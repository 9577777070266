import React, { useState } from 'react';

export const gradientReaderObj = {
  /**
   * @param {'reductionLog' | 'copiesPerMillion'} type
   */
  createGradientReader: (type = 'reductionLog') => {
    const colorStops =
      type === 'reductionLog'
        ? [
            { stop: 0.0, color: 'red' },
            { stop: 0.143, color: 'red' },
            { stop: 0.286, color: 'yellow' },
            { stop: 0.428, color: 'lightgreen' },
            { stop: 0.571, color: 'green' },
            { stop: 0.714, color: 'green' },
            { stop: 0.847, color: 'green' },
            { stop: 1, color: 'green' },
          ]
        : [
            { stop: 1, color: 'red' },
            { stop: 0.75, color: 'red' },
            { stop: 0.5, color: 'yellow' },
            { stop: 0.25, color: 'lightgreen' },
            { stop: 0, color: 'green' },
          ];
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const gr = ctx.createLinearGradient(0, 0, 101, 0);
    canvas.width = 101;
    canvas.height = 1;

    for (const { stop, color } of colorStops) {
      gr.addColorStop(stop, color);
    }

    ctx.fillStyle = gr;
    ctx.fillRect(0, 0, 101, 1);

    return {
      getColor: (pst) =>
        ctx.getImageData(pst | 0, 0, 1, 1, {
          willReadFrequently: true,
        }).data,
    };
  },
};

export const getSamplePointColorByValueUsingGradientReader = (
  value,
  gradientReader,
  gradientType,
  isHealthCare,
  isVerificationLike,
  additionalResultData = null,
) => {
  if (isHealthCare) {
    if (!additionalResultData.isValid) {
      return 'rgb(147, 211, 237)';
    }
    if (additionalResultData.pass) {
      return 'rgb(24, 255, 27)';
    }
    return 'rgb(255, 25, 24)';
  }
  if (isVerificationLike) {
    let value = 0;
    if (additionalResultData) {
      if (additionalResultData.isValid) {
        value = additionalResultData.pass ? 100 : 0;
      } else value = 30;
    }
    const color = gradientReader.getColor(value);
    return 'rgb(' + color[0] + ', ' + color[1] + ', ' + color[2] + ')';
  }
  const pointValue = gradientType === 'reductionLog' ? Math.round(100 / 7) : 25;
  const val = gradientType === 'reductionLog' ? value : Math.log10(value) + 1;
  let percentValue = pointValue * parseFloat(val);
  if (gradientType !== 'reductionLog') {
    percentValue = Math.min(100, percentValue);
    percentValue = Math.max(0, percentValue);
  }
  const colors = gradientReader.getColor(percentValue);

  return 'rgb(' + colors[0] + ', ' + colors[1] + ', ' + colors[2] + ')';
};

export const healthcareTextMarginConfigByRowsAmount = {
  2: [-4, 8, 0],
  3: [-10, 2, 14],
};

const getSpDisplayValueForSamplePoint = (
  reduction,
  displayValue = null,
  copiesPerMillion,
  isVerificationLike,
  isHealthCare,
  gradientType,
  result,
) => {
  if (result) {
    let text = '';
    for (let scenarioName in result) {
      text += `${scenarioName}`;
      text += `: ${Object.values(result[scenarioName]).join(',')} \n`;
    }

    return text;
  }
  if (isVerificationLike || isHealthCare) return displayValue;

  return gradientType === 'reductionLog' ? reduction : copiesPerMillion;
};

export const getHealthCareSpDisplayValues = (
  reduction,
  displayValue,
  isVerificationLike,
  isHealthCare,
  gradientType,
) => {
  const res = [];
  let value = getSpDisplayValueForSamplePoint(
    reduction,
    displayValue,
    isVerificationLike,
    isHealthCare,
    gradientType,
  );

  value = value.toString().split(' ');

  for (let val of value) {
    if (val) {
      res.push(val.replace('_', ' '));
    }
  }

  return res;
};

const SingleSP = ({
  x,
  y,
  sp,
  scale,
  spCircleValue,
  isVerificationLike,
  isHealthCare,
  showLabel,
  gradientType,
  testNumberLabel,
  isUV = false,
}) => {
  const [gradientReader] = useState(
    gradientReaderObj.createGradientReader(gradientType),
  );

  const getColorByValue = (value, verResult = null) =>
    getSamplePointColorByValueUsingGradientReader(
      value,
      gradientReader,
      gradientType,
      isHealthCare,
      isVerificationLike,
      verResult,
    );

  const getSpDisplayValue = (
    reduction,
    displayValue = null,
    copiesPerMillion,
    result,
  ) =>
    getSpDisplayValueForSamplePoint(
      reduction,
      displayValue,
      copiesPerMillion,
      isVerificationLike,
      isHealthCare,
      gradientType,
      result,
    );

  const spLabel = (heightToWidthRatio) => {
    const spText = 'spText' in sp && sp.spText !== null;
    const labelTop = 'labelTop' in sp ? sp.labelTop : false;
    let labelMargin = labelTop ? -85 * (spCircleValue / 35) : 0;
    if (!isHealthCare) {
      const direction = labelTop ? 1 : -1;
      labelMargin = labelMargin + direction * (spCircleValue / 35) * 5;
    }
    if (isHealthCare) {
      labelMargin =
        labelMargin - 10 * (spCircleValue / 35) * heightToWidthRatio;
    }
    return (
      <g className="tooltip">
        {spText && (
          <g>
            <rect
              x={x - (35 * (spCircleValue / 35)) / scale}
              y={y + (17 * (spCircleValue / 35) * 2 + labelMargin) / scale}
              width={(72 * (spCircleValue / 35)) / scale}
              height={(25 * (spCircleValue / 35)) / scale}
              fill="lightblue"
              fillOpacity="0.85"
              stroke="black"
              strokeWidth={1 / scale}
            />
            <text
              x={x}
              y={y + (26 * 1.66 * (spCircleValue / 35) + labelMargin) / scale}
              textAnchor="middle"
              alignmentBaseline="middle"
              fontFamily="Arial"
              fontSize={(16 * (0.7 * (spCircleValue / 35))) / scale + 'px'}
            >
              {sp.spnumber}
            </text>
            <text
              x={x}
              y={y + (32 * 1.66 * (spCircleValue / 35) + labelMargin) / scale}
              textAnchor="middle"
              alignmentBaseline="middle"
              fontFamily="Arial"
              fontSize={(16 * (0.7 * (spCircleValue / 35))) / scale + 'px'}
            >
              {sp.spText} in WC
            </text>
          </g>
        )}
        {!spText && !testNumberLabel && (
          <g>
            <rect
              x={x - (20 * (spCircleValue / 35)) / scale}
              y={y + (14 * (spCircleValue / 35) * 2 + labelMargin) / scale}
              width={(42 * (spCircleValue / 35)) / scale}
              height={(16 * (spCircleValue / 35)) / scale}
              fill="lightblue"
              fillOpacity="0.85"
              stroke="black"
              strokeWidth={1 / scale}
            />
            <text
              x={x + spCircleValue / 35}
              y={y + (22 * (spCircleValue / 35) * 1.66 + labelMargin) / scale}
              textAnchor="middle"
              alignmentBaseline="middle"
              fontFamily="Arial"
              fontSize={(16 * (0.7 * (spCircleValue / 35))) / scale + 'px'}
            >
              {sp.spnumber}
            </text>
          </g>
        )}
        {testNumberLabel ? (
          <g>
            <rect
              x={x - (43 * (spCircleValue / 35)) / scale}
              y={y + (14 * (spCircleValue / 35) * 2 + labelMargin) / scale}
              width={(87 * (spCircleValue / 35)) / scale}
              height={(16 * (spCircleValue / 35)) / scale}
              fill="lightblue"
              fillOpacity="0.85"
              stroke="black"
              strokeWidth={1 / scale}
            />
            <text
              x={x + spCircleValue / 35}
              y={y + (22 * (spCircleValue / 35) * 1.66 + labelMargin) / scale}
              textAnchor="middle"
              alignmentBaseline="middle"
              fontFamily="Arial"
              fontSize={(0.7 * (spCircleValue / 35)) / scale + 'em'}
            >
              {sp.testNumber}
            </text>
          </g>
        ) : null}
      </g>
    );
  };

  if (typeof x !== 'number' || typeof y !== 'number') return;

  const healthcareInfiltrationValues =
    isHealthCare && 'infiltration' in sp
      ? getHealthCareSpDisplayValues(
          sp.reduction,
          sp.infiltration && sp.infiltration.displayValue,
          isVerificationLike,
          isHealthCare,
          gradientType,
        )
      : [];
  const healthcareDisplayValues = isHealthCare
    ? getHealthCareSpDisplayValues(
        sp.reduction,
        sp.result && sp.result.displayValue,
        isVerificationLike,
        isHealthCare,
        gradientType,
      )
    : [];

  const heightToWidthRatio = isHealthCare
    ? healthcareDisplayValues.length === 2
      ? 0.6
      : 0.8
    : 0.5;

  return (
    <g>
      {'infiltration' in sp && (
        <g>
          <rect
            x={x + (30 * (spCircleValue / 35)) / scale}
            y={y - (23 * (spCircleValue / 35) * 2) / scale}
            width={(85 * (spCircleValue / 35)) / scale}
            height={(30 * (spCircleValue / 35)) / scale}
            fill={getColorByValue(
              gradientType === 'reductionLog'
                ? sp.reduction
                : sp.copiesPerMillion,
              sp.infiltration,
            )}
            fillOpacity="0.85"
            stroke="black"
            strokeWidth={1 / scale}
          />
          <text
            x={x + (73 * (spCircleValue / 35)) / scale}
            y={y - (21 * (spCircleValue / 35) * 1.66) / scale}
            textAnchor="middle"
            alignmentBaseline="middle"
            fontFamily="Arial"
            fontSize={(16 * (0.7 * (spCircleValue / 35))) / scale + 'px'}
          >
            {healthcareInfiltrationValues[0] || ''}
          </text>
          <text
            x={x + (73 * (spCircleValue / 35)) / scale}
            y={y - (14 * (spCircleValue / 35) * 1.66) / scale}
            textAnchor="middle"
            alignmentBaseline="middle"
            fontFamily="Arial"
            fontSize={(16 * (0.7 * (spCircleValue / 35))) / scale + 'px'}
          >
            {healthcareInfiltrationValues[1] || ''}
          </text>
        </g>
      )}
      <rect
        x={x - spCircleValue / scale}
        y={y - (spCircleValue * heightToWidthRatio) / scale}
        rx={(0.1 * spCircleValue) / scale}
        ry={(0.1 * spCircleValue) / scale}
        width={(spCircleValue * 2) / scale}
        height={(spCircleValue * heightToWidthRatio * 2) / scale}
        fill={getColorByValue(
          gradientType === 'reductionLog' ? sp.reduction : sp.copiesPerMillion,
          sp.result,
        )}
        fillOpacity="0.8"
      />
      <rect
        x={x - (spCircleValue * 0.8) / scale}
        y={y - (spCircleValue * heightToWidthRatio * 0.8) / scale}
        width={(spCircleValue * 2 * 0.8) / scale}
        height={(spCircleValue * heightToWidthRatio * 2 * 0.8) / scale}
        fill="rgb(255, 255, 255)"
        fillOpacity="0.8"
        stroke="black"
        strokeWidth={1 / scale}
      />
      {isHealthCare ? (
        <React.Fragment>
          <text
            x={x}
            y={
              y +
              (healthcareTextMarginConfigByRowsAmount[
                healthcareDisplayValues.length
              ][0] *
                (spCircleValue / 35)) /
                scale
            }
            textAnchor="middle"
            alignmentBaseline="middle"
            fontFamily="Arial"
            fontSize={(16 * (spCircleValue / 35) * 0.9) / scale + 'px'}
          >
            {healthcareDisplayValues[0] || ''}
          </text>
          <text
            x={x}
            y={
              y +
              (healthcareTextMarginConfigByRowsAmount[
                healthcareDisplayValues.length
              ][1] *
                (spCircleValue / 35)) /
                scale
            }
            textAnchor="middle"
            alignmentBaseline="middle"
            fontFamily="Arial"
            fontSize={(16 * ((spCircleValue / 35) * 0.6)) / scale + 'px'}
          >
            {healthcareDisplayValues[1] || ''}
          </text>
          <text
            x={x}
            y={
              y +
              (healthcareTextMarginConfigByRowsAmount[
                healthcareDisplayValues.length
              ][2] *
                (spCircleValue / 35)) /
                scale
            }
            textAnchor="middle"
            alignmentBaseline="middle"
            fontFamily="Arial"
            fontSize={((spCircleValue / 35) * 0.6) / scale + 'em'}
          >
            {healthcareDisplayValues[2] || ''}
          </text>
        </React.Fragment>
      ) : (
        <text
          x={x}
          y={y + 2 / scale}
          textAnchor="middle"
          alignmentBaseline="middle"
          fontFamily="Arial"
          fontSize={
            isUV
              ? (6 * (spCircleValue / 35) * 1) / scale + 'px'
              : !isVerificationLike
              ? (16 * (spCircleValue / 35) * 1) / scale + 'px'
              : (16 * (spCircleValue / 35) * 0.8) / scale + 'px'
          }
        >
          {getSpDisplayValue(
            sp.reduction,
            sp.result && sp.result.displayValue,
            sp.copiesPerMillionDisplay,
            isUV ? sp.result : null,
          )
            .split('\n')
            .map((line, index) => (
              <tspan
                key={index}
                x={x}
                dy={
                  index === 0
                    ? 0
                    : (1.2 * (6 * (spCircleValue / 35) * 1)) / scale
                }
              >
                {line}
              </tspan>
            ))}
        </text>
      )}
      <circle
        className={'circleForHover'}
        cx={x}
        cy={y}
        r={40}
        fillOpacity="0"
      />
      {showLabel && spLabel(isHealthCare ? heightToWidthRatio : 1)}
    </g>
  );
};

export default SingleSP;
