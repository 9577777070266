import React, { useEffect, useState } from 'react';

import contractsApi from 'Api/contracts';

import DeleteBorderButton from 'Components/Buttons/DeleteBorderButton';
import DeleteDialog from 'Components/Dialogs/DeleteDialog';
import { usePortfolioContext } from 'Components/Portfolios/context';

import { isSafetracesAdmin } from 'Config/roles';

import { isTrainingCompanySelected, isValidStatusResponse } from 'Utils';

function DeleteSiteContractButton({ sitecontract }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { setErrorToast, setSuccessToast, fetchPortfoliosAndSites } =
    usePortfolioContext();

  const canForceDelete = isSafetracesAdmin() && isTrainingCompanySelected();
  const isUsed =
    !!sitecontract?.testCoverage &&
    !(
      sitecontract?.testCoverage?.completed_rate === 0 &&
      sitecontract?.testCoverage?.in_progress_rate === 0 &&
      sitecontract?.testCoverage?.scheduled_rate === 0
    );

  const useForceDelete = isUsed && canForceDelete;

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);
  const handleOpenDialog = () => {
    setIsOpen(true);
  };
  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleDeleteSiteContract = async () => {
    try {
      if (!isMounted) return;
      setIsLoading(true);
      setSuccessToast('');
      if (!sitecontract.sitecontractid) {
        setIsLoading(false);
        setErrorToast('Can not delete site contract!!!');
        return;
      }

      const response = useForceDelete
        ? await contractsApi.removeSiteContractDeep(sitecontract.sitecontractid)
        : await contractsApi.removeSiteContract(sitecontract.sitecontractid);

      if (isValidStatusResponse(response)) {
        handleCloseDialog();
        setSuccessToast('Site Contract successfully force-deleted');
        await fetchPortfoliosAndSites();
      } else {
        setErrorToast(
          response.data?.error?.message ??
            response.data?.error?.detail ??
            'Unexpected error. Site contract was not deleted',
        );
      }
    } catch (e) {
      setErrorToast(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DeleteBorderButton
        clickAction={handleOpenDialog}
        disabled={isUsed && !canForceDelete}
        variant="text"
        tooltipText={
          isUsed
            ? 'Site contract has test packages associated.'
            : 'Delete site contract'
        }
      />
      <DeleteDialog
        open={isOpen}
        isLoading={isLoading}
        handleClose={handleCloseDialog}
        handleDelete={handleDeleteSiteContract}
        title={
          isUsed ? 'Force delete the site contract' : 'Delete site contract'
        }
        extraText={
          isUsed && canForceDelete
            ? 'This will delete the site contract and all associated test packages. ' +
              'It will also cancel and delete all projects, samples, and test results associated with the site contract. ' +
              'This action cannot be undone.'
            : ''
        }
        itemName={sitecontract.sitecontractname}
      />
    </>
  );
}

export default DeleteSiteContractButton;
