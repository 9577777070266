import { format, parseISO } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import tagsApi from 'Api/tags';

const NestedTableTag = ({
  tag,
  users,
  fetchTags,
  fetchTagProperties,
  setAlertMsg,
  setAlertOpen,
}) => {
  const [open, setOpen] = useState(false);
  const [tagEditModal, setTagEditModal] = useState(false);
  const [tagBaselineEditModal, setTagBaselineEditModal] = useState(false);
  const [tagBaselineDeleteModal, setTagBaselineDeleteModal] = useState(false);
  const [tagDeleteModal, setTagDeleteModal] = useState(false);
  const [, setUser] = useState({});
  const [tagName, setTagName] = useState(null);
  const [tagPlateKind, setTagPlateKind] = useState(null);
  const [tagIsSterile, setTagIsSterile] = useState(tag.issterile);
  const [tagSlope, setTagSlope] = useState(null);
  const [tagIntercept, setTagIntercept] = useState(null);
  const [tagLotNumber, setTagLotNumber] = useState(null);
  const [tagManufactureDate, setTagManufactureDate] = useState(null);
  const [tagExpirationDate, setTagExpirationDate] = useState(null);
  const [tagManufacturedBy, setTagManufacturedBy] = useState(null);
  const [tagNotes, setTagNotes] = useState(null);

  const [tagBaselineId, setTagBaselineId] = useState(null);
  const [tagBaseline, setTagBaseline] = useState(null);
  const [tagQcdate, setTagQcdate] = useState(null);
  const [tagQcdateEnabled, setTagQcdateEnabled] = useState(true);

  /**
   * Close or open a nested content
   */
  const handleCollapse = () => {
    setOpen(!open);
  };

  /**
   * Open modal for editing a tag's details
   */
  const handleTagEditModal = () => {
    // Set defaults from exist tag
    setTagName(tag.tag);
    setTagPlateKind(tag.platekind);
    setTagSlope(tag.tagslope);
    setTagIntercept(tag.tagintercept);
    setTagLotNumber(tag.taglotnumber);
    setTagManufactureDate(tag.manufacturedate);
    setTagExpirationDate(tag.expirationdate);
    setTagManufacturedBy(tag.manufacturedby);
    setTagNotes(tag.tagnotes);
    // Open modal
    setTagEditModal(!tagEditModal);
  };

  /**
   * Open modal for editing selected baseline
   *
   * @param qcdateEditEnabled
   * @param baselineId
   * @param QCDate
   * @param baseline
   */
  const handleTagBaselineEditModal = (
    qcdateEditEnabled = true,
    baselineId = null,
    QCDate = null,
    baseline = null,
  ) => {
    // Set values for edit
    setTagBaselineId(baselineId);
    setTagQcdate(QCDate);
    setTagBaseline(baseline);

    // Turn on/off input field with qcdate
    setTagQcdateEnabled(qcdateEditEnabled);

    // Open modal
    setTagBaselineEditModal(!tagBaselineEditModal);
  };

  /**
   * Open modal for deleting selected tag
   */
  const handleTagDeleteModal = () => {
    setTagDeleteModal(!tagDeleteModal);
  };

  /**
   * Open modal for deleting selected baseline of tag
   */
  const handleTagBaselineDeleteModal = (baselineId) => {
    setTagBaselineId(baselineId);
    setTagBaselineDeleteModal(!tagBaselineDeleteModal);
  };

  /**
   * Editing details about tag
   *
   * @param field
   * @param value
   */
  const handleChange = (field, value) => {
    switch (field) {
      case 'tag':
        setTagName(value.target.value);
        break;
      case 'platekind':
        setTagPlateKind(value.target.value);
        break;
      case 'tagslope':
        setTagSlope(value.target.value);
        break;
      case 'tagintercept':
        setTagIntercept(value.target.value);
        break;
      case 'taglotnumber':
        setTagLotNumber(value.target.value);
        break;
      case 'manufacturedate':
        if (value === null) {
          setTagManufactureDate(null);
        } else {
          setTagManufactureDate(format(value, 'yyyy-MM-dd'));
        }
        break;
      case 'expirationdate':
        if (value === null) {
          setTagExpirationDate(null);
        } else {
          setTagExpirationDate(format(value, 'yyyy-MM-dd'));
        }
        break;
      case 'manufacturedby':
        setTagManufacturedBy(value.target.value);
        break;
      case 'tagnotes':
        setTagNotes(value.target.value);
        break;
      case 'tagbaseline':
        setTagBaseline(value.target.value);
        break;
      case 'tagqcdate':
        if (value === null) {
          setTagQcdate(null);
        } else {
          setTagQcdate(format(value, 'yyyy-MM-dd'));
        }
        break;
      default:
        break;
    }
  };

  /**
   * Update exists tag
   *
   * @returns {Promise<void>}
   */
  const handleTagEditSubmit = async () => {
    try {
      await tagsApi.updateOneTag(
        tagPlateKind,
        tagName,
        tagManufactureDate,
        tagExpirationDate,
        tagSlope,
        tagIntercept,
        tagNotes,
        tagLotNumber,
        tagManufacturedBy,
        tag.tagid,
        tagIsSterile,
      );
      await fetchTags();
      await fetchTagProperties();
      setTagEditModal(false);
    } catch (e) {
      console.error(e);
      setAlertMsg(
        'Error updating the tag data! Check the console for more details.',
      );
      setAlertOpen(true);
    }
  };

  /**
   * Remove tag by ID, then close modal
   *
   * @returns {Promise<void>}
   */
  const handleTagDeleteSubmit = async () => {
    try {
      await tagsApi.removeOneTag(tag.tagid);
      await fetchTags();
      await fetchTagProperties();
      setTagDeleteModal(false);
    } catch (e) {
      console.error(e);
      setAlertMsg(
        'Error deleting the tag data! Check the console for more details.',
      );
      setAlertOpen(true);
    }
  };

  /**
   * Create new tag's qcdate:baseline or update exists
   * then refresh list of tags and close modal.
   */
  const handleTagBaselineSubmit = async () => {
    try {
      if (null === tagBaselineId) {
        await tagsApi.addTagBaseline(tag.tagid, tagQcdate, tagBaseline);
      } else {
        await tagsApi.updateTagBaseline(
          tag.tagid,
          tagBaselineId,
          tagQcdate,
          tagBaseline,
        );
      }
      fetchTags();
      fetchTagProperties();
      setTagBaselineEditModal(false);
    } catch (e) {
      console.error(e);
      setAlertMsg(
        'Error updating the tag baseline data! Check the console for more details.',
      );
      setAlertOpen(true);
    }
  };

  /**
   * Remove baseline by Tag ID and Baseline ID
   *
   * @returns {Promise<void>}
   */
  const handleTagBaselineDeleteSubmit = async () => {
    try {
      await tagsApi.removeTagBaseline(tag.tagid, tagBaselineId);
      await fetchTags();
      await fetchTagProperties();
      setTagBaselineDeleteModal(false);
    } catch (e) {
      console.error(e);
      setAlertMsg(
        'Error deleting the tag baseline data! Check the console for more details.',
      );
      setAlertOpen(true);
    }
  };

  const firstUpdate = useRef(true);
  useEffect(() => {
    setUser(
      users.find((obj) => {
        return obj.userid === tag.updatedby;
      }),
    );
    firstUpdate.current = false;
  });

  const renderDate = (date) => {
    if (date === null) {
      return '';
    }

    return format(parseISO(date), 'yyyy-MM-dd');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TableRow hover={!open}>
        <TableCell colSpan={3}>
          {!!tag.tagbaselines && (
            <Tooltip
              title="Tag Baselines"
              placement="top"
              aria-label="tag-baseline"
            >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleCollapse()}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell size="small">
          <IconButton
            onClick={() => handleTagDeleteModal()}
            aria-label="delete"
          >
            <DeleteForeverIcon />
          </IconButton>
        </TableCell>
        <TableCell size="small" onClick={handleTagEditModal}>
          {tag.tag}
        </TableCell>
        <TableCell size="small" onClick={handleTagEditModal}>
          {tag.platekind}
        </TableCell>
        <TableCell size="small" onClick={handleTagEditModal}>
          {tag.tagslope}
        </TableCell>
        <TableCell size="small" onClick={handleTagEditModal}>
          {tag.tagintercept}
        </TableCell>
        <TableCell size="small" onClick={handleTagEditModal}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {tag.taglotnumber}
            {tag.issterile && (
              <Tooltip title="Sterile" placement="top" aria-label="Sterile">
                <LocalHospitalIcon sx={{ color: '#008996' }} />
              </Tooltip>
            )}
          </div>
        </TableCell>
        <TableCell size="small" onClick={handleTagEditModal}>
          {renderDate(tag.manufacturedate)}
        </TableCell>
        <TableCell size="small" onClick={handleTagEditModal}>
          {renderDate(tag.expirationdate)}
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => handleTagBaselineEditModal(true, null, null, null)}
            aria-label="tag-baseline-add"
          >
            <AddBoxIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      {Array.isArray(tag.tagbaselines) && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }}
            colSpan={8}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table aria-label="tag-baseline">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell>Delete Baseline</TableCell>
                      <TableCell>QC Date</TableCell>
                      <TableCell>Baseline</TableCell>
                    </TableRow>
                  </TableHead>
                  {tag.tagbaselines
                    .sort((a, b) => (a.qcdate > b.qcdate ? 1 : -1))
                    .reverse()
                    .map((baseline, i) => (
                      <TableBody key={`tag-baseline-${i}`}>
                        <TableRow hover={true}>
                          <TableCell size="small" style={{ border: 'none' }} />
                          <TableCell size="small" style={{ border: 'none' }} />
                          <TableCell size="small">
                            <IconButton
                              onClick={() =>
                                handleTagBaselineDeleteModal(
                                  baseline.baselineid,
                                )
                              }
                              aria-label="delete"
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell
                            size="small"
                            onClick={() =>
                              handleTagBaselineEditModal(
                                false,
                                baseline.baselineid,
                                baseline.qcdate,
                                baseline.baseline,
                              )
                            }
                          >
                            {baseline.qcdate}
                          </TableCell>
                          <TableCell
                            size="small"
                            onClick={() =>
                              handleTagBaselineEditModal(
                                false,
                                baseline.baselineid,
                                baseline.qcdate,
                                baseline.baseline,
                              )
                            }
                          >
                            {baseline.baseline}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      {/* Modal Tag Delete START */}
      <Dialog open={tagDeleteModal} onClose={() => handleTagDeleteModal()}>
        <DialogTitle>
          {' '}
          Are you sure you want to delete "{tag.tag}" tag?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleTagDeleteSubmit()} size="small">
            Yes
          </Button>
          <Button onClick={() => handleTagDeleteModal('test')} size="small">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={tagBaselineEditModal} onClose={handleTagBaselineEditModal}>
        <DialogTitle>Baseline of "{tag.tag}"</DialogTitle>
        <DialogContent>
          <Stack spacing={1} width="100%" mt="10px">
            <MobileDatePicker
              label="QC Date"
              value={tagQcdate !== null ? parseISO(tagQcdate) : null}
              name="tag-baseline-qcdate"
              inputFormat={'MMM do, yyyy'}
              onChange={(newValue) => handleChange('tagqcdate', newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              disabled={!tagQcdateEnabled}
            />
            <TextField
              fullWidth={true}
              label="Baseline"
              name="tag-baseline-number"
              autoComplete="tag-baseline-number"
              onChange={(newValue) => handleChange('tagbaseline', newValue)}
              value={tagBaseline ?? ''}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => handleTagBaselineSubmit(e)}
            size="small"
            disabled={!tagQcdate}
          >
            Save
          </Button>
          <Button onClick={handleTagBaselineEditModal} size="small">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={tagBaselineDeleteModal}
        onClose={() => handleTagBaselineDeleteModal()}
      >
        <DialogTitle>
          Are you sure you want to delete "{tag.tag}" baseline?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleTagBaselineDeleteSubmit()} size="small">
            Yes
          </Button>
          <Button onClick={() => handleTagBaselineDeleteModal()} size="small">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={tagEditModal} onClose={handleTagEditModal}>
        <DialogTitle>Update tag details</DialogTitle>
        <DialogContent>
          <Stack spacing={1} mt="15px" minWidth="300px">
            <TextField
              fullWidth={true}
              label="Name"
              name="tag-name"
              autoComplete="tag-name"
              onChange={(newValue) => handleChange('tag', newValue)}
              value={tagName ?? ''}
            />
            <TextField
              fullWidth={true}
              label="Platekind"
              name="tag-platekind"
              autoComplete="tag-platekind"
              onChange={(newValue) => handleChange('platekind', newValue)}
              value={tagPlateKind ?? ''}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={tagIsSterile}
                  onChange={() => setTagIsSterile(!tagIsSterile)}
                  name="tag-is-sterile"
                />
              }
              label="Sterile"
            />
            <TextField
              fullWidth={true}
              label="Slope"
              name="tag-slope"
              autoComplete="tag-slope"
              onChange={(newValue) => handleChange('tagslope', newValue)}
              value={tagSlope ?? ''}
            />
            <TextField
              fullWidth={true}
              label="Intercept"
              name="tag-intercept"
              autoComplete="tag-intercept"
              onChange={(newValue) => handleChange('tagintercept', newValue)}
              value={tagIntercept ?? ''}
            />
            <TextField
              fullWidth={true}
              label="Lot Number"
              name="tag-lotnumber"
              autoComplete="tag-lotnumber"
              onChange={(newValue) => handleChange('taglotnumber', newValue)}
              value={tagLotNumber ?? ''}
            />
            <MobileDatePicker
              label="Manufacture Date (Bulk saliva)"
              value={parseISO(tagManufactureDate)}
              name="tag-manufacturedate"
              inputFormat={'MMM do, yyyy'}
              onChange={(newValue) => handleChange('manufacturedate', newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
            <MobileDatePicker
              label="Expiration Date (Bulk saliva)"
              value={parseISO(tagExpirationDate)}
              name="tag-expirationdate"
              inputFormat={'MMM do, yyyy'}
              onChange={(newValue) => handleChange('expirationdate', newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
            <TextField
              fullWidth={true}
              label="Manufactured By"
              name="tag-manufacturedby"
              autoComplete="tag-manufacturedby"
              onChange={(newValue) => handleChange('manufacturedby', newValue)}
              value={tagManufacturedBy ?? ''}
            />
            <TextField
              fullWidth={true}
              label="Notes"
              name="tag-notes"
              autoComplete="tag-notes"
              onChange={(newValue) => handleChange('tagnotes', newValue)}
              value={tagNotes ?? ''}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleTagEditSubmit}
            disabled={
              !(
                tagPlateKind &&
                tagName &&
                tagManufactureDate &&
                tagSlope &&
                tagIntercept &&
                tagLotNumber
              )
            }
            size="small"
          >
            Save
          </Button>
          <Button onClick={handleTagEditModal} size="small">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default NestedTableTag;
