import { TEST_TYPES } from 'Constants';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Radio,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import floorplansApi from 'Api/floorPlans';
import segments from 'Api/segments';

import FloorPlanHDAImage from 'Components/Floorplans/HDA/FloorPlanHDAImage';
import { scaleCoordinates } from 'Components/Floorplans/utils';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

function SelectedListItem({ HDAs, handleHdaSelect }) {
  return (
    <Box sx={{ height: '280px', overflow: 'scroll' }}>
      <List component="nav" aria-label="secondary mailbox folder">
        {HDAs.map((hda) => (
          <ListItemButton
            key={hda.id}
            selected={hda.selected}
            onClick={() => handleHdaSelect(hda.id)}
            autoFocus={hda.selected}
          >
            <ListItemIcon>
              <Radio size="small" checked={!!hda.selected} />
            </ListItemIcon>
            <ListItemText primary={hda.hdaname} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}

function FilterHdaTypes({
  hda,
  HDAtypesByApplication,
  updateFilteredTypes,
  typesSelected,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    setAnchorEl(null);
    updateFilteredTypes(getAllPresentTypes());
  }, [hda, HDAtypesByApplication]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAllPresentTypes = () => {
    if (!hda) return [];

    const typesPresent = [];
    for (let { type } of hda) {
      if (!typesPresent.includes(type)) {
        typesPresent.push(type);
      }
    }

    return typesPresent;
  };

  const filterConfig = useMemo(() => {
    const newFilterConfig = {};

    const typesPresent = getAllPresentTypes();

    for (let [applicationType, applicationData] of Object.entries(
      HDAtypesByApplication,
    )) {
      const { applicationName, spaceTypes } = applicationData;
      for (let type of Object.keys(spaceTypes)) {
        if (typesPresent.includes(type)) {
          if (!newFilterConfig[applicationType]) {
            newFilterConfig[applicationType] = {
              applicationName,
              spaceTypes: {},
            };
          }
          newFilterConfig[applicationType].spaceTypes[type] = spaceTypes[type];
        }
      }
    }

    return newFilterConfig;
  }, [hda, HDAtypesByApplication]);

  const onApplicationTypeClick = (applicationType) => {
    const isApplicationSelected = isApplicationTypeSelected(applicationType);
    const typesInApplication = Object.keys(
      filterConfig[applicationType]?.spaceTypes,
    );
    if (isApplicationSelected) {
      const newFilteredTypes = typesSelected.filter(
        (type) => !typesInApplication.includes(type),
      );
      updateFilteredTypes(newFilteredTypes);
    } else {
      const newSelectedTypes = [...typesSelected];
      for (let type of typesInApplication) {
        if (!newSelectedTypes.includes(type)) {
          newSelectedTypes.push(type);
        }
        updateFilteredTypes(newSelectedTypes);
      }
    }
  };

  const onSpaceTypeClick = (spaceType) => {
    if (typesSelected.includes(spaceType)) {
      updateFilteredTypes(typesSelected.filter((type) => type !== spaceType));
    } else {
      updateFilteredTypes([...typesSelected, spaceType]);
    }
  };

  const isApplicationTypeSelected = (applicationType) => {
    const typesInApplication = Object.keys(
      filterConfig[applicationType]?.spaceTypes,
    );
    for (let type of typesSelected) {
      if (typesInApplication.includes(type)) {
        return true;
      }
    }
    return false;
  };

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleClick}>
        <FilterAltIcon />
      </IconButton>
      <Popover
        id={Boolean(anchorEl) ? 'filter-hda-types' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper>
          {Object.entries(filterConfig).map(
            ([applicationType, { applicationName, spaceTypes }]) => (
              <React.Fragment key={applicationType}>
                <MenuItem
                  onClick={() => onApplicationTypeClick(applicationType)}
                >
                  <Typography sx={{ display: 'flex', alignItems: 'center ' }}>
                    <DoneIcon
                      color={
                        isApplicationTypeSelected(applicationType)
                          ? 'primary'
                          : 'disabled'
                      }
                      sx={{ mr: 1 }}
                      fontSize="small"
                    />
                    {applicationName}
                  </Typography>
                </MenuItem>
                {Object.entries(spaceTypes).map(
                  ([spaceType, spaceTypeName]) => (
                    <MenuItem
                      key={spaceTypeName}
                      onClick={() => onSpaceTypeClick(spaceType)}
                    >
                      <Typography
                        sx={{ ml: 2, display: 'flex', alignItems: 'center ' }}
                      >
                        <DoneIcon
                          color={
                            typesSelected.includes(spaceType)
                              ? 'primary'
                              : 'disabled'
                          }
                          sx={{ mr: 1 }}
                          fontSize="small"
                        />
                        {spaceTypeName}
                      </Typography>
                    </MenuItem>
                  ),
                )}
              </React.Fragment>
            ),
          )}
        </Paper>
      </Popover>
    </React.Fragment>
  );
}

const EditFloorplan = ({
  cancel,
  value,
  testId,
  reFetchProject,
  buildingId,
  projectId,
  floorplans,
  segment,
  hdaId,
}) => {
  const includeHDAEdit = [
    TEST_TYPES.VERIFICATION,
    TEST_TYPES.POSITIVE,
    TEST_TYPES.NEGATIVE,
    TEST_TYPES.NEUTRAL,
    TEST_TYPES.UV,
  ].includes(segment.testtype);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(``);

  const [floorPlanImageData, setFloorPlanImageData] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [hdaTypesInFp, setHdaTypesInFp] = useState([]);
  const boundingBox = useRef(null);
  const dimensionsRef = useRef(null);

  const [HDAs, setHDAs] = useState([]);
  const [HDAtypesByApplication, setHDAtypesByApplication] = useState({});
  const [typesFilter, setTypesFilter] = useState([]);

  const [naturalDimensions, setNaturalDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [scaledDimensions, setScaledDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [scaledHDAs, setScaledHDAs] = useState([]);

  const { handleSubmit, register, errors, getValues, setValue } = useForm();

  useEffect(() => {
    const selectedFloorplan = floorplans.find((fp) => fp.filename === value);
    const floorplanId = selectedFloorplan?.floorplanid;

    fetchHDATypesData();

    if (floorplanId) {
      fetchFloorPlanImage(floorplanId, true);
    }
  }, []);

  useEffect(() => {
    setScaledHDAs(
      HDAs.map((hda) => {
        const shiftX = floorPlanImageData?.cropData?.x || 0;
        const shiftY = floorPlanImageData?.cropData?.y || 0;
        const fromDimensions = floorPlanImageData?.cropData
          ? floorPlanImageData.cropData
          : naturalDimensions;

        const coords = scaleCoordinates({
          fromDimensions,
          toDimensions: scaledDimensions,
          fromCoordinates: {
            x: Number.parseInt(hda.coordX) - shiftX,
            y: Number.parseInt(hda.coordY) - shiftY,
          },
        });
        return {
          ...hda,
          coordX: coords.x,
          coordY: coords.y,
        };
      }),
    );

    dimensionsRef.current = {
      naturalDimensions,
      scaledDimensions,
    };
  }, [HDAs, naturalDimensions, scaledDimensions]);

  const onSubmit = async (data) => {
    const fileName = data.floorplan;
    const selectedFloorplan = floorplans.find((fp) => fp.filename === fileName);
    const selectedHdaId = HDAs.find((hda) => hda.selected)?.id || null;

    setIsLoading(true);
    try {
      const response = await segments.updateSegment(segment.segmentid, {
        testId,
        floorPlan: data.floorplan,
        hdaId: includeHDAEdit ? selectedHdaId || null : null,
        floorPlanId: selectedFloorplan.floorplanid,
      });
      if (isValidResponse(response)) {
        reFetchProject();
        cancel();
      } else {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      setError(err?.message || `Error when updating floorplan`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFloorplanSelect = (e) => {
    const fileName = e.target.value;
    const selectedFloorplan = floorplans.find((fp) => fp.filename === fileName);
    const floorplanId = selectedFloorplan.floorplanid;

    setValue('floorplan', fileName);
    setHDAs([]);
    updateListOfHdaTypesInFp(floorplanId);
    fetchFloorPlanImage(floorplanId);
  };

  const fetchFloorPlanImage = async (floorplanId, initialLoad = false) => {
    if (!floorplanId) return;

    setIsLoading(true);
    setFloorPlanImageData(null);
    setIsImageLoaded(false);

    try {
      const req = await floorplansApi.getPresignedURL({
        buildingId: buildingId ?? '',
        projectId: buildingId ? '' : projectId,
        floorPlanId: floorplanId,
        action: 'getObject',
        heatmapVersion: 1,
      });

      if (req.status === 200 && req.data?.preSignedURL) {
        if (!req?.data?.floorPlanMeta?.cropData) {
          const img = new Image();
          img.src = req.data.preSignedURL;
          img.onload = () => {
            setNaturalDimensions({
              width: img.naturalWidth,
              height: img.naturalHeight,
            });
          };
        }

        setFloorPlanImageData({
          url: req.data.preSignedURL,
          cropData: req?.data?.floorPlanMeta?.cropData,
        });

        const selectedFloorplan = floorplans.find(
          (fp) => fp.floorplanid === floorplanId,
        );
        let parentFloorplan = null;
        if (selectedFloorplan.mainfloorplanid) {
          parentFloorplan = floorplans.find(
            (fp) => fp.floorplanid === selectedFloorplan.mainfloorplanid,
          );
        }
        const _hdaList = parentFloorplan?.hdaList || selectedFloorplan.hdaList;
        setHDAs(
          _hdaList.reduce((list, hda) => {
            // For cropped floorplans, only show HDAs that are within the crop area
            if (parentFloorplan) {
              const { x, y, height, width } =
                selectedFloorplan.metadata.cropData;
              const { coordX, coordY } = hda;

              if (
                coordX < x ||
                coordX > x + width ||
                coordY < y ||
                coordY > y + height
              ) {
                return list;
              }
            }
            if (initialLoad && hda.id === hdaId) {
              list.push({ ...hda, selected: true });

              return list;
            }
            list.push({ ...hda });

            return list;
          }, []),
        );
      } else {
        setError(`No floorplan was found`);
      }
    } catch (e) {
      console.error('Error loading the floorplan image', e);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchHDATypesData = async () => {
    try {
      const response = await floorplansApi.getHDATypes();
      if (isValidResponse(response)) {
        const hdaTypes = getDataFromResponse(response);
        setHDAtypesByApplication(hdaTypes);

        const selectedFloorplan = floorplans.find(
          (fp) => fp.filename === value,
        );
        const floorplanId = selectedFloorplan?.floorplanid;
        updateListOfHdaTypesInFp(floorplanId, hdaTypes);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateListOfHdaTypesInFp = (floorplanId, hdaTypes) => {
    if (!floorplanId) {
      setHdaTypesInFp([]);
      return;
    }

    const _hdaTypes = hdaTypes || HDAtypesByApplication;
    const selectedFloorplan = floorplans.find(
      (fp) => fp.floorplanid === floorplanId,
    );

    let parentFloorplan = null;
    if (selectedFloorplan.mainfloorplanid) {
      parentFloorplan = floorplans.find(
        (fp) => fp.floorplanid === selectedFloorplan.mainfloorplanid,
      );
    }
    const hdaInFp = parentFloorplan?.hdaList || selectedFloorplan.hdaList;
    const hdaTypesInFloorplan = [];

    for (let { type } of hdaInFp) {
      if (!hdaTypesInFloorplan.includes(type)) {
        hdaTypesInFloorplan.push(type);
      }
    }

    const arrOfTypes = Object.values(_hdaTypes).reduce((curr, arr) => {
      curr.push(...Object.keys(arr.spaceTypes));
      return curr;
    }, []);
    hdaTypesInFloorplan.sort(
      (a, b) => arrOfTypes.indexOf(a) - arrOfTypes.indexOf(b),
    );

    setHdaTypesInFp(hdaTypesInFloorplan);
  };

  const handleHdaSelect = (id) => {
    const hda = HDAs.find((h) => h.id === id);
    const wasSelected = hda.selected;
    HDAs.forEach((h) => (h.selected = false));
    if (!wasSelected) {
      hda.selected = true;
    }
    setHDAs([...HDAs]);
  };

  const hdaInFp = useMemo(() => {
    const selectedFloorplan = floorplans.find(
      (fp) => getValues().floorplan === fp.filename,
    );
    if (!selectedFloorplan) return [];

    let parentFloorplan = null;
    if (selectedFloorplan.mainfloorplanid) {
      parentFloorplan = floorplans.find(
        (fp) => fp.floorplanid === selectedFloorplan.mainfloorplanid,
      );
    }

    return parentFloorplan?.hdaList || selectedFloorplan.hdaList;
  }, [floorplans, getValues().floorplan]);

  const hdaAmountInSelectedFP = hdaInFp.length;

  return (
    <Stack p={2} spacing={2}>
      <Stack direction="row">
        <Typography variant="h5">
          {includeHDAEdit ? `Floorplan & HDA` : `Floorplan`}
        </Typography>
        <Button
          href={
            !buildingId
              ? `/floorplanner?projectId=${projectId}&testId=${testId}`
              : `/floorplanner?buildingId=${buildingId}&testId=${testId}&projectId=${projectId}`
          }
          variant="outlined"
          color="primary"
          size="small"
          sx={{ ml: 2 }}
        >
          Edit floorplans
        </Button>
      </Stack>
      <Stack
        py={2}
        direction="column"
        sx={{ maxHeight: '500px', overflow: 'scroll' }}
      >
        {!!error && (
          <Alert sx={{ mb: 2 }} severity="error">
            {error}
          </Alert>
        )}
        {Array.isArray(floorplans) && !floorplans.length ? (
          <Typography>
            There are no available floorplans for this project
          </Typography>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth>
              <TextField
                select
                focused
                variant="outlined"
                label="Floorplan"
                sx={{ width: '440px' }}
                defaultValue={value}
                {...register('floorplan')}
                error={!!(errors && errors.floorplan)}
                helperText={errors?.floorplan?.message}
                onChange={handleFloorplanSelect}
              >
                {floorplans.map((value) => (
                  <MenuItem key={value.floorplanid} value={value.filename}>
                    <Typography>{value.filename}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </form>
        )}
        {getValues().floorplan ? (
          <Stack direction="row" sx={{ my: 4 }}>
            {includeHDAEdit && hdaAmountInSelectedFP ? (
              <Box sx={{ width: '40%' }}>
                <Stack direction="row">
                  <Typography variant="h5" sx={{ mr: 1 }}>
                    HDA list
                  </Typography>
                  <FilterHdaTypes
                    hda={hdaInFp}
                    HDAtypesByApplication={HDAtypesByApplication}
                    typesSelected={typesFilter}
                    updateFilteredTypes={setTypesFilter}
                  />
                </Stack>
                {isLoading ? (
                  <LinearProgress sx={{ mt: 2 }} />
                ) : (
                  <SelectedListItem
                    HDAs={HDAs.filter(
                      (hda) => !!typesFilter.includes(hda.type),
                    )}
                    handleHdaSelect={handleHdaSelect}
                  />
                )}
              </Box>
            ) : null}
            <Box
              sx={{
                width: hdaAmountInSelectedFP && includeHDAEdit ? '60%' : '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <FloorPlanHDAImage
                type="selectable"
                boundingRef={boundingBox}
                imageData={floorPlanImageData}
                setScaledDimensions={setScaledDimensions}
                scaledDimensions={scaledDimensions}
                isImageLoaded={isImageLoaded}
                setIsImageLoaded={setIsImageLoaded}
                HDAs={
                  includeHDAEdit
                    ? scaledHDAs.filter(
                        (hda) => !!typesFilter.includes(hda.type),
                      )
                    : []
                }
                hdaTypesInFp={hdaTypesInFp}
                HDAtypesByApplication={HDAtypesByApplication}
                handleHdaSelect={handleHdaSelect}
                imageSize={{ height: '300px', width: 'auto' }}
              />
            </Box>
          </Stack>
        ) : null}
      </Stack>
      <Stack direction="row" mt={2}>
        <Button
          variant="contained"
          startIcon={<Save />}
          disabled={
            isLoading || (Array.isArray(floorplans) && !floorplans.length)
          }
          type="submit"
          sx={{ mr: 2 }}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
        <Button onClick={cancel}>Cancel</Button>
      </Stack>
    </Stack>
  );
};

export default EditFloorplan;
