import { set } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

function MainAlert({ message, type = 'success' }) {
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (message) {
      setAlert({ message, type });
      setOpen(true);
    }
  }, [message, type]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {!!alert.type && open && (
        <Snackbar
          open={open}
          autoHideDuration={4500}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert severity={alert.type} onClose={handleClose} variant="filled">
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default MainAlert;
