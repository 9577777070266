import {
  HEALTHCARE_TEST_TYPES,
  HEALTHCARE_TEST_TYPES_LABELS,
  TEST_TYPES,
  TEST_TYPES_LABELS,
} from 'Constants';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';

import contractsApi from 'Api/contracts';

import DatePickerControl from 'Components/DatePicker/DatePickerControl';
import SelectControl from 'Components/Select/SelectControl';
import { useTestPackageItems } from 'Components/TestPackageItem/context';

import { getDataFromResponse, isValidResponse, testValue } from 'Utils';

const AddTestPackageButton = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [buildingDefaultValue, setBuildingDefaultValue] = useState('');
  const [contractValidation, setContractValidation] = useState({});
  const {
    canAddTestPackages,
    testPackages,
    siteContract,
    buildings,
    setIsLoading,
    setSuccessMessage,
    setMainError,
    loadAllData,
    isLoading,
  } = useTestPackageItems();

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    control,
    setValue,
    formState: { errors },
    setError,
  } = useForm();

  const handleOpenDialog = () => {
    reset();
    clearErrors();

    const testsInfoObj = siteContract.testsmetadata || siteContract;

    let contractObj = {
      startDate: siteContract.startdate,
      endDate: siteContract.enddate,
      smallSurveyTests: testsInfoObj.smallsurveytests ?? 0,
      largeSurveyTests: testsInfoObj.largesurveytests ?? 0,
      smallDilutionTests: testsInfoObj.smalldilutiontests ?? 0,
      largeDilutionTests: testsInfoObj.largedilutiontests ?? 0,
      miniSurveyTests: testsInfoObj.minisurveytests ?? 0,
      recirculationTests: testsInfoObj.recirculationtests ?? 0,
      verificationTests: testsInfoObj.verificationtests ?? 0,
      [HEALTHCARE_TEST_TYPES.POSITIVE]:
        testsInfoObj[HEALTHCARE_TEST_TYPES.POSITIVE] ?? 0,
      [HEALTHCARE_TEST_TYPES.NEGATIVE]:
        testsInfoObj[HEALTHCARE_TEST_TYPES.NEGATIVE] ?? 0,
      [HEALTHCARE_TEST_TYPES.NEUTRAL]:
        testsInfoObj[HEALTHCARE_TEST_TYPES.NEUTRAL] ?? 0,
      [TEST_TYPES.UV]: testsInfoObj[TEST_TYPES.UV] ?? 0,
    };

    for (const item of testPackages) {
      const testsInfoTPObj = item.testsmetadata || item;

      contractObj.smallSurveyTests -= testsInfoTPObj.smallsurveytests ?? 0;
      contractObj.largeSurveyTests -= testsInfoTPObj.largesurveytests ?? 0;
      contractObj.smallDilutionTests -= testsInfoTPObj.smalldilutiontests ?? 0;
      contractObj.largeDilutionTests -= testsInfoTPObj.largedilutiontests ?? 0;
      contractObj.miniSurveyTests -= testsInfoTPObj.minisurveytests ?? 0;
      contractObj.recirculationTests -= testsInfoTPObj.recirculationtests ?? 0;
      contractObj.verificationTests -= testsInfoTPObj.verificationtests ?? 0;
      contractObj[HEALTHCARE_TEST_TYPES.POSITIVE] -=
        testsInfoTPObj[HEALTHCARE_TEST_TYPES.POSITIVE] ?? 0;
      contractObj[HEALTHCARE_TEST_TYPES.NEGATIVE] -=
        testsInfoTPObj[HEALTHCARE_TEST_TYPES.NEGATIVE] ?? 0;
      contractObj[HEALTHCARE_TEST_TYPES.NEUTRAL] -=
        testsInfoTPObj[HEALTHCARE_TEST_TYPES.NEUTRAL] ?? 0;
      contractObj[TEST_TYPES.UV] -= testsInfoTPObj[TEST_TYPES.UV] ?? 0;
    }
    setContractValidation(contractObj);
    setIsDialogOpen(true);

    setBuildingDefaultValue('');
    if (!!buildings && buildings.length === 1) {
      setBuildingDefaultValue(buildings[0].buildingid);
      setValue('building', buildings[0].buildingid);
    }
  };
  const renderButton = () => {
    if (
      testPackages?.length &&
      testPackages[0].testpackagename ===
        testPackages[0].projectname + ' (Auto)'
    ) {
      return null;
    } else {
      return (
        <Button
          variant="contained"
          onClick={handleOpenDialog}
          disabled={!canAddTestPackages}
        >
          Add a test package
        </Button>
      );
    }
  };

  const validateTestPackageData = (testPackageData) => {
    return !(
      !!~~testPackageData.verificationTests === false &&
      !!~~testPackageData.smallSurveyTests === false &&
      !!~~testPackageData.largeSurveyTests === false &&
      !!~~testPackageData.smallDilutionTests === false &&
      !!~~testPackageData.largeDilutionTests === false &&
      !!~~testPackageData.miniSurveyTests === false &&
      !!~~testPackageData.recirculationTests === false &&
      !!~~testPackageData[HEALTHCARE_TEST_TYPES.POSITIVE] === false &&
      !!~~testPackageData[HEALTHCARE_TEST_TYPES.NEGATIVE] === false &&
      !!~~testPackageData[HEALTHCARE_TEST_TYPES.NEUTRAL] === false &&
      !!~~testPackageData[TEST_TYPES.UV] === false
    );
  };

  const onTestPackageSubmit = async (data) => {
    try {
      setIsLoading(true);
      setSuccessMessage('');

      let sendObj = {
        testPackageName: data.testPackageName,
        date: data.date,
        building: data.building,
        smallSurveyTests: data.smallSurveyTests,
        largeSurveyTests: data.largeSurveyTests,
        smallDilutionTests: data.smallDilutionTests,
        largeDilutionTests: data.largeDilutionTests,
        miniSurveyTests: data.miniSurveyTests,
        recirculationTests: data.recirculationTests,
        verificationTests: data.verificationTests,
        [HEALTHCARE_TEST_TYPES.POSITIVE]: data[HEALTHCARE_TEST_TYPES.POSITIVE],
        [HEALTHCARE_TEST_TYPES.NEGATIVE]: data[HEALTHCARE_TEST_TYPES.NEGATIVE],
        [HEALTHCARE_TEST_TYPES.NEUTRAL]: data[HEALTHCARE_TEST_TYPES.NEUTRAL],
        [TEST_TYPES.UV]: data[TEST_TYPES.UV],
      };
      if (!validateTestPackageData(sendObj)) {
        setMainError('Add at least one test');
      } else {
        const { sitecontractid } = siteContract;
        if (!sitecontractid) {
          setMainError('No site contract data');
          return;
        }
        sendObj.sitecontractid = sitecontractid;

        const response = await contractsApi.createTestPackage(sendObj);
        if (isValidResponse(response)) {
          const tpCreated =
            getDataFromResponse(response)?.testPackageCreated ?? true;
          if (!tpCreated) {
            setError('testPackageName', {
              type: 'manual',
              message:
                'Could not create a Test Package. Please check that a Test Package with the same name does not already exist.',
            });
            return;
          }
          setSuccessMessage('Test Package has been successfully created.');
          setIsDialogOpen(false);
          await loadAllData();
        } else {
          setMainError(response.data?.error?.message.toString());
        }
      }
    } catch (e) {
      setMainError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {renderButton()}
      <Dialog open={isDialogOpen}>
        <form onSubmit={handleSubmit(onTestPackageSubmit)}>
          <DialogTitle>Add Test Package</DialogTitle>
          <DialogContent
            sx={{
              width: 430,
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Test Package Name"
              name="testPackageName"
              id="testPackageName"
              {...register('testPackageName', {
                required: 'Test Package Name field required',
                minLength: {
                  value: 3,
                  message: 'Test Package Name must have at least 3 characters',
                },
              })}
              error={!!(errors && errors.testPackageName)}
              helperText={errors?.testPackageName?.message}
              disabled={isLoading}
            />
            <SelectControl
              control={control}
              rules={{ required: 'Building field is required' }}
              errors={errors}
              name={'building'}
              label={'Buildings'}
              defaultValue={buildingDefaultValue}
              options={
                !!buildings &&
                buildings.map((item) => (
                  <MenuItem key={item.buildingid} value={item.buildingid}>
                    {item.buildingname}
                  </MenuItem>
                ))
              }
            />
            <DatePickerControl
              name={'date'}
              label={'Date'}
              errors={errors}
              isLoading={isLoading}
              inputProps={{
                inputProps: {
                  min: moment(contractValidation.startDate).format(
                    'YYYY-MM-DD',
                  ),
                  max: moment(contractValidation.endDate).format('YYYY-MM-DD'),
                },
              }}
              register={{
                ...register('date', {
                  required: 'Date field required',
                }),
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label="Small Survey Tests"
                  name="smallSurveyTests"
                  id="smallSurveyTests"
                  inputProps={{
                    min: 0,
                    max: testValue(contractValidation?.smallSurveyTests),
                  }}
                  {...register('smallSurveyTests', {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(contractValidation?.smallSurveyTests) ||
                        `Max value is ${testValue(
                          contractValidation?.smallSurveyTests,
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors.smallSurveyTests)}
                  helperText={errors?.smallSurveyTests?.message}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label="Large Survey Tests"
                  name="largeSurveyTests"
                  id="largeSurveyTests"
                  inputProps={{
                    min: 0,
                    max: testValue(contractValidation?.largeSurveyTests),
                  }}
                  {...register('largeSurveyTests', {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(contractValidation?.largeSurveyTests) ||
                        `Max value is ${testValue(
                          contractValidation?.largeSurveyTests,
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors.largeSurveyTests)}
                  helperText={errors?.largeSurveyTests?.message}
                  disabled={isLoading}
                />
              </Grid>
              {!!contractValidation?.smallDilutionTests ? (
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    fullWidth
                    label="Small Dilution Tests"
                    name="smallDilutionTests"
                    id="smallDilutionTests"
                    inputProps={{
                      min: 0,
                      max: testValue(contractValidation?.smallDilutionTests),
                    }}
                    {...register('smallDilutionTests', {
                      validate: {
                        lessThanTen: (v) =>
                          !v ||
                          parseInt(v) <=
                            testValue(contractValidation?.smallDilutionTests) ||
                          `Max value is ${testValue(
                            contractValidation?.smallDilutionTests,
                          )}`,
                      },
                      min: {
                        value: 0,
                        message: 'Min value is 0',
                      },
                    })}
                    error={!!(errors && errors.smallDilutionTests)}
                    helperText={errors?.smallDilutionTests?.message}
                    disabled={isLoading}
                  />
                </Grid>
              ) : null}
              {!!contractValidation?.largeDilutionTests ? (
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    fullWidth
                    label="Large Dilution Tests"
                    name="largeDilutionTests"
                    id="largeDilutionTests"
                    inputProps={{
                      min: 0,
                      max: testValue(contractValidation?.largeDilutionTests),
                    }}
                    {...register('largeDilutionTests', {
                      validate: {
                        lessThanTen: (v) =>
                          !v ||
                          parseInt(v) <=
                            testValue(contractValidation?.largeDilutionTests) ||
                          `Max value is ${testValue(
                            contractValidation?.largeDilutionTests,
                          )}`,
                      },
                      min: {
                        value: 0,
                        message: 'Min value is 0',
                      },
                    })}
                    error={!!(errors && errors.largeDilutionTests)}
                    helperText={errors?.largeDilutionTests?.message}
                    disabled={isLoading}
                  />
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label="Flex Tests"
                  name="recirculationTests"
                  id="recirculationTests"
                  inputProps={{
                    min: 0,
                    max: testValue(contractValidation?.recirculationTests),
                  }}
                  {...register('recirculationTests', {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(contractValidation?.recirculationTests) ||
                        `Max value is ${testValue(
                          contractValidation?.recirculationTests,
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors.recirculationTests)}
                  helperText={errors?.recirculationTests?.message}
                  disabled={isLoading}
                />
              </Grid>
              {!!contractValidation?.miniSurveyTests ? (
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    fullWidth
                    label="Mini Survey Tests"
                    name="miniSurveyTests"
                    id="miniSurveyTests"
                    inputProps={{
                      min: 0,
                      max: testValue(contractValidation?.miniSurveyTests),
                    }}
                    {...register('miniSurveyTests', {
                      validate: {
                        lessThanTen: (v) =>
                          !v ||
                          parseInt(v) <=
                            testValue(contractValidation?.miniSurveyTests) ||
                          `Max value is ${testValue(
                            contractValidation?.miniSurveyTests,
                          )}`,
                      },
                      min: {
                        value: 0,
                        message: 'Min value is 0',
                      },
                    })}
                    error={!!(errors && errors.miniSurveyTests)}
                    helperText={errors?.miniSurveyTests?.message}
                    disabled={isLoading}
                  />
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label="Verification Tests"
                  name="verificationTests"
                  id="verificationTests"
                  inputProps={{
                    min: 0,
                    max: testValue(contractValidation?.verificationTests),
                  }}
                  {...register('verificationTests', {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(contractValidation?.verificationTests) ||
                        `Max value is ${testValue(
                          contractValidation?.verificationTests,
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors.verificationTests)}
                  helperText={errors?.verificationTests?.message}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label={
                    HEALTHCARE_TEST_TYPES_LABELS[HEALTHCARE_TEST_TYPES.NEGATIVE]
                  }
                  name={HEALTHCARE_TEST_TYPES.NEGATIVE}
                  id={HEALTHCARE_TEST_TYPES.NEGATIVE}
                  inputProps={{
                    min: 0,
                    max: testValue(
                      contractValidation?.[HEALTHCARE_TEST_TYPES.NEGATIVE],
                    ),
                  }}
                  {...register(HEALTHCARE_TEST_TYPES.NEGATIVE, {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(
                            contractValidation?.[
                              HEALTHCARE_TEST_TYPES.NEGATIVE
                            ],
                          ) ||
                        `Max value is ${testValue(
                          contractValidation?.[HEALTHCARE_TEST_TYPES.NEGATIVE],
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors[HEALTHCARE_TEST_TYPES.NEGATIVE])}
                  helperText={errors?.[HEALTHCARE_TEST_TYPES.NEGATIVE]?.message}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label={
                    HEALTHCARE_TEST_TYPES_LABELS[HEALTHCARE_TEST_TYPES.NEUTRAL]
                  }
                  name={HEALTHCARE_TEST_TYPES.NEUTRAL}
                  id={HEALTHCARE_TEST_TYPES.NEUTRAL}
                  inputProps={{
                    min: 0,
                    max: testValue(
                      contractValidation?.[HEALTHCARE_TEST_TYPES.NEUTRAL],
                    ),
                  }}
                  {...register(HEALTHCARE_TEST_TYPES.NEUTRAL, {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(
                            contractValidation?.[HEALTHCARE_TEST_TYPES.NEUTRAL],
                          ) ||
                        `Max value is ${testValue(
                          contractValidation?.[HEALTHCARE_TEST_TYPES.NEUTRAL],
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors[HEALTHCARE_TEST_TYPES.NEUTRAL])}
                  helperText={errors?.[HEALTHCARE_TEST_TYPES.NEUTRAL]?.message}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label={
                    HEALTHCARE_TEST_TYPES_LABELS[HEALTHCARE_TEST_TYPES.POSITIVE]
                  }
                  name={HEALTHCARE_TEST_TYPES.POSITIVE}
                  id={HEALTHCARE_TEST_TYPES.POSITIVE}
                  inputProps={{
                    min: 0,
                    max: testValue(
                      contractValidation?.[HEALTHCARE_TEST_TYPES.POSITIVE],
                    ),
                  }}
                  {...register(HEALTHCARE_TEST_TYPES.POSITIVE, {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(
                            contractValidation?.[
                              HEALTHCARE_TEST_TYPES.POSITIVE
                            ],
                          ) ||
                        `Max value is ${testValue(
                          contractValidation?.[HEALTHCARE_TEST_TYPES.POSITIVE],
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors[HEALTHCARE_TEST_TYPES.POSITIVE])}
                  helperText={errors?.[HEALTHCARE_TEST_TYPES.POSITIVE]?.message}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label={TEST_TYPES_LABELS[TEST_TYPES.UV]}
                  name={TEST_TYPES.UV}
                  id={TEST_TYPES.UV}
                  inputProps={{
                    min: 0,
                    max: testValue(contractValidation?.[TEST_TYPES.UV]),
                  }}
                  {...register(TEST_TYPES.UV, {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(contractValidation?.[TEST_TYPES.UV]) ||
                        `Max value is ${testValue(
                          contractValidation?.[TEST_TYPES.UV],
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors[TEST_TYPES.UV])}
                  helperText={errors?.[TEST_TYPES.UV]?.message}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                setIsDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isLoading}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddTestPackageButton;
