import { HEALTHCARE_TEST_TYPES, TEST_TYPES, projectStatus } from 'Constants';
import React from 'react';

import { Grid, Paper, Stack, Typography } from '@mui/material';

import CancelBorderButton from 'Components/Buttons/CancelBorderButton';
import DeleteBorderButton from 'Components/Buttons/DeleteBorderButton';
import { renderTestsIcons } from 'Components/Portfolios/utils';
import StartProjectButton from 'Components/TestPackageItem/StartProjectButton';
import { useTestPackageItems } from 'Components/TestPackageItem/context';

export function renderIcons(siteContract, flexDirection = 'row') {
  const tests = {
    largeSurvey: siteContract.largesurveytests,
    smallSurvey: siteContract.smallsurveytests,
    largeDilution: siteContract.largedilutiontests,
    smallDilution: siteContract.smalldilutiontests,
    miniSurvey: siteContract.minisurveytests,
    recirculation: siteContract.recirculationtests,
    verification: siteContract.verificationtests,
    [HEALTHCARE_TEST_TYPES.NEGATIVE]:
      siteContract[HEALTHCARE_TEST_TYPES.NEGATIVE],
    [HEALTHCARE_TEST_TYPES.POSITIVE]:
      siteContract[HEALTHCARE_TEST_TYPES.POSITIVE],
    [HEALTHCARE_TEST_TYPES.NEUTRAL]:
      siteContract[HEALTHCARE_TEST_TYPES.NEUTRAL],
    [TEST_TYPES.UV]: siteContract[TEST_TYPES.UV],
  };

  return renderTestsIcons(tests, flexDirection);
}

const TestPackageItem = ({
  testPackage,
  handleOpenDeleteTestPackageDialog,
  handleOpenCancelProjectDialog,
}) => {
  const { forceAllowProjectCancel = false } = useTestPackageItems();

  const tpDate = new Date(testPackage?.testpackagedate);
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    weekday: 'short',
  });

  return (
    <Grid item xs={12} style={{ paddingLeft: 0 }}>
      <Paper
        sx={(theme) => ({
          padding: 1,
          border: '1px solid transparent',
          '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
          },
        })}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5">
              Test Package Name: {testPackage.testpackagename}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography>{dateTimeFormat.format(tpDate)}</Typography>
            <Typography>Total tests: {testPackage.totaltests}</Typography>
          </Grid>
          <Grid item xs={12} md={5} lg={6}>
            {renderIcons(testPackage?.testsmetadata ?? testPackage)}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            p={1}
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <Stack
              direction="row"
              spacing={1}
              justifyContent="end"
              alignItems="center"
              width="100%"
            >
              {testPackage.projectid ? (
                <Typography>
                  Project Name:{' '}
                  <a href={`/projects/${testPackage.projectid}`}>
                    {testPackage.projectname}
                  </a>
                </Typography>
              ) : (
                <StartProjectButton testPackage={testPackage} />
              )}
              {testPackage.projectid ? (
                forceAllowProjectCancel ||
                testPackage.status === projectStatus.new ? (
                  <CancelBorderButton
                    tooltipText={'Cancel Project'}
                    clickAction={handleOpenCancelProjectDialog}
                  />
                ) : (
                  <CancelBorderButton
                    tooltipText={'Can not Cancel Project'}
                    isDisable
                  />
                )
              ) : (
                <DeleteBorderButton
                  sx={{ padding: '6px 16px' }}
                  tooltipText={'Delete Test Package'}
                  clickAction={handleOpenDeleteTestPackageDialog}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default TestPackageItem;
