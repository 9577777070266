import React, { forwardRef, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { SCENARIOS_UV_DATA_CONFIG_TABLE } from '../config';
import { useTestScenariosContext } from '../context';

import { Grid, MenuItem, TextField, Typography } from '@mui/material';

import { isSafetracesAdmin } from 'Config/roles';

const UVSettingsForm = forwardRef(
  ({ onSubmit, defaultValues, focusField, type = 'regular' }, ref) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      setFocus,
    } = useForm({
      defaultValues: defaultValues || {},
    });
    const {
      currentTestType,
      forceRestrictAllEdits,
      tagsUsed,
      tagsUsedByScenario,
    } = useTestScenariosContext();

    const disabledFields =
      !(isSafetracesAdmin() && type === 'restricted') && forceRestrictAllEdits;

    const unusedTags = useMemo(() => {
      const result = [];
      const allTags = [
        `Tag-R1`,
        `Tag-R2`,
        `Tag-R3`,
        `Tag-R4`,
        `Tag-R5`,
        `Tag-R6`,
        `Tag-R7`,
        `Tag-R8`,
        `Tag-S1`,
        `Tag-S2`,
        `Tag-S3`,
        `Tag-S4`,
        `Tag-S5`,
        `Tag-S6`,
        `Tag-S7`,
        `Tag-S8`,
      ];

      for (let tag of allTags) {
        if (!tagsUsed.includes(tag)) {
          result.push(tag);
        }
      }
      if (defaultValues?.scenarioId) {
        const tagsInThisScenario = tagsUsedByScenario[defaultValues.scenarioId];
        result.push(...tagsInThisScenario);
      }

      result.sort();

      return result;
    }, [tagsUsed, tagsUsedByScenario]);

    useEffect(() => {
      if (focusField) {
        setFocus(focusField);
      }
    }, []);

    const renderFormFields = () => {
      const result = [];

      for (let [fieldKey, fieldConfig] of Object.entries(
        SCENARIOS_UV_DATA_CONFIG_TABLE,
      )) {
        const {
          key,
          label,
          editable,
          valueAsNumber,
          specialValidator,
          requiredInForm,
          allowedTestTypes,
          presentInRestrictedForm,
        } = fieldConfig;

        const shouldSkip =
          allowedTestTypes && !allowedTestTypes.includes(currentTestType);

        if (
          !editable ||
          shouldSkip ||
          (type === 'restricted' && !presentInRestrictedForm)
        )
          continue;

        if (~['opGroupA', 'opGroupB'].indexOf(fieldKey)) {
          const _label =
            typeof label === 'object' ? label[currentTestType] || label : label;

          const allowedTags = unusedTags.filter((tag) =>
            tag.includes(fieldKey === 'opGroupA' ? 'Tag-R1' : 'Tag-S1'),
          );

          const component = (
            <TextField
              key={fieldKey}
              variant="standard"
              margin="dense"
              label={_label}
              defaultValue={defaultValues?.[key] || ''}
              select
              disabled={disabledFields}
              sx={{ width: '100%' }}
              {...register(key, {
                required: requiredInForm && `${label} is required`,
              })}
              error={!!(errors && errors[key])}
              helperText={errors?.[key]?.message}
            >
              {allowedTags.map((value) => (
                <MenuItem key={value} value={value}>
                  <Typography>{value}</Typography>
                </MenuItem>
              ))}
            </TextField>
          );

          result.push(component);

          continue;
        }

        result.push(
          <TextField
            key={fieldKey}
            variant="standard"
            margin="dense"
            label={label}
            fullWidth
            disabled={disabledFields}
            {...register(key, {
              required: requiredInForm && `${label} is required`,
              valueAsNumber: valueAsNumber,
              validate: (value) =>
                specialValidator ? specialValidator(value) : true,
            })}
            error={!!(errors && errors[key])}
            helperText={errors?.[key]?.message}
          />,
        );
      }

      return result;
    };

    const submit = (vals) => {
      if (typeof onSubmit === 'function') {
        onSubmit(vals);
      }
    };

    return (
      <form ref={ref} onSubmit={handleSubmit(submit)}>
        <Grid container spacing={4}>
          <Grid item>{renderFormFields()}</Grid>
        </Grid>
      </form>
    );
  },
);

UVSettingsForm.displayName = 'UVSettingsForm';

export default UVSettingsForm;
