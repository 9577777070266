import axios from 'axios';

import config from 'Config/config';

const BASEURL = config.back_url + '/scenarios';

const scenarios = {
  createScenarios: async (data) => {
    return await axios.post(BASEURL + '/create', data);
  },
  getScenarios: async (segmentId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }
    return await axios.get(BASEURL + '?segmentId=' + segmentId, options);
  },
  updateScenarios: async (scenarioId, data) => {
    return await axios.put(BASEURL + '/' + scenarioId, data);
  },
  // HEALTHCARE SCENARIOS SECTION
  createUVorHealthcareScenario: async (data) => {
    return await axios.post(`${BASEURL}/createUVorHealthcareScenario`, data);
  },
  deleteHealthcareScenario: async (scenarioId) => {
    return await axios.delete(
      `${BASEURL}/deleteHealthcareScenario/${scenarioId}`,
    );
  },
  updateUVOrHealthcareScenarioTag: async (scenarioId, data) => {
    return await axios.put(`${BASEURL}/${scenarioId}/tag`, data);
  },
  resetScenarioTag: async (scenarioId) => {
    return await axios.put(`${BASEURL}/resetTag/${scenarioId}`);
  },
};

export default scenarios;
