import { HEALTHCARE_TEST_TYPES, TEST_TYPES } from 'Constants';
import React, { useMemo } from 'react';

import ScenariosTable from './OldScenariosTable';
import ScenariosTableHealthcare from './ScenariosTableHealthcare';
import ScenariosTableUV from './ScenariosTableUV';
import { TestScenariosContextProvider } from './context';

import { useProjectContext } from 'Components/SingleProject/context';

function ScenariosTableSingleProjectSingleTestCollapse(props) {
  const { test } = props;
  const {
    fetchProject: reFetchProject,
    refetchProjectTags,
    projectStatus,
  } = useProjectContext();

  const contextValue = useMemo(() => {
    const {
      testid: testId,
      projectid: projectId,
      segmentId,
      testtype: testType,
      testexecuted: testExecuted,
    } = test;

    return {
      testId,
      projectId,
      segmentId,
      testType,
      testExecuted,
      reFetchProject,
      refetchProjectTags,
      projectStatus,
    };
  }, [test, reFetchProject, refetchProjectTags]);

  return (
    <React.Fragment key={`scenarios-${test.testid}`}>
      {Object.values(HEALTHCARE_TEST_TYPES).includes(test.testtype) ? (
        <TestScenariosContextProvider value={contextValue}>
          <ScenariosTableHealthcare insideCollapsibleTable {...props} />
        </TestScenariosContextProvider>
      ) : null}
      {TEST_TYPES.UV === test.testtype ? (
        <TestScenariosContextProvider value={contextValue}>
          <ScenariosTableUV insideCollapsibleTable {...props} />
        </TestScenariosContextProvider>
      ) : null}
      {TEST_TYPES.UV !== test.testtype &&
      !Object.values(HEALTHCARE_TEST_TYPES).includes(test.testtype) ? (
        <ScenariosTable {...props} />
      ) : null}
    </React.Fragment>
  );
}

/**
 * @param {{}} props
 * @param {{
 *  testid: Number,
 *  projectid: Number,
 *  segmentId: Number,
 *  testtype: String,
 *  testexecuted: Boolean,
 *  reFetchProject: Function,
 * }} props.test
 * @returns
 */
export default ScenariosTableSingleProjectSingleTestCollapse;
