import axios from 'axios';

import config from 'Config/config';

const BASEURL = config.back_url + '/floorplans';

const floorPlans = {
  addFloorPlan: async (projectId, data) => {
    return await axios.post(BASEURL + '?projectId=' + projectId, data);
  },
  addFloorPlanForBuilding: async (buildingId, data) => {
    return await axios.post(BASEURL + '?buildingId=' + buildingId, data);
  },
  getFloorPlans: async (projectId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }

    return await axios.get(BASEURL + '?projectId=' + projectId, options);
  },
  getFloorPlansForTest: async (testId) => {
    return await axios.get(BASEURL + '?testId=' + testId);
  },
  getFloorPlansForBuilding: async (buildingId, includeHdaData = false) => {
    const additionalParams = includeHdaData ? '&includeHdaData=1' : '';
    return await axios.get(
      BASEURL + '?buildingId=' + buildingId + additionalParams,
    );
  },
  getPresignedURL: async (data, source) => {
    const options = {
      params: data,
    };
    if (source) {
      options.cancelToken = source.token;
    }

    if (data.projectId && data.buildingId) {
      delete data.projectId;
    }
    return await axios.get(BASEURL + '/getPresignedURL', options);
  },
  uploadFloorPlan: async (url, file, options) => {
    return await axios.put(url, file, options);
  },
  updateFloorPlan: async (floorPlanId, data) => {
    return await axios.put(BASEURL + '/' + floorPlanId, data);
  },
  downloadFloorPlan: async (url) => {
    return await axios.get(url, {
      responseType: 'blob',
      removeHeaderAuth: true,
    });
  },
  deleteFloorPlan: async (floorPlanId) => {
    return await axios.delete(BASEURL + '/' + floorPlanId);
  },
  deleteBrokenFloorPlan: async (floorPlanId, buildingid, filename) => {
    return await axios.delete(
      `${BASEURL}/deleteBroken/?buildingId=${buildingid}&filename=${filename}&floorPlanId=${floorPlanId}`,
    );
  },
  createTestArea: async (mainFpId, bodyObj) => {
    return await axios.post(`${BASEURL}/testArea/create/${mainFpId}`, bodyObj);
  },
  getFloorPlanImageByTestId: async (testId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }

    return await axios.get(`${BASEURL}/getFloorplanForTest/${testId}`, options);
  },
  getFloorplansForProject: (projectId) => {
    return axios.get(`${BASEURL}/getFloorplansForProject/${projectId}`);
  },
  getFloorPlan: async (floorplanId) => {
    return await axios.get(`${BASEURL}/${floorplanId}`);
  },
  getFloorplanHDA: async (floorplanId) => {
    return await axios.get(`${BASEURL}/getFloorPlanHDA/${floorplanId}`);
  },
  getHDATypes: async () => {
    return await axios.get(`${BASEURL}/getHDAtypes`);
  },
  createHDA: async (floorplanId, data) => {
    return await axios.post(`${BASEURL}/hda/create/${floorplanId}`, data);
  },
  updateHDA: async (hdaId, data) => {
    return await axios.put(`${BASEURL}/hda/update/${hdaId}`, data);
  },
  deleteHDA: async (hdaId) => {
    return await axios.delete(`${BASEURL}/hda/${hdaId}`);
  },
};

export default floorPlans;
