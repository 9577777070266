import { TEST_TYPES } from 'Constants';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import segments from 'Api/segments';

const EditSP = ({ cancel, value, testId, reFetchProject, segment, test }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { handleSubmit, register, formState, errors } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    segments
      .updateSegment(segment.segmentid, {
        testId: testId,
        sp: data.sp,
      })
      .then(async () => {
        reFetchProject();
        cancel();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderForm = () => {
    if (!isLoading) {
      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              select
              focused
              variant="outlined"
              fullWidth
              label="Number of SP"
              defaultValue={value}
              {...register('sp')}
              error={!!(errors && errors.sp)}
              helperText={errors?.sp?.message}
            >
              {!~[
                TEST_TYPES.RECIRCULATION,
                TEST_TYPES.VERIFICATION,
                TEST_TYPES.UV,
              ].indexOf(test.testtype)
                ? Array.from({ length: segment.op }, (_, i) => i + 1).map(
                    (value) => (
                      <MenuItem key={value} value={value}>
                        <Typography>{value}</Typography>
                      </MenuItem>
                    ),
                  )
                : null}
              {test.testtype === TEST_TYPES.RECIRCULATION
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value) => (
                    <MenuItem key={value} value={value}>
                      <Typography>{value}</Typography>
                    </MenuItem>
                  ))
                : null}
              {(test.testtype === TEST_TYPES.VERIFICATION ||
                test.testtype === TEST_TYPES.UV) &&
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                  <MenuItem key={value} value={value}>
                    <Typography>{value}</Typography>
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              startIcon={<Save />}
              disabled={!formState.isDirty}
              type="submit"
            >
              Save
            </Button>
            <ButtonGroup>
              <Button onClick={cancel}>Cancel</Button>
            </ButtonGroup>
          </Stack>
        </form>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit number of SP</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && <Alert severity="error">Error when updating SP</Alert>}
    </Stack>
  );
};

export default EditSP;
