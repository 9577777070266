import { ERROR_MESSAGE_DEFAULT } from 'Constants';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

import './styles.css';

import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

import CropperDialog from 'Components/Floorplans/migratedComponents/FloorPlanCropperDialog';
import { pdfToImage } from 'Components/Floorplans/utils';

const FloorplanUploadModal = ({
  open,
  handleClose,
  transitionDuration = 220,
  buildingId,
  buildingName,
}) => {
  const [uploadError, setUploadError] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  useEffect(() => {
    if (!open) {
      setUploadError(null);
      setUploadedFile(null);
      setShowUploadModal(false);
    }
  }, [open]);

  /**
   * Operations after drag&drop (or selecting) an image into the dropzone
   * @param files
   * @returns {Promise<void>}
   */
  const uploadFile = async (files) => {
    if (!files || !files.length) {
      return;
    }
    const file = files[0];
    // If it's a PDF file, convert ot png
    if (file.type === 'application/pdf') {
      file.convertedFile = await pdfToImage(file);
    }
    setUploadError(null);
    setUploadedFile(file);
    setShowUploadModal(true);
  };

  return (
    <React.Fragment>
      {!!uploadedFile && !!showUploadModal && (
        <CropperDialog
          imageFile={uploadedFile}
          buildingId={buildingId}
          floorPlanFiles={[]}
          allBuildings={[
            {
              buildingname: buildingName,
              buildingid: buildingId,
            },
          ]}
          uploadError={setUploadError}
          onCancel={() => {
            setShowUploadModal(false);
            setUploadedFile(null);
          }}
          onUploadCompleted={async () => {
            setShowUploadModal(false);
            setUploadedFile(null);
            handleClose();
          }}
        />
      )}
      <Dialog
        open={open && !showUploadModal}
        transitionDuration={transitionDuration}
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            p: 1,
            pb: 2,
            justifyContent: 'center',
            alignItems: 'center',
            width: '620px',
          },
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          Upload floorplan
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            width: '100%',
            mb: 2,
          }}
        >
          <Box sx={{ width: '100%', height: '100%' }}>
            {!!uploadError ? (
              <Alert sx={{ mb: 2 }} severity="error">
                {typeof uploadError === 'string'
                  ? uploadError
                  : ERROR_MESSAGE_DEFAULT}
              </Alert>
            ) : null}
            <Dropzone onDrop={(acceptedFiles) => uploadFile(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section
                  style={{
                    border: '1px solid #008996',
                    borderRadius: '5px',
                    textAlign: 'center',
                    height: '45px',
                    width: '520px',
                    maxWidth: '100%',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px',
                  }}
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Typography>
                      Drag and drop some file here, or click to select a file
                    </Typography>
                  </div>
                </section>
              )}
            </Dropzone>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default FloorplanUploadModal;
