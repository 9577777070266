import { ERROR_MESSAGE_DEFAULT } from 'Constants';
import axios from 'axios';
import mergeImages from 'merge-images';
import React, { useEffect, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router';
import XLSX from 'xlsx-color';

import FloorplansListSelectable from '../FloorplansListSelectable';
import SingleFloorplanDisplay from '../SingleFloorplanDisplay';
import FloorPlanTemplateImage from '../Template-for-Floor-Plan.png';
import {
  cropImageFromUrlPromisified,
  getMergePosition,
  getScaledImageSize,
  pdfToImage,
} from '../utils';
import CropperDialog from './FloorPlanCropperDialog';
import Resizer from './Resizer';

import { Box, Paper, Stack, Typography } from '@mui/material';

import buildingsApi from 'Api/buildings';
import floorPlansApi from 'Api/floorPlans';
import projectsApi from 'Api/projects';

import DownloadButton from 'Components/Buttons/DownloadButton';
import { useFloorplanContext } from 'Components/Floorplans/context';
import MainAlert from 'Components/Mix/MainAlert';
import CustomCircularProgress from 'Components/UI/CircularProgressWithLabel';

import { useIsMounted } from 'Context';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  getLSViewCompanyId,
  isValidResponse,
} from 'Utils';

export const ProjectDetailsFloorPlan = () => {
  const newAxiosInstance = axios.create();
  const selectedFPImgRef = useRef(null);
  const mounted = useIsMounted();
  const navigate = useNavigate();

  const [selectedFloorPlan, setSelectedFloorPlan] = useState(undefined);
  const [floorplanForTestArea, setFloorplanForTestArea] = useState(undefined);
  const [activeFloorPlanUrl, setActiveFloorPlanUrl] = useState('');

  const [memoForPreview, setMemoForPreview] = useState({});

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  const [loadingPercentCompleted, setLoadingPercentCompleted] = useState(0);

  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  const [fpDownloading, setFpDownloading] = useState(false);
  const [hdaDownloading, setHDADownloading] = useState(false);

  const [showCreateTestAreaModal, setShowCreateTestAreaModal] = useState(false);
  const [testAreaBaseLoading, setTestAreaBaseLoading] = useState(false);
  const [testAreaBaseFile, setTestAreaBaseFile] = useState(null);

  const [allBuildings, setAllBuildings] = useState([]);
  const [uploadError, setUploadError] = useState(null);

  const {
    fetchAllData,
    projectId,
    buildingData,
    floorPlans,
    setError,
    isLoading: isLoadingGlobal,
    buildingId,
    testId,
  } = useFloorplanContext();

  const fetchAllBuildings = async () => {
    const companyId = getLSViewCompanyId() ?? 0;
    if (companyId) {
      const buildingsResponse = await buildingsApi.getBuildingsByCompanyId(
        companyId,
      );
      if (buildingsResponse?.data && buildingsResponse.data?.success) {
        setAllBuildings(buildingsResponse.data.data);
      }
    }
  };

  useEffect(() => {
    fetchAllBuildings();
  }, []);

  /**
   * Display thumbnail of a floor plan after selecting line
   * @returns {Promise<void>}
   * @param id
   * @param filename
   * @param buildingid
   * @param projectid
   * @param parentId
   * @param metadata
   */
  const handleFloorplanItemSelect = async (
    id,
    filename,
    buildingid,
    projectid,
    parentId,
    metadata,
  ) => {
    setTestAreaBaseFile(null);

    const floorPlan = {
      id,
      filename,
      buildingid,
      projectid,
      parentId,
      hasParentFP: !!parentId,
      cropData: metadata?.cropData || null,
    };

    if (selectedFloorPlan?.id === id) {
      clearActiveFloorPlan();
      return;
    }
    if (!floorPlan.hasParentFP) {
      const hdaResp = await floorPlansApi.getFloorplanHDA(floorPlan.id);
      if (!isValidResponse(hdaResp)) {
        setError(`Could not load HDA for floor plan`);
        clearActiveFloorPlan();
        return;
      }
      const hdaData = getDataFromResponse(hdaResp);
      floorPlan.hda = hdaData;
    }

    setSelectedFloorPlan(floorPlan);

    const fileURL = await downloadFile(floorPlan, filename);
    if (fileURL) {
      loadBaseForTestArea(fileURL);
    }
  };

  /**
   * Hiding floor plan thumbnail
   */
  const clearActiveFloorPlan = () => {
    setSelectedFloorPlan(null);
    setTestAreaBaseFile(null);
    setActiveFloorPlanUrl('');
  };

  /**
   * Operations after drag&drop (or selecting) an image into the dropzone
   * @param files
   * @returns {Promise<void>}
   */
  const uploadFile = async (files) => {
    if (!files || !files.length) {
      return;
    }
    const file = files[0];
    // If it's a PDF file, convert ot png
    if (file.type === 'application/pdf') {
      file.convertedFile = await pdfToImage(file);
    }
    setUploadError(null);
    setUploadedFile(file);
    setShowUploadModal(true);
  };

  const getPresignedURL = async (floorplan) => {
    try {
      const fpObj = {
        projectId: floorplan.projectid ?? undefined,
        buildingId: floorplan.buildingid ?? undefined,
        floorPlanId: floorplan.floorplanid,
        heatmapVersion: 1,
        filename: floorplan.filename,
        action: 'getObject',
      };

      if (fpObj.buildingId && fpObj.projectId) {
        delete fpObj.projectId;
      }

      const result = await floorPlansApi.getPresignedURL(fpObj);

      const { preSignedURL } = result.data;

      return preSignedURL;
    } catch (err) {
      console.err(`getPresignedURL Error: `, err);
      return null;
    }
  };

  const loadFileFromS3 = async (url) => {
    const result = {
      data: null,
      type: null,
    };

    try {
      /**
       * Making straight axios call in order to access the ProgressEvent interface,
       * which allows up to caputre download progress and pass that data along to spinner component
       */
      const response = await newAxiosInstance.get(url, {
        responseType: 'blob',
        onDownloadProgress: ({ loaded, total }) => {
          const percentCompleted = Math.round((loaded * 100) / total);
          const newLoadingStatus = percentCompleted !== 100;

          if (Math.abs(loadingPercentCompleted - percentCompleted) >= 2) {
            setLoadingPercentCompleted(percentCompleted);
          }

          if (newLoadingStatus !== isLoadingGlobal) {
            setIsLoadingLocal(percentCompleted !== 100);
          }
        },
      });

      const { data } = response;
      const { type } = data;

      result.data = data;
      result.type = type;
    } catch (err) {
      console.log(`loadFileFromS3 Error: `, err);
    } finally {
      return result;
    }
  };

  /**
   * Downloading image with a floor plan from the S3 bucket
   * @param floorplan
   * @param filename
   * @returns {Promise<void>}
   */
  const downloadFile = async (floorplan) => {
    setLoadingPercentCompleted(0);
    setIsLoadingLocal(true);

    let finalUrl = '';
    let floorplanId;

    try {
      /**
       * Test areas (or floorplans' children) do not have their own image on
       * S3, therefore image we need for them is their parent's.
       */
      floorplanId = floorplan.hasParentFP ? floorplan.parentId : floorplan.id;

      if (memoForPreview[floorplanId]?.dataUrl) {
        finalUrl = memoForPreview[floorplanId].dataUrl;

        setActiveFloorPlanUrl(finalUrl);
        setIsLoadingLocal(false);

        return;
      }

      const memoPresignedURL = memoForPreview[floorplanId]?.preSignedURL;
      /**
       * 1: Get pre-signed url from server and add it to cache
       */
      const preSignedURL =
        memoPresignedURL || (await getPresignedURL(floorplan));

      if (!preSignedURL) {
        throw new Error(`No pre-signed URL for floorplan`);
      }

      if (!memoForPreview[floorplanId]) {
        memoForPreview[floorplanId] = {};
      }

      memoForPreview[floorplanId].preSignedURL = preSignedURL;

      /**
       * 2. Try to obtain data from s3. In case no success and having used URL from cache,
       * try again, deleting old presigned URL from cache.
       */
      const { data, type } = await loadFileFromS3(preSignedURL);
      if (!data) {
        if (memoPresignedURL) {
          delete memoForPreview[floorplanId].preSignedURL;

          return await downloadFile(floorplan);
        } else throw new Error();
      }

      /**
       * 3. Download file in required format - create a blob url and render in img tag
       */
      const blob = new Blob([data], { type });
      const createObjectURL =
        window.URL.createObjectURL || window.webkitURL.createObjectURL;
      const url = createObjectURL(blob);

      /**
       * 4. Update cache and set data url
       */
      memoForPreview[floorplanId].dataUrl = url;
      finalUrl = url;

      if (mounted.current) {
        setMemoForPreview({ ...memoForPreview });
        setActiveFloorPlanUrl(finalUrl);
        setIsLoadingLocal(false);
      }
    } catch (e) {
      console.error(e);
      if (memoForPreview[floorplanId]) {
        delete memoForPreview[floorplanId];
      }
      if (mounted.current) {
        setError('Error loading the floor plan image');
        setActiveFloorPlanUrl('');
        setIsLoadingLocal(false);
      }
    } finally {
      return finalUrl;
    }
  };

  const updateProjectStatus = async (i) => {
    try {
      if (!projectId) return;

      const result = await projectsApi.updateStatus(projectId, 'in_progress');
      if (!result?.data?.success) throw new Error();
    } catch (err) {
      if (i < 2) {
        return updateProjectStatus(i + 1);
      } else {
        console.error('Project status update error: ', err);
        return null;
      }
    }
  };

  const getFloorplanUrlAndImage = async ({ name, cropData }) => {
    let url = ``;
    let image = null;

    try {
      const response = await floorPlansApi.getPresignedURL({
        projectId: projectId ?? undefined,
        buildingId: buildingId ?? undefined,
        filename: name,
        action: 'getObject',
        heatmapVersion: 1,
      });

      if (response?.data?.preSignedURL) {
        const { preSignedURL } = response.data;
        if (!preSignedURL) {
          throw new Error(`No presigned URL`);
        }

        const _fp = await floorPlansApi.downloadFloorPlan(preSignedURL);
        if (!_fp) {
          throw new Error(`Broken presigned URL`);
        }

        const { data } = _fp;
        const { type } = data;

        const blob = new Blob([data], { type });

        const createObjectURL =
          window.URL.createObjectURL || window.webkitURL.createObjectURL;

        url = createObjectURL(blob);

        if (cropData) {
          url = await cropImageFromUrlPromisified({
            url,
            cropData,
          });
        }

        if (url) {
          image = await new Promise((resolve, reject) => {
            const image = new Image();
            image.src = url;

            image.addEventListener('load', () => {
              resolve(image);
            });
            image.addEventListener('error', (e) => {
              console.error('Failed to load the image', e);
              reject(new Error('Failed to load the image'));
            });
          });
        }
      }
    } catch (err) {
      url = null;
      image = null;
      console.log(`Floorplan URL obtain Error: `, err);
    } finally {
      return [url, image];
    }
  };

  /**
   * TODO: add memo for downloads, same logic as preview memo
   */
  const handleDownloadFP = async (fpId) => {
    try {
      setFpDownloading(true);

      const floorplan = floorPlans.find((fp) => fp.floorplanid === fpId);
      const { filename: fileName, metadata } = floorplan;
      const cropData = metadata?.cropData || null;

      const [url, image] = await getFloorplanUrlAndImage({
        id: fpId,
        name: fileName,
        cropData,
      });

      if (!url || !image) {
        throw new Error();
      }

      const size = getScaledImageSize(image);
      const offset = getMergePosition(size);

      const fpToMergeUrl = Resizer.resizeAndRotateImage(
        image,
        size.width,
        size.height,
        size.width,
        size.height,
        'PNG',
        100,
        0,
      );

      const resultURL = await mergeImages(
        [
          { src: FloorPlanTemplateImage, x: 0, y: 0 },
          {
            src: fpToMergeUrl,
            x: offset.x,
            y: offset.y,
            opacity: 1,
          },
        ],
        { crossOrigin: 'anonymous' },
      );

      const link = document.createElement('a');

      link.setAttribute('href', resultURL);
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();
      return true;
    } catch (err) {
      console.log(err, 'err');
      return true;
    } finally {
      if (mounted.current) {
        setFpDownloading(false);
      }
    }
  };

  const loadBaseForTestArea = async (fileURL = null) => {
    if (!activeFloorPlanUrl && !fileURL) {
      return;
    }

    setTestAreaBaseLoading(true);

    try {
      const response = await axios({
        method: 'get',
        url: fileURL || activeFloorPlanUrl,
        responseType: 'blob',
      });

      if (response?.status !== 200) {
        throw new Error();
      }

      const { data } = response;

      if (mounted.current) {
        setTestAreaBaseFile(data);
      }

      return data;
    } catch (err) {
      console.error(`loadBaseForTestArea Error: `, err);

      return null;
    } finally {
      if (mounted.current) {
        setTestAreaBaseLoading(false);
      }
    }
  };

  const openCreateTestAreaModal = async () => {
    try {
      if (!testAreaBaseFile && testAreaBaseLoading) {
        return setTimeout(openCreateTestAreaModal, 200);
      }
      setShowCreateTestAreaModal(true);
    } catch (err) {
      console.error(err);
      setError(`Something went wrong. Please, try again later.`);
    }
  };

  const handleDownloadHDASummary = async () => {
    try {
      setHDADownloading(true);

      const [hdaConfResponse, hdaBuildingResponse] = await Promise.all([
        floorPlansApi.getHDATypes(),
        buildingsApi.getHDASummary(buildingId),
      ]);
      if (
        !isValidResponse(hdaConfResponse) ||
        !isValidResponse(hdaBuildingResponse)
      ) {
        const errMsg = !isValidResponse(hdaConfResponse)
          ? getErrorMessageFromResponse(hdaConfResponse)
          : getErrorMessageFromResponse(hdaBuildingResponse);
        throw new Error(errMsg);
      }
      const buildingHDAData = getDataFromResponse(hdaBuildingResponse);
      const hdaConf = getDataFromResponse(hdaConfResponse);

      const { dataByFloor, dataByType } = buildingHDAData;

      const rowsPerFloorplanData =
        Object.keys(dataByFloor).length +
        Object.values(dataByFloor).reduce((acc, curr) => {
          const totalRowsPerFloor = Object.values(curr).reduce(
            (acc, fp) => acc + (fp.hda.length || 1),
            0,
          );
          return acc + totalRowsPerFloor;
        }, 0) +
        1;
      const rowsPerHdaTypes = Object.keys(dataByType).length + 3;
      const rowsAmount = rowsPerFloorplanData + rowsPerHdaTypes;

      const columnsAmount = 4; // floorplan name, hda name, hda type

      const alphabetArr = Array.from(`ABCDEFGHIJKLMNOPQRSTUVWXYZ`);

      const xlsxBook = XLSX.utils.book_new();
      const contentSheet = XLSX.utils.aoa_to_sheet(
        Array(rowsAmount)
          .fill(1)
          .map(() => alphabetArr.slice(0, columnsAmount).map(() => ``)),
      );

      contentSheet['!cols'] = [];
      for (let columnNumber = 0; columnNumber < columnsAmount; columnNumber++) {
        const widthConfigArr = contentSheet['!cols'];
        widthConfigArr.push(getXlsxCellWidthObj(48));
      }

      let row = 1;

      contentSheet[`A${row}`].v = 'Floorplan name';
      contentSheet[`B${row}`].v = 'HDA name';
      contentSheet[`C${row}`].v = 'Application type';
      contentSheet[`D${row}`].v = 'Space type';
      row++;

      for (let [floorNumber, floorData] of Object.entries(dataByFloor)) {
        const _floorNumber = parseInt(floorNumber);
        const floorNumberHeader = ~_floorNumber
          ? `Floor ${_floorNumber}`
          : `Floor N/A`;
        contentSheet[`A${row}`].v = floorNumberHeader;

        row++;
        for (let floorplansData of Object.values(floorData)) {
          const { name, hda } = floorplansData;
          contentSheet[`A${row}`].v = name;
          for (let singleHda of hda) {
            contentSheet[`B${row}`].v = singleHda.name;
            let hdaTypeName = '';
            let hdaApplicationName = '';
            for (let hdaTypes of Object.values(hdaConf)) {
              if (hdaTypes.spaceTypes[singleHda.type]) {
                hdaApplicationName = hdaTypes.applicationName;
                hdaTypeName = hdaTypes.spaceTypes[singleHda.type];
                break;
              }
            }
            contentSheet[`C${row}`].v = hdaApplicationName;
            contentSheet[`D${row}`].v = hdaTypeName;
            row++;
          }
          if (!hda.length) {
            row++;
          }
        }
      }

      row++;
      contentSheet[`A${row}`].v = 'The number of HDA for each space type';
      row++;
      row++;
      for (let [hdaType, hdaTypeAmount] of Object.entries(dataByType)) {
        let hdaTypeName = '';
        for (let hdaTypes of Object.values(hdaConf)) {
          if (hdaTypes.spaceTypes[hdaType]) {
            hdaTypeName = hdaTypes.spaceTypes[hdaType];
            break;
          }
        }
        contentSheet[`A${row}`].v = hdaTypeName;
        contentSheet[`B${row}`].v = hdaTypeAmount;
        row++;
      }

      XLSX.utils.book_append_sheet(xlsxBook, contentSheet);

      XLSX.writeFile(xlsxBook, `${buildingData.buildingname} HDA summary.xlsx`);
    } catch (err) {
      console.log('handleDownloadHDASummary Error: ', err);
      if (mounted.current) {
        setError(err.message || ERROR_MESSAGE_DEFAULT);
      }
    } finally {
      if (mounted.current) {
        setHDADownloading(false);
      }
    }
  };

  const getXlsxCellWidthObj = (width) => {
    return {
      wch: width,
    };
  };

  if (!floorPlans) {
    return null;
  }

  const handleTestAreaCreationStart = async (id) => {
    try {
      setTestAreaBaseFile(null);
      setIsLoadingLocal(true);

      const floorplan = floorPlans.find((fp) => fp.floorplanid === id);
      const fpForTestArea = {
        id,
        filename: floorplan.filename,
        buildingid: floorplan.buildingid,
        projectid: floorplan.projectid,
        parentId: floorplan.mainfloorplanid,
        hasParentFP: !!floorplan.mainfloorplanid,
        cropData: floorplan.metadata?.cropData || null,
      };

      const hdaResp = await floorPlansApi.getFloorplanHDA(id);
      if (!isValidResponse(hdaResp)) {
        setError(`Could not load HDA for floor plan`);
        return;
      }
      const hdaData = getDataFromResponse(hdaResp);
      fpForTestArea.hda = hdaData;

      setFloorplanForTestArea(fpForTestArea);

      const fileURL = await downloadFile(fpForTestArea, fpForTestArea.filename);
      if (fileURL) {
        loadBaseForTestArea(fileURL);
      }

      openCreateTestAreaModal();
    } catch (err) {
      console.log('handleTestAreaCreationStart Error: ', err);
      setError(ERROR_MESSAGE_DEFAULT);
      setTestAreaBaseFile(null);
    }
  };

  return (
    <>
      {!!uploadedFile && !!showUploadModal && (
        <CropperDialog
          imageFile={uploadedFile}
          projectId={projectId}
          buildingId={buildingId}
          floorPlanFiles={floorPlans}
          allBuildings={allBuildings}
          uploadError={setUploadError}
          onCancel={() => {
            setShowUploadModal(false);
            setUploadedFile(null);
          }}
          onUploadCompleted={async () => {
            if (Array.isArray(floorPlans) && !floorPlans.length) {
              await updateProjectStatus(0);
            }
            setShowUploadModal(false);
            setUploadedFile(null);
            fetchAllData();
          }}
        />
      )}
      <MainAlert message={uploadError} type={'error'} />
      {!!testAreaBaseFile &&
        !testAreaBaseLoading &&
        !!showCreateTestAreaModal && (
          <CropperDialog
            type="testArea"
            imageFile={testAreaBaseFile}
            projectId={projectId}
            buildingId={buildingId}
            allBuildings={allBuildings}
            mainFpId={floorplanForTestArea?.id}
            mainFpName={floorplanForTestArea?.filename || ``}
            hdaList={floorplanForTestArea?.hda || []}
            floorPlanFiles={floorPlans}
            onCancel={() => {
              setShowCreateTestAreaModal(false);
              setFloorplanForTestArea(null);
            }}
            onUploadCompleted={async () => {
              setShowCreateTestAreaModal(false);
              setFloorplanForTestArea(null);
              fetchAllData();
            }}
          />
        )}
      <Stack direction="row" sx={{ mt: 2 }}>
        <Box width="50%">
          <Stack direction="column" gap={2}>
            <Dropzone onDrop={(acceptedFiles) => uploadFile(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section
                  style={{
                    border: '1px solid #008996',
                    borderRadius: '5px',
                    textAlign: 'center',
                    height: '45px',
                    width: '520px',
                    maxWidth: '100%',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px',
                  }}
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Typography>
                      Drag and drop some file here, or click to select a file
                    </Typography>
                  </div>
                </section>
              )}
            </Dropzone>
            <Stack direction="row" gap={2} sx={{ mt: 2 }}>
              <DownloadButton
                text="HDA summary"
                clickAction={handleDownloadHDASummary}
                disabled={isLoadingLocal || hdaDownloading}
                loading={isLoadingLocal || hdaDownloading}
              />
            </Stack>
            <FloorplansListSelectable
              selectedFPId={selectedFloorPlan?.id}
              handleSelect={handleFloorplanItemSelect}
              handleDownload={handleDownloadFP}
              loading={isLoadingLocal || fpDownloading}
              handleTestAreaCreationStart={handleTestAreaCreationStart}
              handleRedirectToHDA={(event, id) => {
                const searchParams = [`buildingId=${buildingId}`];
                if (testId) {
                  searchParams.push(`testId=${testId}`);
                }
                if (projectId) {
                  searchParams.push(`projectId=${projectId}`);
                }

                const path = `/floorplanner-hda/${id}?${searchParams.join(
                  '&',
                )}`;
                if (event.metaKey || event.ctrlKey) {
                  window.open(path);
                  return;
                }

                navigate(path);
              }}
            />
          </Stack>
        </Box>
        {!!selectedFloorPlan && (
          <Box
            width="50%"
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Paper
              sx={{
                height: '300px',
                width: '432px',
                position: 'fixed',
                top: 'calc((100% - 80px) / 2)',
              }}
            >
              {!!isLoadingLocal ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CustomCircularProgress value={loadingPercentCompleted} />
                </Box>
              ) : (
                <SingleFloorplanDisplay
                  ref={selectedFPImgRef}
                  url={activeFloorPlanUrl}
                  cropData={selectedFloorPlan?.cropData}
                />
              )}
            </Paper>
          </Box>
        )}
      </Stack>
    </>
  );
};
