import { HEALTHCARE_TEST_TYPES, TEST_TYPES } from 'Constants';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useProjectContext } from '../../context';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';

import originpoints from 'Api/originpoints';
import scenarios from 'Api/scenarios';

import TagsSelectorTableCell from 'Components/SingleProject/Main/Tables/TagsSelectorTableCell';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

import { StyledTableCell, StyledTableRow } from 'Layout/layoutStyles';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

export default function TagsSelectorTable({ updateProjectData }) {
  const { projectTests, isProjectTagsLoading } =
    useSelector(projectDataSelector);

  const { setMainError } = useProjectContext();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogMeta, setDialogMeta] = useState({});
  const [healthcareOPsByTestId, setHealthcareOPsByTestId] = useState({});

  /**
   * Find out how many columns should be present in the table based on the number of scenarios
   */
  const columnsHeaderScenarioAmount = projectTests.reduce((acc, { scnrs }) => {
    if (Array.isArray(scnrs) && scnrs.length > acc) {
      return scnrs.length;
    }
    return acc;
  }, 0);
  const testsWithScenarios = projectTests.reduce((acc, test) => {
    if (Array.isArray(test.scnrs) && test.scnrs.length > 0) {
      const _test = Object.assign({}, test, {
        scnrs: [...test.scnrs].sort((a, b) => a.scenarioid - b.scenarioid),
      });

      acc.push(_test);
    }
    return acc;
  }, []);

  useEffect(() => {
    getOriginPointsDataForTests();
  }, []);

  const getOriginPointsDataForTests = async () => {
    setIsLoading(true);

    try {
      const opsByTest = {};
      for (let test of testsWithScenarios) {
        if (Object.values(HEALTHCARE_TEST_TYPES).includes(test.testtype)) {
          const response = await originpoints.getAllOriginPointsForTest(
            test.testid,
          );
          const OPdata = getDataFromResponse(response);
          let healthcareOPCTagId;
          let healthcareOPBtagId;
          for (let OP of OPdata) {
            if (OP.opnumber === 'OP-001') {
              healthcareOPCTagId = OP.opid;
            }
            if (OP.opnumber === 'OP-002') {
              healthcareOPBtagId = OP.opid;
            }
          }
          opsByTest[test.testid] = {
            ...OPdata,
            healthcareOPCTagId,
            healthcareOPBtagId,
          };
        }
      }

      setHealthcareOPsByTestId(opsByTest);
    } catch (err) {
      console.log(`getOriginPointsDataForTests: `, err);
    } finally {
      setIsLoading(false);
    }
  };

  const openTagResetDrawer = async (test, scenario) => {
    let healthcareOPCTagId;
    let healthcareOPBtagId;

    if (Object.values(HEALTHCARE_TEST_TYPES).includes(test.testtype)) {
      const response = await originpoints.getAllOriginPointsForTest(
        test.testid,
      );
      if (!isValidResponse(response)) {
        setMainError(getErrorMessageFromResponse(response));
        return;
      }

      const OPdata = getDataFromResponse(response);
      for (let OP of OPdata) {
        if (OP.opnumber === 'OP-001') {
          healthcareOPCTagId = OP.opid;
        }
        if (OP.opnumber === 'OP-002') {
          healthcareOPBtagId = OP.opid;
        }
      }
    }

    setDialogMeta({
      title: 'Reset scenario tag',
      type: 'reset',
      text: 'Are you sure you want to reset this scenario tag?',
      testType: test.testtype,
      scenarioId: scenario.scenarioid,
      healthcareOPCTagId,
      healthcareOPBtagId,
    });
    setIsDialogOpen(true);
  };

  const closeTagsDrawer = () => {
    setIsDialogOpen(false);
    setTimeout(() => setDialogMeta({}), 500);
  };

  const handleResetTag = async () => {
    const promises = [];

    if (Object.values(HEALTHCARE_TEST_TYPES).includes(dialogMeta.testType)) {
      promises.push(
        scenarios.updateUVOrHealthcareScenarioTag(dialogMeta.scenarioId, {
          updateType: 'reset',
          opId: dialogMeta.healthcareOPCTagId,
        }),
      );
      if (dialogMeta.testType === HEALTHCARE_TEST_TYPES.POSITIVE) {
        promises.push(
          scenarios.updateUVOrHealthcareScenarioTag(dialogMeta.scenarioId, {
            updateType: 'reset',
            opId: dialogMeta.healthcareOPBtagId,
          }),
        );
      }
    } else {
      promises.push(scenarios.resetScenarioTag(dialogMeta.scenarioId));
    }
    const res = await Promise.all(promises);
    const isSuccess = res.every((r) => isValidResponse(r));

    if (isSuccess) {
      setIsDialogOpen(false);
      setTimeout(() => setDialogMeta({}), 500);
      updateProjectData();
    } else {
      setMainError('Something went wrong');
    }
  };

  const handleTagUpdate = async (dialogMeta) => {
    setIsLoading(true);
    try {
      const promises = [];

      if (Object.values(HEALTHCARE_TEST_TYPES).includes(dialogMeta.testType)) {
        promises.push(
          scenarios.updateUVOrHealthcareScenarioTag(dialogMeta.scenarioId, {
            tag: dialogMeta.cTagValue,
            opId: dialogMeta.healthcareOPCTagId,
          }),
        );

        if (HEALTHCARE_TEST_TYPES.POSITIVE === dialogMeta.testType) {
          promises.push(
            scenarios.updateUVOrHealthcareScenarioTag(dialogMeta.scenarioId, {
              tag: dialogMeta.bTagValue,
              opId: dialogMeta.healthcareOPBtagId,
            }),
          );
        }
      } else if (TEST_TYPES.UV === dialogMeta.testType) {
        promises.push(
          scenarios.updateUVOrHealthcareScenarioTag(dialogMeta.scenarioId, {
            opGroupA: dialogMeta.opGroupA,
            opGroupB: dialogMeta.opGroupB,
            testType: TEST_TYPES.UV,
          }),
        );
      } else {
        for (let opid of dialogMeta.OPids) {
          promises.push(
            originpoints.setOpTag({
              OPId: opid,
              scenarioId: String(dialogMeta.scenarioId),
              tag: dialogMeta.cTagValue,
            }),
          );
        }
      }

      const res = await Promise.all(promises);
      const isSuccess = res.every((r) => isValidResponse(r));
      if (isSuccess) {
        setIsDialogOpen(false);
        setTimeout(() => setDialogMeta({}), 500);
        updateProjectData();
      } else {
        setMainError('Something went wrong');
      }

      setIsLoading(false);
    } catch (err) {
      setMainError(err.message);
      setIsLoading(false);
    }
  };

  if (!columnsHeaderScenarioAmount) return null;

  return (
    <React.Fragment>
      <Dialog open={isDialogOpen}>
        <DialogTitle>{dialogMeta.title}</DialogTitle>
        <DialogContent
          sx={{ width: 420, display: 'flex', justifyContent: 'center' }}
        >
          <Stack
            direction="column"
            sx={{ p: dialogMeta.type === 'reset' ? 0 : 1 }}
          >
            {dialogMeta.text ? (
              <Typography>{dialogMeta.text}</Typography>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 1 }}>
          <Button onClick={closeTagsDrawer} sx={{ mr: 2 }} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            variant="contained"
            startIcon={
              isLoading ? (
                <CircularProgress color="inherit" size={'1rem'} />
              ) : null
            }
            onClick={
              dialogMeta.type === 'reset' ? handleResetTag : handleTagUpdate
            }
            color={dialogMeta.type === 'reset' ? 'error' : 'primary'}
          >
            {dialogMeta.type === 'reset' ? 'Reset' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <TableContainer component={Paper}>
            <Table aria-label="table" sx={{ tableLayout: 'fixed' }}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center" widthcol={4}>
                    Test
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    widthcol={2}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Test number
                  </StyledTableCell>
                  {Array.from({ length: columnsHeaderScenarioAmount }).map(
                    (v, i) => (
                      <StyledTableCell
                        key={i}
                        align="center"
                        widthcol={18 / columnsHeaderScenarioAmount}
                      >
                        Scenario {i + 1}
                      </StyledTableCell>
                    ),
                  )}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {testsWithScenarios.map((test) => (
                  <StyledTableRow
                    key={`${test.testid}`}
                    sx={{ '& > *': { borderBottom: 'unset' } }}
                  >
                    <StyledTableCell align="left">
                      {isProjectTagsLoading ? (
                        <Skeleton>
                          <span>{test.testname}</span>
                        </Skeleton>
                      ) : (
                        test.testname
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {isProjectTagsLoading ? (
                        <Skeleton sx={{ margin: 'auto' }}>
                          <span>{test.orderNumber}</span>
                        </Skeleton>
                      ) : (
                        test.orderNumber
                      )}
                    </StyledTableCell>
                    {test.scnrs.map((snr) => (
                      <StyledTableCell key={snr.scenarioid} align="center">
                        {isProjectTagsLoading ? (
                          <Skeleton sx={{ margin: 'auto' }}>
                            <TagsSelectorTableCell
                              dialogMeta={dialogMeta}
                              openTagResetDrawer={openTagResetDrawer}
                              setDialogMeta={setDialogMeta}
                              test={test}
                              snr={snr}
                              opData={healthcareOPsByTestId[test.testid] || {}}
                              handleTagUpdate={handleTagUpdate}
                            />
                          </Skeleton>
                        ) : (
                          <TagsSelectorTableCell
                            dialogMeta={dialogMeta}
                            openTagResetDrawer={openTagResetDrawer}
                            setDialogMeta={setDialogMeta}
                            test={test}
                            snr={snr}
                            handleTagUpdate={handleTagUpdate}
                            opData={healthcareOPsByTestId[test.testid] || {}}
                          />
                        )}
                      </StyledTableCell>
                    ))}
                    {Array.from({
                      length: columnsHeaderScenarioAmount - test.scnrs.length,
                    }).map((i, index) => (
                      <StyledTableCell key={index} />
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
