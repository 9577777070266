import { colorsFilterValues } from 'Constants';
import {
  HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR,
  HEATMAP_VERIFICATION_LIKE_BEHAVIOR,
  TEST_TYPES,
} from 'Constants';
import pptxgen from 'pptxgenjs';

import { getScaledImageSize } from 'Components/Floorplans/utils';
import {
  getHealthCareSpDisplayValues,
  getSamplePointColorByValueUsingGradientReader,
  gradientReaderObj,
  healthcareTextMarginConfigByRowsAmount,
} from 'Components/HeatMap/components/SingleSP';

import { getCalcResultLabelFromVerLikeTest } from 'Utils';

const PRESENTATION_DEFAULT_SIZE = {
  w: 10,
  h: 5.625,
};

const PRESENTATION_DEFAULT_LEFT_MARGIN = 3.823;

const createPresentation = () => new pptxgen();

/**
 * @param {pptxgen} pres
 * @param {String} title
 * @param {?String} subtitle
 * @param {?{}} titleOptions
 * @returns
 */
const createTemplateSlideForPresentation = (
  pres,
  title,
  subtitle = '',
  titleOptions = {},
  footer,
) => {
  const slide = pres.addSlide();

  slide.addShape(pres.ShapeType.rect, {
    fill: { color: '#F5F5F8' },
    x: 0,
    y: 0,
    w: PRESENTATION_DEFAULT_LEFT_MARGIN,
    h: PRESENTATION_DEFAULT_SIZE.h,
  });

  if (title) {
    slide.addText(title, {
      x: 0.292,
      y: 0.32,
      w: titleOptions.width ? titleOptions.width : '100%',
      valign: 'top',
      fontSize: 24,
      bold: true,
      align: 'left',
      color: '000000',
      fontFace: 'Lato',
    });
  }

  if (footer) {
    const pidMatch = footer.match(/PID: \d+/) || footer.match(/PID:\d+/);

    if (pidMatch) {
      const pidText = pidMatch[0];
      const beforePID = footer.slice(0, pidMatch.index);
      const afterPID = footer.slice(pidMatch.index + pidText.length);

      let textRuns;
      if (beforePID) {
        textRuns = [
          { text: beforePID, options: { color: '#000000' } },
          { text: pidText, options: { color: 'FFFFFF' } },
          { text: afterPID, options: { color: 'FFFFFF' } },
        ];
      } else {
        textRuns = [{ text: pidText, options: { color: 'FFFFFF' } }];
        if (afterPID) {
          textRuns.push({ text: afterPID, options: { color: '000000' } });
        }
      }

      slide.addText(textRuns, {
        x: PRESENTATION_DEFAULT_LEFT_MARGIN,
        y: 5.34,
        w: 3,
        valign: 'top',
        fontSize: 14,
        align: 'left',
        fontFace: 'Lato Regular',
      });
    } else {
      slide.addText(footer, {
        x: PRESENTATION_DEFAULT_LEFT_MARGIN,
        y: 5.34,
        w: 3,
        valign: 'top',
        fontSize: 14,
        align: 'left',
        color: '000000',
        fontFace: 'Lato Regular',
      });
    }
  }

  slide.addText('Confidential', {
    x: 8.75,
    y: 5.34,
    w: 2,
    valign: 'top',
    fontSize: 14,
    align: 'left',
    color: '000000',
    fontFace: 'Lato Regular',
  });

  slide.addShape(pres.ShapeType.rect, {
    fill: { color: 'BEC3C6' },
    x: 0.354,
    y: 0.78575,
    w: 9.29,
    h: 0.01,
  });

  if (subtitle) {
    slide.addText(subtitle, {
      x: 0.292,
      y: 0.885,
      w: '100%',
      valign: 'top',
      fontSize: 18,
      align: 'left',
      color: '000000',
      fontFace: 'Lato Regular',
    });
  }

  slide.addImage({
    path: '/SafeTraces_Logo.png',
    x: 0.23,
    y: 5.115,
    w: 1.1952,
    h: 0.2917,
  });

  return slide;
};

const createSignificanceRectangleImage = (significance) => {
  const drawRoundedRect = (
    ctx,
    x,
    y,
    width,
    height,
    radius,
    fillColor,
    borderColor,
  ) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
    if (borderColor) {
      ctx.lineWidth = 5;
      ctx.strokeStyle = borderColor;
      ctx.stroke();
    }
  };

  let colorView = '#888888';
  switch (significance) {
    case colorsFilterValues.HIGH:
      colorView = '#ff0000';
      break;
    case colorsFilterValues.LOW:
      colorView = '#008000';
      break;
    case colorsFilterValues.MEDIUM:
      colorView = '#ffff00';
      break;
    default:
      break;
  }

  const canvas = document.createElement('canvas');
  canvas.width = 400;
  canvas.height = 400;
  const ctx = canvas.getContext('2d');

  ctx.fillStyle = '#FFFFFF';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  const padding = 50;
  drawRoundedRect(
    ctx,
    10,
    10,
    canvas.width - 20,
    canvas.height - 20,
    30,
    '#FFFFFF',
    '#000000',
  );
  drawRoundedRect(
    ctx,
    padding,
    padding,
    canvas.width - 2 * padding,
    canvas.height - 2 * padding,
    0,
    colorView,
  );

  return canvas.toDataURL();
};

const VERIFICATION_MERGED_TABLE_HEADER = [
  {
    text: 'Test Location',
    options: { fill: '#004650', color: '#FFFFFF' },
  },
  {
    text: 'Hourly Aerosol Removal Rate',
    options: { fill: '#004650', color: '#FFFFFF' },
  },
  {
    text: 'eACH',
    options: { fill: '#004650', color: '#FFFFFF' },
  },
  {
    text: 'Pass (>4.61 eACH)',
    options: { fill: '#004650', color: '#FFFFFF' },
  },
];

const VERIFICATION_MERGED_TABLE_ROWS_COLORS = ['#F5F5F8', '#F5F5F8'];

/**
 * Expects list of 5 or 6-element arrays depending on multipleTests option
 * @param {Array<Array>} data
 * @returns
 */
const createTableForVerificationHeatmapSummary = (
  data,
  multipleTests = true,
) => {
  const rowsTable = multipleTests
    ? [VERIFICATION_MERGED_TABLE_HEADER]
    : [VERIFICATION_MERGED_TABLE_HEADER.slice(1)];

  for (let row of data) {
    rowsTable.push(
      row.map((text) => {
        return {
          text,
          options: {
            fill: VERIFICATION_MERGED_TABLE_ROWS_COLORS[rowsTable.length % 2],
            border: { pt: 0.2, color: '#000000' },
          },
        };
      }),
    );
  }

  let singleCol = multipleTests
    ? PRESENTATION_DEFAULT_LEFT_MARGIN - 0.2 - 1.2 - 1.2 - 0.6
    : PRESENTATION_DEFAULT_LEFT_MARGIN - 0.2 - 1.2 - 0.8;

  const table = [
    rowsTable,
    {
      x: 0.1,
      y: '25%',
      w: PRESENTATION_DEFAULT_LEFT_MARGIN - 0.2,
      align: 'center',
      fontFace: 'Lato Regular',
      fill: '000000',
      fontSize: 10,
      bold: false,
      valign: 'middle',
      border: { pt: 0.2, color: '#000000' },
      colW: multipleTests ? [1.2, 1.2, 0.6, singleCol] : [1.2, 0.8, singleCol],
    },
  ];

  return table;
};

const addHeatmapOverviewSlideForSingleHeatmap = (
  pres,
  heatmapData,
  imageObj,
) => {
  const isHealthcareHeatmap = !!~HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR.indexOf(
    heatmapData.testtype,
  );
  const isVerificationHeatmap = !!~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(
    heatmapData.testtype,
  );

  const isUvHeatmap = heatmapData.testtype === TEST_TYPES.UV;

  const isGenSurv = !!~[
    TEST_TYPES.SMALL_SURVEY,
    TEST_TYPES.LARGE_SURVEY,
    TEST_TYPES.GENERAL,
  ].indexOf(heatmapData.testtype);

  const LEFT_SPACE_WIDTH = PRESENTATION_DEFAULT_LEFT_MARGIN;

  let slideTitle = `${heatmapData.testname}`;

  let subtitle = '';
  if (isGenSurv) {
    subtitle = `${heatmapData.heatmaps[0]?.op[0]?.opnumber}, ${heatmapData.heatmaps[0]?.op[0]?.opname}`;
  } else {
    const scenarioDescription = heatmapData.scenario?.scenariodescription || '';
    if (scenarioDescription) {
      subtitle += `${scenarioDescription}`;
    }
  }

  let footer = `PID: ${heatmapData.projectId} TestID: ${heatmapData.testid}`;
  if (heatmapData.surveyDate) {
    footer = heatmapData.surveyDate + ' ' + footer;
  }

  const slide = createTemplateSlideForPresentation(
    pres,
    slideTitle,
    subtitle,
    {},
    footer,
  );

  let topMarginForComments = 0;

  if (isHealthcareHeatmap) {
    topMarginForComments += 1;

    const scenarioname = heatmapData.scenario?.scenarioname;

    const scenarioData =
      heatmapData.testmetadata[heatmapData.segmentid]?.scenariosData?.[
        scenarioname
      ];

    const spArr = heatmapData.heatmaps[0]?.sp || [];
    const spDataObj = spArr.reduce((acc, item) => {
      const { spnumber, result } = item;
      if (!acc[spnumber]) {
        const val = result.displayValue.split(' ')[0] || '';
        if (spnumber === 'SP-001') {
          acc.eACH = val;
        }
        acc[spnumber] = val;
      }
      return acc;
    }, {});

    const valuesKeys = ['eACH', 'SP-002', 'SP-003', 'SP-004', 'SP-005'];

    const rowsTable = [
      [
        {
          text: 'Description',
          options: { fill: '#aab3b6', color: '#000000' },
        },
        ...valuesKeys.map((key) => {
          return {
            text: key,
            options: { fill: '#aab3b6', color: '#000000' },
          };
        }),
      ],
      [
        {
          text: heatmapData.scenario.scenariodescription || '',
          options: { fill: '#CED3E8' },
        },
        ...valuesKeys.map((key) => {
          return {
            text: spDataObj[key],
            options: { fill: '#CED3E8' },
          };
        }),
      ],
      [
        ...[
          'Delta P',
          '',
          scenarioData?.deltaPsp002vsp001 || '',
          scenarioData?.deltaPsp003vsp001 || '',
          scenarioData?.deltaPsp004vsp001 || '',
          scenarioData?.deltaPsp005vsp001 || '',
        ].map((val) => {
          return {
            text: val,
            options: { fill: '#E8EBF4' },
          };
        }),
      ],
    ];

    const singleCol = (LEFT_SPACE_WIDTH - 1) / 5;
    slide.addTable(rowsTable, {
      x: 0.1,
      y: 1.8,
      w: LEFT_SPACE_WIDTH,
      align: 'center',
      fontFace: 'Lato Regular',
      fill: '000000',
      fontSize: 10,
      bold: true,
      valign: 'middle',
      colW: [1, singleCol, singleCol, singleCol, singleCol, singleCol],
    });
  }

  if (isVerificationHeatmap) {
    topMarginForComments += 1;

    const testName = heatmapData.testname;
    const testResObj =
      heatmapData.calculatedresult && JSON.parse(heatmapData.calculatedresult);

    /** Same as in results/getVerificationProjectSummary (Project Downloads > Results > Summary) */
    let minimumPercentSP = 'n/a';
    if (testResObj.testValid && testResObj.spDetails) {
      const regex = /([><%])/g;
      minimumPercentSP = Object.values(testResObj.spDetails).reduce(
        (min, curr) => {
          const { displayValue } = curr;

          const spPercent =
            typeof displayValue === 'string' && displayValue.replace(regex, '');
          const floatPercent = parseFloat(spPercent);

          if (!floatPercent) return min;

          if (!min || floatPercent < min) {
            min = floatPercent;
          }

          return min;
        },
        '',
      );
    }

    let eACH = '';
    if (typeof minimumPercentSP === 'number') {
      eACH =
        parseInt(
          Math.round(
            -100 * Math.log10(1 - minimumPercentSP / 100) * Math.log(10),
          ),
        ) / 100;
    }

    const resultLabel = getCalcResultLabelFromVerLikeTest(
      { calculatedresult: heatmapData.calculatedresult },
      true,
    );

    slide.addTable(
      ...createTableForVerificationHeatmapSummary(
        [[testName, minimumPercentSP, eACH, 4.61, resultLabel]],
        false,
      ),
    );
  }

  if (isUvHeatmap) {
    topMarginForComments += 1;
    const resultsTagsLabels = {
      tagR: 'Tag-R1:',
      tagS: 'Tag-S1:',
    };

    const segmentId = heatmapData.segmentid;
    const scenariosData = heatmapData.testmetadata[segmentId]?.scenariosData;
    const scenariosInternalNamesDisplayNamesMap = Object.entries(
      scenariosData,
    ).reduce((acc, [internalName, { displayName }]) => {
      acc[internalName] = displayName;
      return acc;
    }, {});

    const headerScenariosOrder = [];

    const header = [
      {
        text: 'Sample point',
        options: { fill: '#004650', color: '#FFFFFF' },
      },
      ...Object.entries(scenariosInternalNamesDisplayNamesMap).map(
        ([name, displayName]) => {
          headerScenariosOrder.push(name);
          return {
            text: `${displayName} (eACH)`,
            options: { fill: '#004650', color: '#FFFFFF' },
          };
        },
      ),
    ];

    const rows = [];
    for (let { spname, result } of heatmapData.heatmaps[0].sp) {
      const scenariosResults = headerScenariosOrder.map((name) =>
        Object.entries(result[name])
          .reduce((acc, [tag, value]) => {
            acc.push(`${resultsTagsLabels[tag]} ${value}`);
            return acc;
          }, [])
          .join(', '),
      );

      rows.push(
        [spname, ...scenariosResults].map((text) => {
          return {
            text,
            options: {
              fill: VERIFICATION_MERGED_TABLE_ROWS_COLORS[0],
              border: { pt: 0.2, color: '#000000' },
            },
          };
        }),
      );
    }
    slide.addTable([header, ...rows], {
      x: 0.1,
      y: '25%',
      w: PRESENTATION_DEFAULT_LEFT_MARGIN - 0.2,
      align: 'center',
      fontFace: 'Lato Regular',
      fill: '000000',
      fontSize: 10,
      bold: false,
      valign: 'middle',
      border: { pt: 0.2, color: '#000000' },
      colW: [
        1,
        ...Array(headerScenariosOrder.length).fill(
          (PRESENTATION_DEFAULT_LEFT_MARGIN - 1.2) /
            headerScenariosOrder.length,
        ),
      ],
    });
  }

  const commentsText = heatmapData.comments
    ? `${heatmapData.comments}\n${heatmapData.heatmapComment || ''}`
    : heatmapData.heatmapComment || '';

  const textElements = [
    {
      text: 'Comments',
      options: {
        fontSize: 16,
        color: '#000000',
        align: 'left',
        breakLine: true,
        fontFace: 'Lato Bold',
        bold: true,
        autoFit: true,
      },
    },
  ];

  if (
    !isHealthcareHeatmap &&
    !isVerificationHeatmap &&
    heatmapData.calculatedresult
  ) {
    textElements.push({
      text: `• ${heatmapData.calculatedresult} \n`,
      options: {
        fontSize: 14,
        color: '#000000',
        align: 'left',
        autoFit: true,
        fontFace: 'Lato Regular',
      },
    });
  }

  textElements.push({
    text: `• ${commentsText}`,
    options: {
      fontSize: 14,
      color: '#000000',
      align: 'left',
      autoFit: true,
      fontFace: 'Lato Regular',
    },
  });

  slide.addText(textElements, {
    x: 0.1,
    y: isGenSurv ? '25%' : 2.3 + topMarginForComments,
    w: LEFT_SPACE_WIDTH - 0.2,
    h: 2.0,
    valign: 'top',
    autoFit: true,
  });

  /**
   * Add heatmap image to slide with legend if needed
   */
  const addLegend =
    !!imageObj &&
    TEST_TYPES.UV !== heatmapData.testtype &&
    !~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(heatmapData.testtype);
  const healthcareLegend = isHealthcareHeatmap;
  const reductionLogLegend =
    !healthcareLegend &&
    heatmapData.testtype !== TEST_TYPES.GENERAL &&
    heatmapData.testtype !== TEST_TYPES.RECIRCULATION;

  const legendWidth = addLegend
    ? (healthcareLegend || reductionLogLegend ? 0.1 : 0.075) *
      PRESENTATION_DEFAULT_SIZE.w
    : 0;

  if (imageObj) {
    const margins = PRESENTATION_DEFAULT_SIZE.w * 0.05;

    const heatmapMiddlePercent =
      LEFT_SPACE_WIDTH / PRESENTATION_DEFAULT_SIZE.w +
      (1 -
        margins * 2 -
        legendWidth / PRESENTATION_DEFAULT_SIZE.w -
        LEFT_SPACE_WIDTH / PRESENTATION_DEFAULT_SIZE.w) /
        2;

    const slideImgSizeInInch = getScaledImageSize(
      { width: imageObj.size.width, height: imageObj.size.height },
      {
        width:
          PRESENTATION_DEFAULT_SIZE.w -
          LEFT_SPACE_WIDTH -
          margins * 2 -
          legendWidth,
        height: PRESENTATION_DEFAULT_SIZE.h * 0.68,
      },
    );

    slide.addImage({
      data: imageObj.data,
      x: `${
        (heatmapMiddlePercent +
          margins -
          slideImgSizeInInch.width / 2 / PRESENTATION_DEFAULT_SIZE.w) *
        100
      }%`,
      y: '25%',
      w: slideImgSizeInInch.width,
      h: slideImgSizeInInch.height,
    });
  }

  let legendHeight;
  let legendCoordX = PRESENTATION_DEFAULT_SIZE.w * 0.98 - legendWidth;
  let legendCoordY = PRESENTATION_DEFAULT_SIZE.h * 0.25;

  if (addLegend) {
    const heightRatio = healthcareLegend
      ? 1.42
      : reductionLogLegend
      ? 2.26
      : 1.83;
    const path = healthcareLegend
      ? '/heatmap-legend-healthcare.png'
      : reductionLogLegend
      ? '/heatmap-legend-reduction-log.png'
      : '/heatmap-legend-copies-per-million.png';

    legendHeight = legendWidth * heightRatio;
    slide.addImage({
      path,
      x: legendCoordX,
      y: legendCoordY,
      w: legendWidth,
      h: legendHeight,
    });
  }

  // if (TEST_TYPES.RECIRCULATION === heatmapData.testtype) {
  //   slide.addImage({
  //     data: createSignificanceRectangleImage(heatmapData.significance),
  //     x: isGenSurv ? legendCoordX : LEFT_SPACE_WIDTH - 0.55,
  //     y: isGenSurv ? legendCoordY + legendHeight + 0.3 : 1.25,
  //     w: 0.5,
  //     h: 0.5,
  //   });
  // }
};

const drawImageWithHeatmapDataOnCanvas = (floorplanImg, heatmapData) => {
  const isFlexHeatmap = heatmapData.testtype === TEST_TYPES.RECIRCULATION;
  const isHealthcareHeatmap = !!~HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR.indexOf(
    heatmapData.testtype,
  );
  const isVerificationHeatmap = !!~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(
    heatmapData.testtype,
  );

  const isGenSurv = !!~[
    TEST_TYPES.SMALL_SURVEY,
    TEST_TYPES.LARGE_SURVEY,
    TEST_TYPES.GENERAL,
  ].indexOf(heatmapData.testtype);

  const gradientReader = gradientReaderObj.createGradientReader(
    !isGenSurv && heatmapData.testtype !== TEST_TYPES.RECIRCULATION
      ? 'reductionLog'
      : 'copiesPerMillion',
  );

  const floorplan = heatmapData.floorplanData;
  /**
   * No crop for floor plan image is needed if it is the main version of floor plan.
   * If there is a crop data, then we need to crop the image (test area case)
   */
  let startCropPointX = 0;
  let startCropPointY = 0;
  let croppedFloorplanWidth = floorplanImg.width;
  let croppedFloorplanHeight = floorplanImg.height;

  if (!!floorplan?.metadata?.cropData) {
    const { x, y, width, height } = floorplan.metadata.cropData;

    startCropPointX = x;
    startCropPointY = y;
    croppedFloorplanWidth = width;
    croppedFloorplanHeight = height;
  }

  const viewParams = heatmapData?.heatmapmetadata?.viewParams || {};
  const hasSavedViewParams = Object.keys(viewParams).length > 0;

  let containerWidth;
  let containerHeight;
  let scaledImageHeight;
  let scaledImageWidth;
  let offsetX;
  let offsetY;

  let renderedScale;

  if (hasSavedViewParams) {
    const { containerBorderHeight, containerBorderWidth, scale, translation } =
      viewParams;

    containerWidth = containerBorderWidth;
    containerHeight = containerBorderHeight;
    scaledImageHeight = croppedFloorplanHeight * scale;
    scaledImageWidth = croppedFloorplanWidth * scale;
    offsetX = translation?.x;
    offsetY = translation?.y;

    renderedScale = scale;
  } else {
    renderedScale = 800 / croppedFloorplanWidth;

    containerWidth = croppedFloorplanWidth * renderedScale;
    containerHeight = croppedFloorplanHeight * renderedScale;
    scaledImageHeight = croppedFloorplanHeight * renderedScale;
    scaledImageWidth = croppedFloorplanWidth * renderedScale;
    offsetX = 0;
    offsetY = 0;
  }

  // only for better resolution of the final image
  const scaleFactor = 4;

  const canvas = document.createElement('canvas');

  canvas.width = containerWidth * scaleFactor;
  canvas.height = containerHeight * scaleFactor;

  const ctx = canvas.getContext('2d');
  ctx.scale(scaleFactor, scaleFactor);
  ctx.fillStyle = 'white';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.drawImage(
    floorplanImg,
    startCropPointX,
    startCropPointY,
    croppedFloorplanWidth,
    croppedFloorplanHeight,
    offsetX,
    offsetY,
    scaledImageWidth,
    scaledImageHeight,
  );

  const heatmap = heatmapData.heatmaps[0];

  const { sp, op } = heatmap;

  // Apply shift for coordinates
  const originPoints = op.map((originPoint) => {
    const { opcoordx, opcoordy } = originPoint;
    return {
      ...originPoint,
      x:
        parseFloat(opcoordx) * renderedScale +
        (viewParams?.translation?.x || 0),
      y:
        parseFloat(opcoordy) * renderedScale +
        (viewParams?.translation?.y || 0),
    };
  });
  const samplePoints = sp.map((samplePoint) => {
    const { spcoordx, spcoordy } = samplePoint;
    return {
      ...samplePoint,
      x:
        parseFloat(spcoordx) * renderedScale +
        (viewParams?.translation?.x || 0),
      y:
        parseFloat(spcoordy) * renderedScale +
        (viewParams?.translation?.y || 0),
    };
  });

  const { showPointsLabel, spSize } = viewParams;
  const drawParams = hasSavedViewParams
    ? {
        showLabel: showPointsLabel,
        size: spSize,
      }
    : {
        showLabel: true,
        size: 35,
      };

  for (let op of originPoints) {
    const { x, y, opnumber } = op;

    drawOriginPoint(
      ctx,
      x,
      y,
      opnumber,
      isVerificationHeatmap || isHealthcareHeatmap
        ? 'rgb(0, 0, 255)'
        : 'rgb(255, 0, 0)',
      drawParams,
    );
  }

  for (let sp of samplePoints) {
    let color;
    let displayValue;

    const gradientType =
      !isGenSurv && heatmapData.testtype !== TEST_TYPES.RECIRCULATION
        ? 'reductionLog'
        : 'copiesPerMillion';

    switch (true) {
      case isVerificationHeatmap:
        color = getSamplePointColorByValueUsingGradientReader(
          sp.reduction,
          gradientReader,
          gradientType,
          false,
          true,
          sp.result,
        );
        displayValue = sp.result?.displayValue || '';

        break;
      case isGenSurv:
        color = getSamplePointColorByValueUsingGradientReader(
          sp.copiesPerMillion,
          gradientReader,
          gradientType,
          false,
          false,
        );
        displayValue = sp.copiesPerMillionDisplay || '';
        break;
      case isFlexHeatmap:
        color = getSamplePointColorByValueUsingGradientReader(
          gradientType === 'reductionLog' ? sp.reduction : sp.copiesPerMillion,
          gradientReader,
          gradientType,
          false,
          false,
        );
        displayValue =
          gradientType === 'reductionLog'
            ? sp.reduction
            : sp.copiesPerMillionDisplay;
        break;
      case isHealthcareHeatmap:
        color = getSamplePointColorByValueUsingGradientReader(
          sp.reduction,
          gradientReader,
          gradientType,
          true,
          false,
          sp.result,
        );
        break;
      default:
        break;
    }

    switch (true) {
      case isVerificationHeatmap:
      case isGenSurv:
      case isFlexHeatmap:
        const { x, y } = sp;
        drawRegularSP(ctx, x, y, color, displayValue, drawParams);
        break;
      case isHealthcareHeatmap:
        drawHealthcareSP(ctx, sp, color, drawParams);
        break;
      default:
        break;
    }

    if ('infiltration' in sp) {
      drawInfiltrationData(
        ctx,
        sp,
        getSamplePointColorByValueUsingGradientReader(
          sp.reduction,
          gradientReader,
          'reductionLog',
          true,
          false,
          sp.infiltration,
        ),
        drawParams,
      );
    }

    drawLabelSP(ctx, sp, drawParams);
  }

  const base64DataUrl = canvas.toDataURL('image/png');

  return {
    url: base64DataUrl,
    width: canvas.width,
    height: canvas.height,
  };
};

export {
  PRESENTATION_DEFAULT_SIZE,
  PRESENTATION_DEFAULT_LEFT_MARGIN,
  VERIFICATION_MERGED_TABLE_HEADER,
  VERIFICATION_MERGED_TABLE_ROWS_COLORS,
  createPresentation,
  createTemplateSlideForPresentation,
  createTableForVerificationHeatmapSummary,
  createSignificanceRectangleImage,
  addHeatmapOverviewSlideForSingleHeatmap,
  drawImageWithHeatmapDataOnCanvas,
};

// --- Helper functions for heatmap elements ---

const drawOriginPoint = (ctx, x, y, displayValue, color, drawParams) => {
  const { showLabel, size } = drawParams;

  const labelHeight = 16 * (size / 35);
  const labelWidth = 45 * (size / 35);
  const labelPositionX = x - 22 * (size / 35);
  const labelPositionY = y + 2 * (size / 35);

  const radius = 10;

  ctx.fillStyle = color;
  ctx.strokeStyle = 'black';
  ctx.lineWidth = 1;
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, Math.PI * 2);
  ctx.fill();
  ctx.stroke();

  if (showLabel) {
    ctx.fillStyle = 'lightblue';
    ctx.globalAlpha = 0.85;
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    ctx.fillRect(labelPositionX, labelPositionY, labelWidth, labelHeight);
    ctx.strokeRect(labelPositionX, labelPositionY, labelWidth, labelHeight);
    ctx.globalAlpha = 1;

    ctx.fillStyle = 'black';
    ctx.font = `${16 * (0.7 * (size / 35))}px Arial`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(displayValue, x, y + 7 * (size / 35) * 1.58);
  }
};

const drawRegularSP = (ctx, x, y, color, displayValue, drawParams) => {
  const { size } = drawParams;
  const heightToWidthRatio = 0.5;

  const valueBlockWidth = size * 2;
  const valueBlockHeight = valueBlockWidth * heightToWidthRatio;

  const innerColor = 'rgb(255, 255, 255)';

  drawRectangle(
    ctx,
    x - valueBlockWidth / 2,
    y - valueBlockHeight / 2,
    valueBlockWidth,
    valueBlockHeight,
    color,
    0.8,
    0.1 * size,
  );

  drawRectangle(
    ctx,
    x - (0.8 * valueBlockWidth) / 2,
    y - (0.8 * valueBlockHeight) / 2,
    0.8 * valueBlockWidth,
    0.8 * valueBlockHeight,
    innerColor,
    0.8,
    1,
    true,
  );

  drawText(ctx, x, y + 2, displayValue, 16 * (size / 35) * 0.8);
};

const drawHealthcareSP = (ctx, sp, color, drawParams) => {
  const { x, y } = sp;
  const { size } = drawParams;
  const innerColor = 'rgb(255, 255, 255)';

  const healthcareDisplayValues = getHealthCareSpDisplayValues(
    sp.reduction,
    sp.result && sp.result.displayValue,
    true,
    true,
    'reductionLog',
  );

  const heightToWidthRatio = healthcareDisplayValues.length === 2 ? 0.6 : 0.8;
  const valueBlockWidth = size * 2;
  const valueBlockHeight = valueBlockWidth * heightToWidthRatio;

  drawRectangle(
    ctx,
    x - valueBlockWidth / 2,
    y - valueBlockHeight / 2,
    valueBlockWidth,
    valueBlockHeight,
    color,
    0.8,
    0.1 * size,
  );

  drawRectangle(
    ctx,
    x - (0.8 * valueBlockWidth) / 2,
    y - (0.8 * valueBlockHeight) / 2,
    0.8 * valueBlockWidth,
    0.8 * valueBlockHeight,
    innerColor,
    0.8,
    1,
    true,
  );

  drawText(
    ctx,
    x,
    y +
      healthcareTextMarginConfigByRowsAmount[
        healthcareDisplayValues.length
      ][0] *
        (size / 35),
    healthcareDisplayValues[0] || '',
    16 * (size / 35) * 0.9,
  );
  drawText(
    ctx,
    x,
    y +
      healthcareTextMarginConfigByRowsAmount[
        healthcareDisplayValues.length
      ][1] *
        (size / 35),
    healthcareDisplayValues[1] || '',
    16 * (size / 35) * 0.6,
  );
  drawText(
    ctx,
    x,
    y +
      healthcareTextMarginConfigByRowsAmount[
        healthcareDisplayValues.length
      ][2] *
        (size / 35),
    healthcareDisplayValues[2] || '',
    16 * (size / 35) * 0.6,
  );
};

const drawLabelSP = (ctx, sp, drawParams, heightToWidthRatio = 1) => {
  const { size, showLabel } = drawParams;
  const { x, y } = sp;

  if (!showLabel) return;

  const spText = 'spText' in sp && sp.spText !== null;
  const labelTop = 'labelTop' in sp ? sp.labelTop : false;

  let labelMargin = labelTop ? -85 * (size / 35) : 0;
  if (heightToWidthRatio === 1) {
    const direction = labelTop ? 1 : -1;
    labelMargin = labelMargin + direction * (size / 35) * 5;
  } else {
    labelMargin = labelMargin - 10 * (size / 35) * heightToWidthRatio;
  }

  if (!spText) {
    drawRectangle(
      ctx,
      x - 20 * (size / 35),
      y + (14 * (size / 35) * 2 + labelMargin),
      42 * (size / 35),
      16 * (size / 35),
      'lightblue',
      0.85,
      0,
      true,
    );
    drawText(
      ctx,
      x + size / 35,
      y + (22 * (size / 35) * 1.66 + labelMargin),
      sp.spnumber,
      16 * (0.7 * (size / 35)),
    );
  }
  if (spText) {
    drawRectangle(
      ctx,
      x - 35 * (size / 35),
      y + (17 * (size / 35) * 2 + labelMargin),
      72 * (size / 35),
      25 * (size / 35),
      'lightblue',
      0.85,
      0,
      true,
    );

    drawText(
      ctx,
      x,
      y + (26 * 1.66 * (size / 35) + labelMargin),
      sp.spnumber,
      16 * (0.7 * (size / 35)),
    );
    drawText(
      ctx,
      x,
      y + (32 * 1.66 * (size / 35) + labelMargin),
      `${sp.spText} in WC`,
      16 * (0.7 * (size / 35)),
    );
  }
};

const drawInfiltrationData = (ctx, sp, color, drawParams) => {
  const { x, y } = sp;
  const { size } = drawParams;

  const healthcareInfiltrationValues = getHealthCareSpDisplayValues(
    sp.reduction,
    sp.infiltration && sp.infiltration.displayValue,
    true,
    true,
    'reductionLog',
  );

  if (!healthcareInfiltrationValues.length) return;

  drawRectangle(
    ctx,
    x + 30 * (size / 35),
    y - 23 * ((2 * size) / 35),
    85 * (size / 35),
    30 * (size / 35),
    color,
    0.85,
    0,
    true,
  );
  drawText(
    ctx,
    x + 73 * (size / 35),
    y - 21 * (size / 35) * 1.66,
    healthcareInfiltrationValues[0] || '',
    16 * (0.7 * (size / 35)),
  );
  drawText(
    ctx,
    x + 73 * (size / 35),
    y - 14 * (size / 35) * 1.66,
    healthcareInfiltrationValues[1] || '',
    16 * (0.7 * (size / 35)),
  );
};

// --- Helper functions for simple forms ---

const drawRectangle = (
  ctx,
  x,
  y,
  width,
  height,
  color,
  opacity = 1,
  r = 0,
  stroke = false,
) => {
  ctx.fillStyle = color;
  ctx.globalAlpha = opacity;
  ctx.beginPath();
  ctx.moveTo(x + r, y);
  ctx.arcTo(x + width, y, x + width, y + height, r);
  ctx.arcTo(x + width, y + height, x, y + height, r);
  ctx.arcTo(x, y + height, x, y, r);
  ctx.arcTo(x, y, x + width, y, r);
  if (stroke) {
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    ctx.stroke();
  }
  ctx.closePath();
  ctx.fill();

  ctx.globalAlpha = 1;
};

const drawText = (ctx, x, y, text = '', font = 16) => {
  ctx.fillStyle = 'black';
  ctx.font = `${font}px Arial`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(text, x, y);
};
