import {
  ERROR_MESSAGE_DEFAULT,
  HEATMAP_VERIFICATION_LIKE_BEHAVIOR,
  LS_COMPANIES,
  LS_COMPARE_HEATMAPS,
  LS_PARTNER_SET_COMPANY_ID,
  LS_PARTNER_SET_COMPANY_NAME,
  LS_SAFETRACE_SET_COMPANY_ID,
  LS_SAFETRACE_SET_COMPANY_NAME,
  PROJECT_STATUSES,
  PROJECT_STATUS_WORKFLOW,
  REQ_ERROR_SHOULD_BE_HIDDEN_INDICATOR,
  SERVER_ERROR_MESSAGE_DEFAULT,
  TEST_TYPES,
  colorsFilterValues,
} from 'Constants';
import axios from 'axios';
import React from 'react';

import floorPlansApi from '../Api/floorPlans';

import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Typography from '@mui/material/Typography';

import { cropImageFromUrlPromisified } from 'Components/Floorplans/utils';

import { isPartnerUser, isSafetracesUser } from 'Config/roles';

import { LOCAL_STORAGE_USER_KEY } from 'Context/Classes/User';

const isSelectedMenu = (path, to) => {
  if (path === '/' && to === '/') {
    return true;
  }

  return !!(path !== '/' && to !== '/' && path.includes(to));
};

const isValidResponse = (response) => {
  return (
    response &&
    response.status === 200 &&
    response.data !== null &&
    response.data.success === true &&
    response.data.data !== undefined
  );
};

const isValidStatusResponse = (response) => {
  return response && response.status === 200 && response.data.success === true;
};

const isStatusExistEntity = (response) => {
  return response && response.status === 208;
};

const getErrorMessageFromResponse = (response) => {
  if (!response) {
    return SERVER_ERROR_MESSAGE_DEFAULT;
  }

  return response?.data?.error?.message || ERROR_MESSAGE_DEFAULT;
};

const shouldShowErrorFromResponse = (response) =>
  response !== REQ_ERROR_SHOULD_BE_HIDDEN_INDICATOR;

const getSafetraceSetCompanyId = () => {
  return localStorage.getItem(LS_SAFETRACE_SET_COMPANY_ID) ?? 0;
};

const setSafetraceCompanyId = (companyId) => {
  localStorage.setItem(LS_SAFETRACE_SET_COMPANY_ID, companyId);
};

const setPartnerViewCompanyId = (companyId) => {
  localStorage.setItem(LS_PARTNER_SET_COMPANY_ID, companyId);
};

const getSafetraceSetCompanyName = () => {
  return localStorage.getItem(LS_SAFETRACE_SET_COMPANY_NAME) ?? '';
};

const setSafetraceCompanyName = (companyName) => {
  localStorage.setItem(LS_SAFETRACE_SET_COMPANY_NAME, companyName);
};

const setPartnerViewCompanyName = (companyName) => {
  localStorage.setItem(LS_PARTNER_SET_COMPANY_NAME, companyName);
};

const isTrainingCompanySelected = () => {
  const companyNameAllowedCancelAny =
    typeof process.env.REACT_APP_TRAINING_COMPANY_NAME === 'string'
      ? process.env.REACT_APP_TRAINING_COMPANY_NAME.replace(
          /%20/g,
          ` `,
        ).toLowerCase()
      : null;
  const lsCompanyName = getSafetraceSetCompanyName().toLowerCase();
  return lsCompanyName === companyNameAllowedCancelAny;
};

const getCompanyId = () => {
  if (isSafetracesUser()) {
    return getLSViewCompanyId();
  }

  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
  return user.companyId;
};

const setHeatmapCompare = (heatmapId) => {
  let obj = JSON.parse(localStorage.getItem(LS_COMPARE_HEATMAPS));
  const companyId = getCompanyId();
  if (obj && companyId in obj) {
    const companyObj = obj[companyId];
    const heatmaps = companyObj.heatmaps;
    if (heatmaps.indexOf(heatmapId) === -1) {
      heatmaps.push(heatmapId);
    }
  } else {
    obj = {};
    obj[companyId] = {
      heatmaps: [heatmapId],
    };
  }

  localStorage.setItem(LS_COMPARE_HEATMAPS, JSON.stringify(obj));
};

const checkExistHeatmapCompare = (heatmapId) => {
  const obj = JSON.parse(localStorage.getItem(LS_COMPARE_HEATMAPS));
  if (!obj) {
    return false;
  }

  const companyId = getCompanyId();
  if (obj && companyId in obj) {
    const companyObj = obj[companyId];
    if (companyObj.heatmaps) {
      return companyObj.heatmaps.indexOf(heatmapId) !== -1;
    }
  }

  return false;
};

const removeHeatmapCompare = (heatmapId) => {
  const obj = JSON.parse(localStorage.getItem(LS_COMPARE_HEATMAPS));
  if (!obj) {
    return;
  }

  const companyId = getCompanyId();
  if (obj && companyId in obj) {
    const companyObj = obj[companyId];
    if (companyObj) {
      const heatmaps = companyObj.heatmaps;
      const index = heatmaps.indexOf(heatmapId);
      if (index > -1) {
        heatmaps.splice(index, 1);
        localStorage.setItem(LS_COMPARE_HEATMAPS, JSON.stringify(obj));
      }
    }
  }
};

const getHeatmapCompare = () => {
  const obj = JSON.parse(localStorage.getItem(LS_COMPARE_HEATMAPS));
  if (!obj) {
    return [];
  }

  const companyId = getCompanyId();
  return obj[companyId] && obj[companyId].heatmaps
    ? obj[companyId].heatmaps
    : [];
};

const filterItem = (item, search) => {
  if (!search) {
    return true;
  }
  let searchString = '';
  Object.keys(item).forEach((value) => {
    searchString +=
      ' ' + (typeof item[value] === 'string' ? item[value].toLowerCase() : '');
  });
  return searchString.indexOf(search.toLowerCase()) !== -1;
};

const heatmapCalculateResult = (resultData) => {
  if (resultData.calculatedresult) {
    return resultData.calculatedresult;
  }
  if (~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(resultData.testtype)) {
    const heatmapData = resultData.heatmaps;
    if (!Array.isArray(heatmapData) || !heatmapData[0]) return '';

    const result = {};
    let testPassed = true;
    let testValid = true;

    const spArr = heatmapData[0].sp;
    if (Array.isArray(spArr)) {
      result.spDetails = spArr.reduce((res, sp) => {
        res[sp.spnumber] = sp.result || {};
        if (!sp.result) {
          testPassed = false;
          testValid = false;
        } else {
          const { pass, isValid } = sp.result;
          if (!pass) {
            testPassed = false;
          }
          if (!isValid) {
            testValid = false;
          }
        }
        return res;
      }, {});
    }

    result.testPassed = testPassed;
    result.testValid = testValid;

    return JSON.stringify(result);
  }
  const achievedList = [];
  let listLength = 0;
  for (let heatmap of resultData.heatmaps) {
    if (heatmap.sp) {
      listLength += heatmap.sp.length;
      heatmap.sp.forEach((item) => {
        if (Number(item.reduction) >= 3) {
          achievedList.push(item.reduction);
        }
      });
    }
  }

  const percent = ((achievedList.length / listLength) * 100).toFixed(1);
  const achieved = achievedList.length;
  return (
    percent +
    ' % of (' +
    achieved +
    ' out of ' +
    listLength +
    ') sample points achieved a 99.9% reduction'
  );
};

const heatmapDownloadFloorPlan = async (filename, projectId) => {
  const newAxiosInstance = axios.create();
  // Step 1: get pre-signed url by sending axios request to server
  const result = await floorPlansApi.getPresignedURL({
    projectId: projectId,
    filename,
    action: 'getObject',
    heatmapVersion: 1,
  });

  // Step 2: Download file from AWS s3 bucket as a blob
  // Making straight axios call in order to access the ProgressEvent interface which allows
  // up to capture download progress and pass that data along to spinner component
  const response = await newAxiosInstance.get(
    result.data.preSignedURL,
    {
      responseType: 'blob',
    },
    null,
  );

  //  Step 3: Download file in required format - create a blob url and render in img tag
  let blob = new Blob([response.data], { type: response.data.type });
  let createObjectURL =
    window.URL.createObjectURL || window.webkitURL.createObjectURL;
  const fullImageURL = createObjectURL(blob);

  // Step 4: Check if the image needs to be cropped (if it's a test area)
  if (result.data && result.data?.floorPlanMeta?.cropData) {
    return await cropImageFromUrlPromisified({
      url: fullImageURL,
      cropData: result.data.floorPlanMeta.cropData,
    });
  } else {
    return fullImageURL;
  }
};

const getSignificanceColor = (significance) => {
  if (significance) {
    switch (significance) {
      case colorsFilterValues.HIGH:
      case colorsFilterValues.PASS:
        return 'red';
      case colorsFilterValues.MEDIUM:
        return 'yellow';
      case colorsFilterValues.LOW:
      case colorsFilterValues.FAILURE:
        return 'green';
      default:
        return 'grey';
    }
  }
};

const heatmapIntervals = (heatMap) => {
  const intervals = [];
  if (heatMap && typeof heatMap.intervalcount !== 'undefined') {
    for (let i = 0; i < heatMap.intervalcount; i++) {
      intervals.push(
        i * heatMap.intervalduration +
          ' - ' +
          (i + 1) * heatMap.intervalduration +
          ' min',
      );
    }
  }

  return intervals;
};

const getUserData = (field = null) => {
  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
  if (field && userData[field]) {
    return userData[field];
  }

  return userData;
};

const changeCommentString = (s) => {
  return s.match(/.{1,70}(\s|$)/g);
};

const xlsxStringToNumber = (s) => {
  if (s) {
    return parseInt(s.replace(/,/g, ''));
  }
  return 0;
};

const testValue = (v) => {
  return v || 0;
};

const getNewMapValueOnScaleChange = (
  { x, y },
  newScale,
  previousScale,
  ref = null,
) => {
  if (!ref.current) return null;

  const scaleRatio = newScale / (previousScale || 1);
  const rect = ref.current.getBoundingClientRect();

  const focalPt = {
    x: rect.width / 2 - x,
    y: rect.height / 2 - y,
  };
  const focalPtDelta = {
    x: scaleRatio * focalPt.x - focalPt.x,
    y: scaleRatio * focalPt.y - focalPt.y,
  };

  return {
    scale: newScale,
    translation: {
      x: x - focalPtDelta.x,
      y: y - focalPtDelta.y,
    },
  };
};

const setCompanies = (companies) => {
  localStorage.setItem(LS_COMPANIES, JSON.stringify(companies));
};

const getCompanies = () => {
  return localStorage.getItem(LS_COMPANIES)
    ? JSON.parse(localStorage.getItem(LS_COMPANIES))
    : [];
};

const getLSViewCompanyId = () => {
  let viewCompanyLSKey;

  if (isSafetracesUser()) {
    viewCompanyLSKey = LS_SAFETRACE_SET_COMPANY_ID;
  } else if (isPartnerUser()) {
    viewCompanyLSKey = LS_PARTNER_SET_COMPANY_ID;
  } else {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY));
    return (user && parseInt(user.companyId, 10)) || 0;
  }

  if (viewCompanyLSKey) {
    return localStorage.getItem(viewCompanyLSKey)
      ? parseInt(JSON.parse(localStorage.getItem(viewCompanyLSKey)), 10)
      : null;
  }
  return null;
};

/**
 * Analyzes colors, returns an array of applicable colors and/or test types.
 *
 * Used in the pass/invalid/failure/high/medium/low filter
 *
 *
 * @param colors
 * @returns {{testTypes: null | unknown[], significances: unknown[]}}
 */
const getTypesAndSignificances = (colors) => {
  let applicableTypes = null;
  const validColors = new Set();
  colors.forEach((c) => {
    if (['pass', 'low'].includes(c)) {
      validColors.add('low');
    }
    if (['failure', 'high'].includes(c)) {
      validColors.add('high');
    }
    if (['invalid', 'medium'].includes(c)) {
      validColors.add('medium');
    }
  });
  // Use colors to determine which types to include:
  const includePassFail = colors.some((c) =>
    // 'pass', 'failure' and 'invalid' are applicable to verification, small & large dilution tests
    ['pass', 'failure', 'invalid'].includes(c),
  );
  const includeOtherTypes = colors.some((c) =>
    ['low', 'medium', 'high'].includes(c),
  );
  if (!colors.some((c) => ['pass', 'failure', 'invalid'].includes(c))) {
    applicableTypes = Object.values(TEST_TYPES).filter(
      (t) => !~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(t),
    );
  }
  if (includeOtherTypes && !includePassFail) {
    applicableTypes = Object.values(TEST_TYPES).filter(
      (t) => !~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(t),
    );
  } else if (includePassFail && !includeOtherTypes) {
    applicableTypes = HEATMAP_VERIFICATION_LIKE_BEHAVIOR;
  }

  return {
    testTypes: applicableTypes,
    significances: Array.from(validColors),
  };
};

const getCalcResultLabelFromVerLikeTest = (item, short = false) => {
  let calculatedResult;
  const passLabel = short ? 'Pass' : 'Test passed.';
  const failLabel = short ? 'Fail' : 'Test failed';
  const invalidLabel = short ? 'Invalid' : 'Test is invalid';

  try {
    const resultData = JSON.parse(item.calculatedresult);
    if (resultData && Object.keys(resultData)) {
      const { testValid, testPassed } = resultData;
      if (testValid) {
        calculatedResult = testPassed ? passLabel : failLabel;
      } else {
        calculatedResult = invalidLabel;
      }
    }
    return calculatedResult;
  } catch (err) {
    return '';
  }
};

const getDataFromResponse = (response) => {
  const data = response && response.data && response.data.data;
  return data || null;
};

const formDefaultColorsFilteredObj = (colorToSet = null) => {
  const result = {};
  for (let testType of Object.values(TEST_TYPES)) {
    if (!result[testType]) {
      result[testType] = {
        doExist: true,
        atLeastOneChecked: true,
        colors: {},
      };
    }
    result[testType].colors = Object.values(colorsFilterValues).reduce(
      (colorsObj, color) => {
        colorsObj[color] = colorToSet ? colorToSet === color : true;
        return colorsObj;
      },
      {},
    );
  }

  return result;
};

const formSignificanceParamsFromColorsObj = (colors) =>
  Object.keys(colors).reduce((res, testType) => {
    const testTypeColors = colors[testType].colors;
    const significancesTestType = Object.keys(testTypeColors).reduce(
      (significances, color) => {
        if (testTypeColors[color]) {
          significances.push(color);
        }
        return significances;
      },
      [],
    );

    let significance = significancesTestType.length
      ? significancesTestType
      : false;
    if (
      significance &&
      Object.keys(testTypeColors).length === significance.length
    ) {
      significance = true;
    }

    res += `&${testType}=${significance}`;

    return res;
  }, '');

const copyColorsFilterObj = (colorsObj) => {
  const newColorsObj = { ...colorsObj };
  for (let testType of Object.values(TEST_TYPES)) {
    if (colorsObj[testType].colors) {
      newColorsObj[testType].colors = { ...colorsObj[testType].colors };
    }
  }

  return newColorsObj;
};

const handleProjectStatusDecision = (action, previousStatus, params = {}) => {
  const actions = ['resultsUpload', 'resultsDelete'];

  if (
    !~actions.indexOf(action) ||
    !~PROJECT_STATUS_WORKFLOW.indexOf(previousStatus)
  ) {
    return [false, previousStatus];
  }

  let shouldUpdate = false;
  let newStatus = previousStatus;

  switch (action) {
    case 'resultsUpload':
      if (
        PROJECT_STATUS_WORKFLOW.indexOf(previousStatus) <
        PROJECT_STATUS_WORKFLOW.indexOf(PROJECT_STATUSES.RESULTS_GENERATED)
      ) {
        shouldUpdate = true;
        newStatus = PROJECT_STATUSES.RESULTS_GENERATED;
      }
      break;
    case 'resultsDelete':
      const isTheLastFile = params.isLastFile;
      if (
        isTheLastFile &&
        previousStatus === PROJECT_STATUSES.RESULTS_GENERATED
      ) {
        shouldUpdate = true;
        newStatus = PROJECT_STATUSES.READY_FOR_LAB;
      }
      break;
    default:
      break;
  }

  return [shouldUpdate, newStatus];
};

const getLabelsDimensions = (
  paperWidth,
  paperHeight,
  paperPadding,
  labelsSpacing,
  labelsAmount,
  labelW,
  labelH,
) => {
  const paperW = paperWidth - paperPadding.left - paperPadding.right;
  const paperH = paperHeight - paperPadding.top - paperPadding.bottom;

  if (!labelsAmount || paperW < labelW || paperH < labelH) return [];

  const columnsPerPaper = Math.floor(
    (paperW + labelsSpacing.horizontal) / (labelW + labelsSpacing.horizontal),
  );
  const rowsPerPaper = Math.floor(
    (paperH + labelsSpacing.vertical) / (labelH + labelsSpacing.vertical),
  );

  const dimensions = [];

  for (let labelNum = 1; labelNum <= labelsAmount; labelNum++) {
    const position = {
      top: 0,
      left: 0,
    };

    const labelRow = Math.ceil(labelNum / columnsPerPaper);
    const labelColumn = labelNum - (labelRow - 1) * columnsPerPaper;

    const pageNum = Math.ceil(labelRow / rowsPerPaper);
    const labelRowOnPage =
      labelRow - (Math.ceil(labelRow / rowsPerPaper) - 1) * rowsPerPaper;

    const top =
      (pageNum - 1) * paperHeight +
      (labelRowOnPage - 1) * (labelH + labelsSpacing.vertical);
    const left = (labelColumn - 1) * (labelW + labelsSpacing.horizontal);

    position.top = top;
    position.left = left;

    dimensions[labelNum - 1] = position;
  }

  return dimensions;
};

const getLabelsInnerContainerConfig = (
  paperWidth,
  paperPadding,
  labelWidth,
  labelsSpacing,
) => {
  const labelMargin = labelsSpacing.horizontal;
  const paperW = paperWidth - paperPadding.left - paperPadding.right;

  const columnsPerPaper = Math.floor(
    (paperW + labelMargin) / (labelWidth + labelMargin),
  );

  const realRowW =
    columnsPerPaper * labelWidth + (columnsPerPaper - 1) * labelMargin;
  const leftInnerContainerMargin = (paperWidth - realRowW) / 2;

  return {
    width: realRowW,
    left: leftInnerContainerMargin,
    top: paperPadding.top,
  };
};

/**
 * Wraps value in additional quotes so any commas might be present in value
 * will not affect CSV structure.
 * @param {string} value
 */
const csvCommasEscape = (value) => {
  if (value && typeof value === 'string') {
    value = value.replace(/"/g, '""');
  }

  return '"' + value + '"';
};

const renderTestInfoForHeatmap = (heatmap) => {
  if (!heatmap || !heatmap?.testmetadata) {
    return (
      <>
        <Typography color="text.secondary" variant="subtitle1" sx={{ mb: 1 }}>
          <Skeleton />
        </Typography>
        <Typography>
          <Skeleton />
        </Typography>
        <Typography>
          <Skeleton />
        </Typography>
        <Typography>
          <Skeleton />
        </Typography>
        <Typography>
          <Skeleton />
        </Typography>
        <Typography>
          <Skeleton />
        </Typography>
      </>
    );
  }
  const testNumber =
    heatmap?.displayNumbers?.[heatmap.testid] ??
    heatmap?.testordernumber ??
    'n/a';
  const testMeta = heatmap?.testmetadata?.[heatmap.segmentid.toString()];
  const scenario = heatmap?.scenario;

  const scenarioData = testMeta?.scenariosData?.[scenario?.scenarioname];

  const scenarioText = scenario
    ? `"${scenario?.scenariodisplayname}" (#${scenario?.scenarioindex})`
    : 'n/a';

  if (heatmap.testtype === TEST_TYPES.UV) {
    return (
      <>
        <Typography>
          Area: <strong>{heatmap.buildingdata.sq_ft ?? '---'}</strong>
        </Typography>
        <Typography>
          Ceiling Ht:{' '}
          <strong>{heatmap.buildingdata.celling_height ?? '---'}</strong>
        </Typography>
        <Typography>UV Wavelength Irradiance @ 1m</Typography>
        <TableContainer sx={{ mb: 1 }} component={Paper}>
          <Table aria-label="heatmap scenarios table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>eACH</TableCell>
                <TableCell>Pass/Fail Requirement</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>UV Off (eACH) </TableCell>
                <TableCell>{testMeta?.scenariosData?.uvOff.HVAC_ACH}</TableCell>
                <TableCell>
                  {testMeta?.scenariosData?.uvOff.EACH_PASS_FAIL}
                </TableCell>
              </TableRow>
              <TableRow
                key={'heatmap-data-table'}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>UV On (eACH)</TableCell>
                <TableCell>{testMeta?.scenariosData?.uvOn.HVAC_ACH}</TableCell>
                <TableCell>
                  {testMeta?.scenariosData?.uvOn.EACH_PASS_FAIL}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <Typography color="text.secondary" variant="subtitle1" sx={{ mb: 1 }}>
        Test #{testNumber}, Scenario: {scenarioText}
      </Typography>
      <Typography>
        Sample type:{' '}
        <strong>
          {heatmap?.sampletype ?? heatmap.collectionmethod ?? '---'}
        </strong>
      </Typography>
      {scenarioData && (
        <>
          <Typography>
            HVAC ACH: <strong>{scenarioData.HVAC_ACH ?? '---'}</strong>
          </Typography>
          <Typography>
            PEC ACH: <strong>{scenarioData.PEC_ACH ?? '---'}</strong>
          </Typography>
          <Typography>
            ACH pass/fail: <strong>{scenarioData.achPassFail ?? '---'}</strong>
          </Typography>
          <Typography>
            Leakage pass/fail:{' '}
            <strong>{scenarioData.leakagePassFail ?? '---'}</strong>
          </Typography>
        </>
      )}
    </>
  );
};

export {
  /** API HANDLERS */
  isValidResponse,
  isValidStatusResponse,
  getErrorMessageFromResponse,
  getDataFromResponse,
  shouldShowErrorFromResponse,
  isStatusExistEntity,
  /** USER & PERMISSIONS RELATED */
  getSafetraceSetCompanyId,
  setSafetraceCompanyId,
  setPartnerViewCompanyId,
  getUserData,
  isTrainingCompanySelected,
  /** LOCAL STORAGE HANDLERS */
  getSafetraceSetCompanyName,
  setSafetraceCompanyName,
  setPartnerViewCompanyName,
  setCompanies,
  getCompanies,
  getLSViewCompanyId,
  setHeatmapCompare,
  checkExistHeatmapCompare,
  removeHeatmapCompare,
  getHeatmapCompare,
  /** SIGNIFICANCE RELATED */
  formDefaultColorsFilteredObj,
  formSignificanceParamsFromColorsObj,
  copyColorsFilterObj,
  getTypesAndSignificances,
  getSignificanceColor,
  /** OTHER */
  isSelectedMenu,
  filterItem,
  heatmapCalculateResult,
  heatmapDownloadFloorPlan,
  heatmapIntervals,
  changeCommentString,
  xlsxStringToNumber,
  testValue,
  getNewMapValueOnScaleChange,
  getCalcResultLabelFromVerLikeTest,
  handleProjectStatusDecision,
  getLabelsDimensions,
  getLabelsInnerContainerConfig,
  csvCommasEscape,
  renderTestInfoForHeatmap,
};
