import {
  addHeatmapOverviewSlideForSingleHeatmap,
  createPresentation,
  drawImageWithHeatmapDataOnCanvas,
} from 'Utils/presentationUtils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import floorplansApi from 'Api/floorPlans';
import resultsApi from 'Api/results';
import segmentsApi from 'Api/segments';

import BreadcrumbsTest from 'Components/Breadcrumbs/BreadcrumbsTests';
import FilterSearchColors from 'Components/Mix/FilterSearchColors';
import ProjectsListDnD from 'Components/ResultTests/ProjectsListDnD';
import ErrorMessage from 'Components/UI/ErrorMessage';

import { colorsSelector, setColors } from 'Features/Colors/colorsSlice';
import {
  inProgressSelector,
  setInProgress,
} from 'Features/Filter/inProgressSlice';
import { fetchBuildings } from 'Features/Results/resultsSlice';
import {
  fetchBuildingTest,
  setNotError,
  testsSelector,
} from 'Features/Tests/testsSlice';

import {
  filterItem,
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

export default function ResultTests() {
  const { buildingId } = useParams();
  const [search, setSearch] = useState('');
  const { colors } = useSelector(colorsSelector);
  const { inProgress } = useSelector(inProgressSelector);
  const { tests, isLoading, error } = useSelector(testsSelector);
  const [downloadingHeatmapsProjectId, setDownloadingHeatmapsProjectId] =
    useState(null);
  const [initialLoading, setInitialLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBuildings(colors, buildingId, false, inProgress));
    dispatch(fetchBuildingTest(buildingId, colors, null, false, inProgress));
  }, [dispatch, buildingId]);

  useEffect(() => {
    dispatch(fetchBuildings(colors, buildingId, true, inProgress));
    dispatch(fetchBuildingTest(buildingId, colors, null, true, inProgress));
  }, []);

  useEffect(() => {
    if (!isLoading && initialLoading) {
      setInitialLoading(false);
    }
  }, [isLoading]);

  const handleFilterChange = useCallback(
    (colorsObj) => {
      dispatch(setColors(colorsObj));
      dispatch(fetchBuildings(colorsObj, buildingId, true, inProgress));
      dispatch(
        fetchBuildingTest(buildingId, colorsObj, null, true, inProgress),
      );
    },
    [inProgress, buildingId],
  );

  const handleInProgressSwitch = useCallback(
    (inProgressValue) => {
      dispatch(setInProgress(inProgressValue));
      dispatch(fetchBuildings(colors, buildingId, true, inProgressValue));
      dispatch(
        fetchBuildingTest(buildingId, colors, null, true, inProgressValue),
      );
    },
    [colors, buildingId],
  );

  const handleCloseErrorAlert = () => {
    dispatch(setNotError());
  };

  const testsListsByProject = useMemo(() => {
    if (!tests) return {};

    let projectInd = 1;

    return tests
      .filter((item) => filterItem(item, search))
      .reduce((acc, test) => {
        if (!acc[test.projectid]) {
          acc[test.projectid] = {
            name: test.projectname,
            id: test.projectid,
            hasResultsSnapshot: !!test.has_results_snapshot,
            tests: [],
            projectOrderNum: projectInd,
          };

          projectInd++;
        }
        acc[test.projectid].tests.push(test);

        return acc;
      }, {});
  }, [tests, search]);

  const updateTestsList = () => {
    dispatch(fetchBuildingTest(buildingId, colors, null, true, inProgress));
  };

  const handleProjectHeatmapsDownload = async (projectId) => {
    try {
      setDownloadingHeatmapsProjectId(projectId);

      const heatmapsResponse = await resultsApi.getHeatMapListForProject(
        projectId,
        null,
        false,
      );
      if (!isValidResponse(heatmapsResponse)) {
        throw new Error(getErrorMessageFromResponse(heatmapsResponse));
      }
      const heatmaps = getDataFromResponse(heatmapsResponse);

      const floorplansByHeatmapData = {};
      const uniqueFloorplans = new Set();

      for (let heatmap of heatmaps) {
        const {
          floorplanid,
          filename,
          mainfloorplanid,
          mainfpname,
          heatmapid,
          metadata,
        } = heatmap;

        const floorplanId = mainfloorplanid || floorplanid;

        floorplansByHeatmapData[heatmapid] = {
          id: floorplanId,
          name: mainfpname || filename,
          metadata,
          url: '',
        };
        if (floorplanId) {
          uniqueFloorplans.add(floorplanId);
        }
      }

      const loadFloorplan = async (floorPlanId) => {
        const fpResp = await floorplansApi.getPresignedURL({
          projectId,
          floorPlanId,
          heatmapVersion: 1,
        });
        if (!fpResp.data.preSignedURL) return null;

        const floorplanImg = await new Promise((resolve) => {
          const img = new Image();
          img.src = fpResp.data.preSignedURL;
          img.crossOrigin = 'anonymous';
          img.onload = () => resolve(img);
        });

        return {
          url: fpResp.data.preSignedURL,
          img: floorplanImg,
          id: floorPlanId,
        };
      };

      const [
        heatmapsDetailedResponse,
        segmentsData,
        ...loadedFloorplansDataArr
      ] = await Promise.all([
        resultsApi.getHeatMapListDetailed(
          heatmaps.map(({ heatmapid }) => heatmapid),
        ),
        segmentsApi.getSegments(null, projectId),
        ...Array.from(uniqueFloorplans).map((fpId) => loadFloorplan(fpId)),
      ]);

      if (
        !isValidResponse(heatmapsDetailedResponse) ||
        !isValidResponse(segmentsData) ||
        !loadedFloorplansDataArr.reduce((acc, fpData) => acc && !!fpData, true)
      ) {
        throw new Error();
      }
      const floorplansDataObj = loadedFloorplansDataArr.reduce(
        (acc, { url, id, img }) => {
          acc[id] = {
            url,
            img,
          };
          return acc;
        },
        {},
      );

      const testsDatesObj = getDataFromResponse(segmentsData).reduce(
        (acc, { testid, surveydate }) => {
          acc[testid] = acc[testid] || surveydate;
          return acc;
        },
        {},
      );

      const heatmapsDetailed = getDataFromResponse(heatmapsDetailedResponse);
      for (let heatmapId of Object.keys(heatmapsDetailed)) {
        const { id } = floorplansByHeatmapData[heatmapId];

        heatmapsDetailed[heatmapId].surveyDate =
          testsDatesObj[heatmapsDetailed[heatmapId].testid] || '';
        heatmapsDetailed[heatmapId].floorplanData =
          floorplansByHeatmapData[heatmapId];
        if (id) {
          heatmapsDetailed[heatmapId].floorplanData.url =
            floorplansDataObj[id].url;
        }
      }

      const heatmapsDetailedSortedArr = Object.values(heatmapsDetailed).sort(
        (a, b) => a.testid - b.testid,
      );

      const pres = createPresentation();

      for (let heatmapData of heatmapsDetailedSortedArr) {
        heatmapData.projectId = projectId;
        heatmapData.heatmapComment = heatmapData.heatmapcomments;

        let imageObj = null;

        if (heatmapData.floorplanData.id) {
          const canvasImg = drawImageWithHeatmapDataOnCanvas(
            floorplansDataObj[heatmapData.floorplanData.id].img,
            heatmapData,
          );

          imageObj = {
            data: canvasImg.url,
            size: {
              width: canvasImg.width,
              height: canvasImg.height,
            },
          };
        }

        addHeatmapOverviewSlideForSingleHeatmap(pres, heatmapData, imageObj);
      }

      let fileName = `PID_${projectId}_heatmaps.pptx`;
      pres.writeFile({ fileName });
    } catch (error) {
      console.error('Error downloading heatmaps', error);
    }

    setDownloadingHeatmapsProjectId(null);
  };

  return (
    <>
      {!!error && (
        <ErrorMessage
          message={error}
          handleCloseErrorAlert={handleCloseErrorAlert}
        />
      )}
      <div style={{ width: '100%' }}>
        <BreadcrumbsTest buildingId={buildingId} />
        <FilterSearchColors
          filterColors={colors}
          inProgress={inProgress}
          handleSearch={(value) => setSearch(value)}
          searchFieldLabel={'Search for Tests'}
          handleFilterChange={handleFilterChange}
          handleInProgressSwitch={handleInProgressSwitch}
          disabled={isLoading || initialLoading}
        />
        <ProjectsListDnD
          buildingId={+buildingId}
          projects={testsListsByProject}
          isLoading={isLoading || initialLoading}
          showSkeletonList={initialLoading}
          afterUpdate={updateTestsList}
          handleProjectHeatmapsDownload={handleProjectHeatmapsDownload}
          downloadingHeatmapsProjectId={downloadingHeatmapsProjectId}
        />
      </div>
    </>
  );
}
