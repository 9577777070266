import { ERROR_MESSAGE_DEFAULT, PROJECT_STATUSES } from 'Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Stack } from '@mui/material';

import projects from 'Api/projects';

import {
  AccorditionWrapper,
  Breadcrumbs,
  Floorplans,
  Labels,
  OrderForm,
  PageTitle,
  ProjectConfiguration,
  Results,
  SamplePlan,
} from 'Components/SingleProject/ProjectDownloads';
import ErrorMessage from 'Components/UI/ErrorMessage';

import { isPartnerEditorWithLabAccess, isSafetracesUser } from 'Config/roles';

import { useIsMounted } from 'Context';

import { getDataFromResponse, isValidResponse } from 'Utils';

export default function ProjectDownloads() {
  const { projectId } = useParams();

  const mounted = useIsMounted();

  const [projectConfig, setProjectConfig] = useState(null);

  const [isLoading, setIsLoading] = useState(null);
  const [mainError, setMainError] = useState('');

  useEffect(() => {
    fetchProject();
  }, []);

  const fetchProject = async () => {
    setIsLoading(true);
    try {
      const statusUpdateResponse = await projects.autoUpdateStatus(projectId);
      if (!isValidResponse(statusUpdateResponse)) {
        throw new Error('Failed to update project status');
      }
      const response = await projects.getProjectResoucesInfo(projectId);
      if (isValidResponse(response)) {
        if (mounted.current) {
          setProjectConfig(getDataFromResponse(response));
        }
      }
    } catch (e) {
      if (mounted.current) {
        setProjectConfig(null);
      }
      console.error(e.toString());
    } finally {
      if (mounted.current) {
        setIsLoading(false);
      }
    }
  };

  const handleError = useCallback((error) => {
    let message = error;
    if (typeof error !== 'string') {
      message = error?.message;
    }
    setMainError(message || ERROR_MESSAGE_DEFAULT);
  }, []);

  const refetchProjectConfig = useCallback(async () => {
    try {
      const statusUpdateResponse = await projects.autoUpdateStatus(projectId);
      if (!isValidResponse(statusUpdateResponse)) {
        throw new Error('Failed to update project status');
      }
      const response = await projects.getProjectResoucesInfo(projectId);
      if (isValidResponse(response)) {
        if (mounted.current) {
          const newConfig = getDataFromResponse(response);
          setProjectConfig(newConfig);
        }
      }
    } catch (e) {
      if (mounted.current) {
        setProjectConfig(null);
      }
      console.error(e.toString());
    }
  }, [projectId]);

  return (
    <React.Fragment>
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={() => setMainError(null)}
        />
      )}
      <div style={{ width: '100%' }}>
        <Breadcrumbs
          loading={!projectConfig?.id}
          name={projectConfig?.name}
          id={projectConfig?.id}
        />
        <Stack direction="column">
          <PageTitle
            loading={!projectConfig?.id}
            name={projectConfig?.name}
            id={projectConfig?.id}
          />
          <div style={{ marginTop: '16px' }}>
            <AccorditionWrapper
              title="Project Configuration"
              id="project-configuration"
            >
              {!!projectConfig?.id && (
                <ProjectConfiguration
                  loading={isLoading}
                  projectId={projectConfig.id}
                  projectName={projectConfig.name}
                  doExist={projectConfig.hasTest}
                  hasHealthcareTest={!!projectConfig.hasHealthcareTest}
                  hasLegacyTest={!!projectConfig.hasLegacyTest}
                  hasVerLikeTest={!!projectConfig.hasVerLikeTest}
                  onError={handleError}
                />
              )}
            </AccorditionWrapper>
            <AccorditionWrapper title="Order Form" id="order-form">
              <OrderForm
                loading={isLoading}
                projectId={projectConfig?.id}
                projectName={projectConfig?.name}
                onError={handleError}
              />
            </AccorditionWrapper>
            <AccorditionWrapper
              title="Labels"
              id="labels"
              disabled={
                !projectConfig?.status ||
                [PROJECT_STATUSES.NEW].includes(projectConfig?.status)
              }
            >
              <Labels
                loading={isLoading}
                projectId={projectConfig?.id}
                projectName={projectConfig?.name}
                hasSamples={projectConfig?.hasSamples}
                hasOP={projectConfig?.hasOP}
                hasSP={projectConfig?.hasSP}
                onError={handleError}
              />
            </AccorditionWrapper>
            <AccorditionWrapper
              title="Plate Layout"
              id="sample-plan"
              disabled={
                !projectConfig?.status ||
                [
                  PROJECT_STATUSES.NEW,
                  PROJECT_STATUSES.IN_PROGRESS,
                  PROJECT_STATUSES.EXECUTION_READY,
                ].includes(projectConfig?.status)
              }
            >
              <SamplePlan
                loading={isLoading || typeof isLoading !== 'boolean'}
                projectId={projectConfig?.id}
                projectName={projectConfig?.name}
                shouldExist={
                  projectConfig?.hasSamples &&
                  projectConfig?.hasAtLeastOneOpTagDefined
                }
                uvExist={projectConfig?.hasUVSamplePlan}
                nonUvExist={projectConfig?.hasNotUVSamplePlan}
                onError={handleError}
                refetchProjectConfig={fetchProject}
              />
            </AccorditionWrapper>
            {isSafetracesUser() || isPartnerEditorWithLabAccess() ? (
              <AccorditionWrapper
                title="Results"
                id="results"
                disabled={
                  !projectConfig?.status ||
                  [
                    PROJECT_STATUSES.NEW,
                    PROJECT_STATUSES.IN_PROGRESS,
                    PROJECT_STATUSES.EXECUTION_READY,
                  ].includes(projectConfig?.status)
                }
              >
                <Results
                  loading={isLoading || typeof isLoading !== 'boolean'}
                  projectId={projectConfig?.id}
                  projectName={projectConfig?.name}
                  projectStatus={projectConfig?.status}
                  projectCreationDate={projectConfig?.createdate}
                  hasFiles={projectConfig?.hasResultsFile}
                  hasResults={projectConfig?.hasResultsRecords}
                  hasHealthcareResults={projectConfig?.hasHealthcareResults}
                  hasVerlikeResults={projectConfig?.hasVerlikeResults}
                  hasGeneralResults={projectConfig?.hasGeneralResults}
                  hasUVResults={projectConfig?.hasUVResults}
                  onError={handleError}
                  refetchProject={refetchProjectConfig}
                />
              </AccorditionWrapper>
            ) : null}
            <AccorditionWrapper
              title="Floorplans"
              id="floorplans"
              disabled={
                typeof projectConfig?.hasFloorplan === 'boolean' &&
                !projectConfig.hasFloorplan
              }
            >
              <Floorplans
                loading={isLoading}
                projectId={projectConfig?.id}
                projectStatus={projectConfig?.status}
                doExist={projectConfig?.hasFloorplan}
                onError={handleError}
                refetchProject={refetchProjectConfig}
              />
            </AccorditionWrapper>
            {/* {isSafetracesUser() ? (
              <AccorditionWrapper
                title="Heatmaps Results"
                id="heatmaps_results"
                disabled={
                  !projectConfig?.status ||
                  [
                    PROJECT_STATUSES.NEW,
                    PROJECT_STATUSES.IN_PROGRESS,
                    PROJECT_STATUSES.EXECUTION_READY,
                  ].includes(projectConfig?.status) ||
                  !projectConfig?.hasVerResults
                }
              >
                <HeatmapResults
                  loading={isLoading || typeof isLoading !== 'boolean'}
                  projectId={projectConfig?.id}
                  projectName={projectConfig?.name}
                  onError={handleError}
                />
              </AccorditionWrapper>
            ) : null} */}
          </div>
        </Stack>
      </div>
    </React.Fragment>
  );
}
