import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const darkCell = {
  background: 'rgb(0, 137, 150)',
  color: 'white',
  padding: '5px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 'smaller',
};

const lightCell = {
  background: 'white',
  borderRight: '1px solid gray',
  borderBottom: '1px solid gray',
  color: 'black',
  padding: '5px',
  textAlign: 'center',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 'smaller',
};

const ProjectTagLogNumbers = ({ projectId, projectData }) => {
  const tagLotNumbers = React.useMemo(() => {
    if (projectData?.builder) {
      return {
        'Tag-A': projectData?.builder.getTagLotNumberGroup('Tag-A', false),
        'Tag-B': projectData?.builder.getTagLotNumberGroup('Tag-B', false),
        'Tag-C': projectData?.builder.getTagLotNumberGroup('Tag-C', false),
        'Tag-B CET': projectData?.builder.getTagLotNumberGroup('Tag-B', true),
        'Tag-C CET': projectData?.builder.getTagLotNumberGroup('Tag-C', true),
        'Tag-R': projectData?.builder.getTagLotNumberGroup('Tag-R', false),
        'Tag-S': projectData?.builder.getTagLotNumberGroup('Tag-S', false),
      };
    }

    return [];
  }, [projectData?.builder]);

  return (
    <div style={{ pageBreakAfter: 'always', pageBreakBefore: 'always' }}>
      <Box
        id="ProjectTagLotNumbers"
        display="flex"
        justifyContent="space-between"
        pt={5}
      >
        <Box>
          <Typography
            variant="h5"
            color="textPrimary"
            align="left"
            style={{ fontFamily: 'Gotham', fontSize: '1.2rem' }}
          >
            PID: {projectId} , {projectData.projectName}
          </Typography>
        </Box>
      </Box>
      <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
        <Typography variant="h6">Project Tag Lot Numbers</Typography>
      </div>
      {!!tagLotNumbers && (
        <table
          style={{
            border: '1px solid gray',
            borderRight: 'none',
            borderBottom: 'none',
            width: '80%',
            borderSpacing: 0,
          }}
        >
          <thead>
            <tr>
              <th style={lightCell}>&nbsp;</th>
              <th style={darkCell} width="12.8%">
                Tag A
              </th>
              <th style={darkCell} width="12.8%">
                Tag B
              </th>
              <th style={darkCell} width="12.8%">
                Tag C
              </th>
              <th style={darkCell} width="12.8%">
                Tag B CET
              </th>
              <th style={darkCell} width="12.8%">
                Tag C CET
              </th>
              <th style={darkCell} width="12.8%">
                Tag R
              </th>
              <th style={darkCell} width="12.8%">
                Tag S
              </th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((tagNum) => (
              <tr key={'PC_lotNumbersNum' + tagNum}>
                <td style={lightCell}>{tagNum}</td>
                <td style={lightCell}>
                  {tagLotNumbers['Tag-A'][tagNum - 1].lotNumber ?? 'N/A'}
                </td>
                <td style={lightCell}>
                  {tagLotNumbers['Tag-B'][tagNum - 1].lotNumber ?? 'N/A'}
                </td>
                <td style={lightCell}>
                  {tagLotNumbers['Tag-C'][tagNum - 1].lotNumber ?? 'N/A'}
                </td>
                <td style={lightCell}>
                  {tagLotNumbers['Tag-B CET'][tagNum - 1].lotNumber ?? 'N/A'}
                </td>
                <td style={lightCell}>
                  {tagLotNumbers['Tag-C CET'][tagNum - 1].lotNumber ?? 'N/A'}
                </td>
                <td style={lightCell}>
                  {tagLotNumbers['Tag-R'][tagNum - 1].lotNumber ?? 'N/A'}
                </td>
                <td style={lightCell}>
                  {tagLotNumbers['Tag-S'][tagNum - 1].lotNumber ?? 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ProjectTagLogNumbers;
