import { HEALTHCARE_TEST_TYPES, TEST_TYPES } from 'Constants';
import React from 'react';

import ScenariosTableHealthcare from './ScenariosTableHealthcare';
import ScenariosTableUV from './ScenariosTableUV';
import SingleTestCommonScenariosTable from './SingleTestCommonScenariosTable';

export default function ScenariosTableSingleTest(props) {
  if (!props?.test?.testtype || !props?.test?.testid) {
    return null;
  }

  return (
    <React.Fragment key={`scenarios-${props?.test?.testid}`}>
      {Object.values(HEALTHCARE_TEST_TYPES).includes(props.test.testtype) ? (
        <ScenariosTableHealthcare {...props} />
      ) : null}
      {TEST_TYPES.UV === props.test.testtype ? (
        <ScenariosTableUV {...props} />
      ) : null}
      {TEST_TYPES.UV !== props.test.testtype &&
      !Object.values(HEALTHCARE_TEST_TYPES).includes(props.test.testtype) ? (
        <SingleTestCommonScenariosTable {...props} />
      ) : null}
    </React.Fragment>
  );
}
