import {
  HEALTHCARE_TEST_TYPES,
  TAGS_B_LIST,
  TAGS_LIST,
  TAGS_R_LIST,
  TAGS_S_LIST,
  TEST_TYPES,
} from 'Constants';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, FormControl, IconButton, MenuItem, Select } from '@mui/material';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

export default function TagsSelectorTableCell({
  test,
  snr,
  setDialogMeta,
  openTagResetDrawer,
  handleTagUpdate,
  opData,
}) {
  const { permissions, projectOriginPoints, isProjectTagsLoading } =
    useSelector(projectDataSelector);
  const { editPermission, canEditTests } = permissions;
  const [localDialogMeta, setLocalDialogMeta] = useState({});

  const enableSelect = async (test, scenario) => {
    const usedTags = [];

    const OPdata = projectOriginPoints.filter(
      (OP) => OP.testid === test.testid,
    );

    const OPids = OPdata.map((op) => op.opid);

    let healthcareOPCTagId;
    let healthcareOPBtagId;

    if (Object.values(HEALTHCARE_TEST_TYPES).includes(test.testtype)) {
      for (let OP of OPdata) {
        if (OP.opnumber === 'OP-001') {
          healthcareOPCTagId = OP.opid;
        }
        if (OP.opnumber === 'OP-002') {
          healthcareOPBtagId = OP.opid;
        }
      }
    }

    for (let scnr of test.scnrs) {
      if (scnr.tagsArr) {
        usedTags.push(...scnr.tagsArr);
      }
      if (!usedTags.includes(scnr.tags)) {
        usedTags.push(scnr.tags);
      }
    }
    const availableCTags = TAGS_LIST.filter(
      (tag) =>
        !usedTags.includes(tag) ||
        tag === scenario.tags ||
        scenario.tagsArr.includes(tag),
    );
    const availableBTags = TAGS_B_LIST.filter(
      (tag) =>
        !usedTags.includes(tag) ||
        tag === scenario.tags ||
        scenario.tagsArr.includes(tag),
    );

    const availableRTags = TAGS_R_LIST.filter(
      (tag) =>
        !usedTags.includes(tag) ||
        tag === scenario.tags ||
        scenario.tagsArr.includes(tag),
    );

    const availableSTags = TAGS_S_LIST.filter(
      (tag) =>
        !usedTags.includes(tag) ||
        tag === scenario.tags ||
        scenario.tagsArr.includes(tag),
    );

    setLocalDialogMeta({
      title: 'Edit scenario tag',
      type: 'edit',
      testType: test.testtype,
      OPids,
      scenarioId: scenario.scenarioid,
      cTagValue: scenario.tags || scenario.tagsArr[0],
      bTagValue: scenario.tagsArr[1] || '',
      opGroupA: scenario.tagsArr[0] || '',
      opGroupB: scenario.tagsArr[1] || '',
      availableCTags: TEST_TYPES.UV !== test.testtype ? availableCTags : null,
      availableBTags:
        HEALTHCARE_TEST_TYPES.POSITIVE === test.testtype
          ? availableBTags
          : null,
      availableRTags: TEST_TYPES.UV === test.testtype ? availableRTags : null,
      availableSTags: TEST_TYPES.UV === test.testtype ? availableSTags : null,
      healthcareOPCTagId,
      healthcareOPBtagId,
    });
  };

  useEffect(() => {
    if (typeof localDialogMeta.title === 'undefined') {
      enableSelect(test, snr).catch((err) => {
        console.log(err);
      });
    }
  }, [test]);

  if (!editPermission) {
    return (
      <span>
        {Object.values(HEALTHCARE_TEST_TYPES).includes(test.testtype) ||
        TEST_TYPES.UV === test.testtype
          ? snr.tagsArr.join(', ')
          : snr.tags}
      </span>
    );
  }

  return (
    <React.Fragment>
      <>
        {localDialogMeta.availableCTags &&
        localDialogMeta.scenarioId === snr.scenarioid ? (
          <FormControl variant="standard" sx={{ m: '0 3px 0 0', width: 75 }}>
            <Select
              label={localDialogMeta.availableBTags ? 'Tag C' : 'Tag'}
              sx={{ width: 75, fontSize: '0.9rem' }}
              value={localDialogMeta.cTagValue || ''}
              onChange={(event) => {
                const newDialogMeta = Object.assign({}, localDialogMeta);
                newDialogMeta.cTagValue = event.target.value;
                newDialogMeta.healthcareOPCTagId = opData.healthcareOPCTagId;

                setDialogMeta(newDialogMeta);
                handleTagUpdate(newDialogMeta);
              }}
            >
              {localDialogMeta.availableCTags.map((tag, index) => (
                <MenuItem key={tag + '-' + index} value={tag}>
                  {tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        {localDialogMeta.availableBTags &&
        localDialogMeta.scenarioId === snr.scenarioid ? (
          <FormControl variant="standard" sx={{ m: 0, width: 75 }}>
            <Select
              sx={{ width: 75, fontSize: '0.9rem', ml: 1 }}
              value={localDialogMeta.bTagValue || ''}
              onChange={(event) => {
                const newDialogMeta = Object.assign({}, localDialogMeta);
                newDialogMeta.bTagValue = event.target.value;
                newDialogMeta.healthcareOPBtagId = opData.healthcareOPBtagId;

                setLocalDialogMeta(newDialogMeta);
                handleTagUpdate(newDialogMeta);
              }}
            >
              {localDialogMeta.availableBTags.map((tag, index) => (
                <MenuItem key={`${tag}-${index}`} value={tag}>
                  {tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </>
      {localDialogMeta.availableRTags &&
      localDialogMeta.scenarioId === snr.scenarioid ? (
        <FormControl variant="standard" sx={{ m: 0, width: 75 }}>
          <Box sx={{ width: 75, fontSize: '0.9rem', whiteSpace: 'nowrap' }}>
            {`${localDialogMeta.opGroupA}, ${localDialogMeta.opGroupB}` || ''}
          </Box>
          {/* <Select
            sx={{ width: 75, fontSize: '0.9rem' }}
            value={localDialogMeta.opGroupA || ''}
            onChange={(event) => {
              const newDialogMeta = Object.assign({}, localDialogMeta);
              newDialogMeta.opGroupA = event.target.value;

              setLocalDialogMeta(newDialogMeta);
              handleTagUpdate(newDialogMeta);
            }}
          >
            {localDialogMeta.availableRTags.map((tag, index) => (
              <MenuItem key={`${tag}-${index}`} value={tag}>
                {tag}
              </MenuItem>
            ))}
          </Select> */}
        </FormControl>
      ) : null}
      {/* {localDialogMeta.availableSTags &&
      localDialogMeta.scenarioId === snr.scenarioid ? (
        <FormControl variant="standard" sx={{ m: 0, width: 75 }}>
          <Select
            sx={{ width: 75, fontSize: '0.9rem', ml: 1 }}
            value={localDialogMeta.opGroupB || ''}
            onChange={(event) => {
              const newDialogMeta = Object.assign({}, localDialogMeta);
              newDialogMeta.opGroupB = event.target.value;

              setLocalDialogMeta(newDialogMeta);
              handleTagUpdate(newDialogMeta);
            }}
          >
            {localDialogMeta.availableSTags.map((tag, index) => (
              <MenuItem key={`${tag}-${index}`} value={tag}>
                {tag}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null} */}

      {!localDialogMeta.availableRTags && !localDialogMeta.availableSTags ? (
        <IconButton
          onClick={() => {
            return openTagResetDrawer(test, snr);
          }}
          aria-label="reset scenario tag"
          size="small"
          disabled={isProjectTagsLoading || test.testexecuted || !canEditTests}
          color="error"
        >
          <DeleteOutlineIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </React.Fragment>
  );
}
