import {
  originpoints as originPointsApi,
  samplePoints as samplePointsApi,
  scenarios as scenariosApi,
  segments as segmentsApi,
} from 'Api';
import {
  HEALTHCARE_TEST_TYPES,
  TEST_TYPES,
  UNMOUNT_IGNORED_ERR_MSG,
} from 'Constants';
import axios from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  extractTagsInfoFromScenariosData,
  normaliseOriginPointsData,
  normaliseSamplesData,
  normaliseScenariosData,
} from './utils';

import samplePlans from 'Api/samplePlans';

import {
  DeleteScenarioModal,
  ResetTagModal,
  UVHealthcareScenarioSettingsModal,
} from 'Components/Scenarios/Modals';
import { useTestContext } from 'Components/SingleTest/context';
import ErrorMessage from 'Components/UI/ErrorMessage';

import { ACCESS_LEVEL_ADMIN, ACCESS_LEVEL_EDITOR } from 'Config/roles';

import { useIsMounted } from 'Context';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  getUserData,
  isValidResponse,
} from 'Utils';

const TestScenariosContext = createContext({});

export const TestScenariosContextProvider = ({ children, value }) => {
  const { accessLevel, roleName } = getUserData();
  const userPermissionsConfigIdentifier = accessLevel + roleName;
  const editPermission =
    !!~[ACCESS_LEVEL_ADMIN].indexOf(accessLevel) ||
    !!~[ACCESS_LEVEL_EDITOR].indexOf(accessLevel);

  const mounted = useIsMounted();

  const { reFetchProject } = useTestContext();

  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [addEditModalMeta, setAddEditModalMeta] = useState(null);
  const [errorMsg, setErrorMessage] = useState('');

  const [scenariosData, setScenariosData] = useState({});
  const [originPointsData, setOriginPointsData] = useState({});
  const [samplesData, setSamplesData] = useState({});

  const [initialLoad, setInitialLoad] = useState(true);

  const [isDataFetching, setIsDataFetching] = useState(false);
  const [isAddInProgress, setIsAddInProgress] = useState(false);
  const [isEditInProgress, setIsEditInProgress] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [isResetTagInProgress, setIsResetTagInProgress] = useState(false);

  const [scenarioForDeleteId, setScenarioForDeleteId] = useState(null);
  const [resetTagMeta, setResetTagMeta] = useState(null);

  const [tagsUsed, setTagsUsed] = useState([]);
  const [tagsUsedByScenario, setTagsUsedByScenario] = useState({});

  const [
    scenarioExecutionChangeInProgress,
    setScenarioExecutionChangeInProgress,
  ] = useState(false);
  // TODO: add check for projectContext and update tags for taglotnumbers on scenario update

  useEffect(() => {
    const source = axios.CancelToken.source();

    try {
      fetchData(source);
    } catch (err) {}

    return () => {
      source.cancel(UNMOUNT_IGNORED_ERR_MSG);
    };
  }, []);

  useEffect(() => {
    if (value.shouldUpdateScenariosData) {
      fetchData();
    }
  }, [value]);

  /**
   * 1. Gets data for OPs, scenarios, segments and sample points (fails if any of the requests fail)
   * 2. Normalises the data and puts it into state
   */
  const fetchData = async (source) => {
    try {
      const { testId, segmentId } = value;

      if (!testId || !segmentId) return;

      setIsDataFetching(true);

      const [opResponse, scenariosResp, segmentsResp, samplesResp] =
        await Promise.all([
          originPointsApi.getAllOriginPointsForTest(testId, source),
          scenariosApi.getScenarios(segmentId, source),
          segmentsApi.getSegments(testId, null, source),
          samplePointsApi.getAllSamples(testId, null, source),
        ]);

      if (
        !isValidResponse(opResponse) ||
        !isValidResponse(scenariosResp) ||
        !isValidResponse(segmentsResp) ||
        !isValidResponse(samplesResp)
      ) {
        throw new Error();
      }

      const originPoints = getDataFromResponse(opResponse);
      const scenarios = getDataFromResponse(scenariosResp);
      const segments = getDataFromResponse(segmentsResp);
      const samples = getDataFromResponse(samplesResp);

      const normalisedScenariosData = normaliseScenariosData(
        scenarios,
        originPoints,
        segments,
        samples,
      );

      const [_tagsUsed, _tagsUsedByScenarios] =
        extractTagsInfoFromScenariosData(normalisedScenariosData);

      const normalisedOriginPointsData =
        normaliseOriginPointsData(originPoints);

      const normalisedSamplesData = normaliseSamplesData(samples);

      setScenariosData(normalisedScenariosData);
      setOriginPointsData(normalisedOriginPointsData);
      setSamplesData(normalisedSamplesData);
      setTagsUsed(_tagsUsed);
      setTagsUsedByScenario(_tagsUsedByScenarios);
    } catch (e) {
      if (mounted.current && e.message !== UNMOUNT_IGNORED_ERR_MSG) {
        setErrorMessage(`Could not load scenarios data`);
      }
    } finally {
      if (mounted.current) {
        setIsDataFetching(false);
        if (initialLoad) {
          setInitialLoad(false);
        }
      }
    }
  };

  const startAddScenarioProcess = () => {
    // TODO: redo this to obtain data from static scenario with proper inheritance of characteristics
    const presumablyTheStaticScenario = Object.values(scenariosData)[0];
    if (!presumablyTheStaticScenario) return;

    const anyTemplateFormObj = Object.assign({}, presumablyTheStaticScenario);
    const keysForDataInheritance = [
      'HVAC_ACH',
      'PEC_ACH',
      'selectorKnobAchSetting',
      'testDurationSelectorNob',
      'achPassFail',
      'leakagePassFail',
      'infiltration',
      'deltaPsp002vsp001',
      'deltaPsp003vsp001',
      'deltaPsp004vsp001',
      'deltaPsp005vsp001',
    ];

    for (let key of Object.keys(anyTemplateFormObj)) {
      if (!keysForDataInheritance.includes(key)) {
        anyTemplateFormObj[key] = '';
      }
    }

    const newAddEditModalMeta = Object.assign(
      {},
      { type: '', focusField: '', handleSubmit: null },
      {
        type: 'create',
        data: anyTemplateFormObj, // prepare empty scenario data with inherited from static scenario data
        handleSubmit: handleAddScenario,
        handleAfterSubmit: handleAfterScenarioAdd,
        handleClose: () => {
          setAddEditModalMeta(null);
          setIsAddInProgress(false);
        },
      },
    );

    setAddEditModalMeta(newAddEditModalMeta);
    setIsAddEditModalOpen(true);
  };

  const startResetTagScenarioProcess = (scenarioId, opKey) => {
    setResetTagMeta({
      scenarioId,
      opKey,
      opId: originPointsData[opKey]?.id,
    });
  };

  const cancelResetTagScenarioProcess = () => {
    setResetTagMeta(null);
    setIsResetTagInProgress(false);
  };

  /**
   * Restricted edit is used for ST Admins to edit name & description ignoring test execution status & project workflow
   * @param {Number} scenarioId
   * @param {String} focusField
   * @param {'regular' | 'restricted'} editType
   * @returns
   */
  const startEditScenarioProcess = (
    scenarioId,
    focusField = '',
    editType = 'regular',
  ) => {
    const scenarioObj = scenariosData[scenarioId];
    if (!scenarioObj || !['regular', 'restricted'].includes(editType)) {
      return;
    }

    const newAddEditModalMeta = Object.assign(
      {},
      { type: '', focusField: '', handleSubmit: null },
      {
        type: editType === 'regular' ? 'edit' : 'restricted_edit',
        data: Object.assign({}, scenarioObj), // tags inside can mutate, not cool, fix it later
        focusField: focusField || '',
        handleSubmit: (values) => handleEditScenario(values, editType),
        handleAfterSubmit: handleAfterScenarioEdit,
        handleClose: () => {
          setAddEditModalMeta(null);
          setIsEditInProgress(false);
        },
      },
    );

    setAddEditModalMeta(newAddEditModalMeta);
    setIsAddEditModalOpen(true);
  };

  const handleAddScenario = async (values) => {
    setIsAddInProgress(true);
    try {
      await samplePlans.removeSamplePlan(Number(value.projectId));
      const res = await createOrUpdateScenarioAndUpdateOPs(values);
      if (!res) {
        return;
      }
      await segmentsApi.recreateSamples(Number(value.projectId));
      handleAfterScenarioAdd();
    } catch (err) {
      console.error(`Error while adding scenario: ${err.message}`);
    } finally {
      if (mounted.current) {
        setIsAddInProgress(false);
      }
    }
  };

  /**
   * @param {{}} values
   * @param {'create' | 'edit' | 'restricted_edit'} action
   * @returns
   */
  const createOrUpdateScenarioAndUpdateOPs = async (
    values,
    action = 'create',
  ) => {
    try {
      const testMetadata = Object.entries(values).reduce(
        (acc, [key, value]) => {
          if (!['description', 'op001Tag', 'op002Tag'].includes(key)) {
            acc[key] = value ?? '';
          }
          return acc;
        },
        {},
      );

      const bodyObj = {
        segmentId: value.segmentId,
        scenarioData: {
          testMetadata,
          scenario: {
            description: values.description ?? '',
          },
        },
      };

      if (['edit', 'restricted_edit'].includes(action)) {
        bodyObj.editType = action === 'edit' ? 'regular' : 'restricted';
      }

      let scenarioId = values.scenarioId;
      const response =
        action === 'create'
          ? await scenariosApi.createUVorHealthcareScenario(bodyObj)
          : await scenariosApi.updateScenarios(scenarioId, bodyObj);

      if (!isValidResponse(response)) {
        throw new Error(getErrorMessageFromResponse(response));
      }

      if (action === 'restricted_edit') {
        return true;
      }

      if (action === 'create') {
        scenarioId = getDataFromResponse(response).scenarioId;
      }

      if (values.op001Tag) {
        const opId = originPointsData['OP-001'].id ?? -1;
        const op1update = await scenariosApi.updateUVOrHealthcareScenarioTag(
          scenarioId,
          { opId, tag: values.op001Tag },
        );
        if (!isValidResponse(op1update)) {
          throw new Error(getErrorMessageFromResponse(op1update));
        }
      }
      if (
        HEALTHCARE_TEST_TYPES.POSITIVE === value.testType &&
        values.op002Tag
      ) {
        const opId = originPointsData['OP-002'].id ?? -1;
        const op2update = await scenariosApi.updateUVOrHealthcareScenarioTag(
          scenarioId,
          { opId, tag: values.op002Tag },
        );
        if (!isValidResponse(op2update)) {
          throw new Error(getErrorMessageFromResponse(op2update));
        }
      }

      if (values.opGroupA || values.opGroupB) {
        const opsUpdate = await scenariosApi.updateUVOrHealthcareScenarioTag(
          scenarioId,
          {
            testType: TEST_TYPES.UV,
            opGroupA: values.opGroupA,
            opGroupB: values.opGroupB,
          },
        );
        if (!isValidResponse(opsUpdate)) {
          throw new Error(getErrorMessageFromResponse(opsUpdate));
        }
      }

      return true;
    } catch (err) {
      console.log('createOrUpdateScenarioAndUpdateOPs Error: ', err);
      if (mounted.current) {
        setErrorMessage(err.message);
      }
      return false;
    }
  };

  const handleAfterScenarioAdd = () => {
    fetchData();
    setIsAddEditModalOpen(false);
    setAddEditModalMeta(null);
    // projects tags update
  };

  /**
   * Restricted edit is used for ST Admins to edit name & description ignoring test execution status & project workflow
   * @param {{}} values
   * @param {'regular' | 'restricted'} editType
   * @returns
   */
  const handleEditScenario = async (values, editType = 'regular') => {
    if (!['regular', 'restricted'].includes(editType)) {
      return;
    }

    setIsEditInProgress(true);
    try {
      const res = await createOrUpdateScenarioAndUpdateOPs(
        values,
        editType === 'regular' ? 'edit' : 'restricted_edit',
      );
      if (!res) {
        return;
      }

      handleAfterScenarioEdit();
    } catch (err) {
      console.error(`Error while editing scenario: ${err.message}`);
    } finally {
      if (mounted.current) {
        setIsEditInProgress(false);
      }
    }
  };

  const handleAfterScenarioEdit = () => {
    fetchData();
    setIsAddEditModalOpen(false);
    setAddEditModalMeta(null);
    if (typeof value.reFetchProject === 'function') {
      value.reFetchProject();
    }
    if (typeof value.refetchProjectTags === 'function') {
      value.refetchProjectTags();
    }
  };

  const startDeleteProcess = (scenarioId) => {
    setScenarioForDeleteId(scenarioId);
  };

  const handleDeleteScenario = async () => {
    setIsDeleteInProgress(true);

    try {
      const response = await scenariosApi.deleteHealthcareScenario(
        scenarioForDeleteId,
      );
      if (isValidResponse(response) && mounted.current) {
        await samplePlans.removeSamplePlan(Number(value.projectId));
        await segmentsApi.recreateSamples(Number(value.projectId));
        afterScenarioDelete();
        setScenarioForDeleteId(null);
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      if (mounted.current) {
        console.error(`Error while deleting scenario: ${err.message}`);
        setErrorMessage(err.message);
      }
    } finally {
      if (mounted.current) setIsDeleteInProgress(false);
    }
  };

  const handleTagReset = async () => {
    setIsResetTagInProgress(true);

    try {
      const response = await scenariosApi.updateUVOrHealthcareScenarioTag(
        resetTagMeta.scenarioId,
        { opId: resetTagMeta.opId, updateType: 'reset' },
      );

      if (isValidResponse(response) && mounted.current) {
        await samplePlans.removeSamplePlan(Number(value.projectId));
        fetchData();
        if (typeof value.reFetchProject === 'function') {
          value.reFetchProject();
        }
        if (typeof value.refetchProjectTags === 'function') {
          value.refetchProjectTags();
        }
        if (typeof reFetchProject === 'function') {
          reFetchProject();
        }
        setResetTagMeta(null);
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      if (mounted.current) {
        console.error(`Error while deleting scenario: ${err.message}`);
        setErrorMessage(err.message);
      }
    } finally {
      if (mounted.current) setIsResetTagInProgress(false);
    }
  };

  const afterScenarioDelete = () => {
    fetchData();
    if (typeof value.refetchProjectTags === 'function') {
      value.refetchProjectTags();
    }
    setScenarioForDeleteId(null);
  };

  const cancelScenarioDelete = () => {
    setScenarioForDeleteId(null);
    setIsDeleteInProgress(false);
  };

  const resetGlobalError = useCallback(() => {
    if (mounted.current) {
      setErrorMessage('');
    }
  }, []);

  const setScenarioExecuted = async (scenarioId, val) => {
    setScenarioExecutionChangeInProgress(true);
    try {
      const response = await scenariosApi.updateScenarios(scenarioId, {
        scenarioexecuted: val,
      });

      if (isValidResponse(response)) {
        fetchData();
        if (typeof value.reFetchProject === 'function') {
          value.reFetchProject();
        }
        if (typeof reFetchProject === 'function') {
          reFetchProject();
        }

        setScenarioExecutionChangeInProgress(false);
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      if (mounted.current) {
        setScenarioExecutionChangeInProgress(false);
        setErrorMessage(err.message);
        console.error(`Error while updating scenario: ${err.message}`);
      }
    }
  };

  return (
    <TestScenariosContext.Provider
      value={{
        projectId: Number(value.projectId),
        testId: Number(value.testId),
        addEditModalMeta,
        reFetchProject: value.reFetchProject,
        testExecuted: value.testExecuted,
        projectStatus: value.projectStatus,
        editPermission,
        initialLoad,
        scenariosData,
        userPermissionsConfigIdentifier,
        originPointsData,
        samplesData,
        startAddScenarioProcess,
        startEditScenarioProcess,
        handleDeleteScenario,
        startDeleteProcess,
        cancelScenarioDelete,
        startResetTagScenarioProcess,
        isAddEditModalOpen,
        scenarioForDeleteId,
        currentTestType: value.testType,
        tagsUsed,
        tagsUsedByScenario,
        isDeleteInProgress,
        isAddInProgress,
        isEditInProgress,
        isResetTagInProgress,
        isDataFetching,
        resetTagMeta,
        handleTagReset,
        cancelResetTagScenarioProcess,
        setScenarioExecuted,
        scenarioExecutionChangeInProgress,
        shouldUpdateScenariosData: value.shouldUpdateScenariosData,
      }}
    >
      <ErrorMessage
        message={errorMsg}
        handleCloseErrorAlert={resetGlobalError}
      />
      <UVHealthcareScenarioSettingsModal />
      <DeleteScenarioModal />
      <ResetTagModal />
      {children}
    </TestScenariosContext.Provider>
  );
};

export const useTestScenariosContext = () => {
  const {
    projectId,
    setIsAddEditModalOpen,
    addEditModalMeta,
    reFetchProject,
    testId,
    editPermission,
    initialLoad,
    isAddInProgress,
    isEditInProgress,
    scenariosData,
    userPermissionsConfigIdentifier,
    testExecuted,
    originPointsData,
    samplesData,
    startAddScenarioProcess,
    startEditScenarioProcess,
    startDeleteProcess,
    handleDeleteScenario,
    isAddEditModalOpen,
    isDeleteInProgress,
    isResetTagInProgress,
    cancelScenarioDelete,
    scenarioForDeleteId,
    tagsUsed,
    tagsUsedByScenario,
    currentTestType,
    startResetTagScenarioProcess,
    resetTagMeta,
    cancelResetTagScenarioProcess,
    handleTagReset,
    setScenarioExecuted,
    scenarioExecutionChangeInProgress,
    projectStatus,
    shouldUpdateScenariosData,
  } = useContext(TestScenariosContext);

  /**
   * @returns {string} - the message for a user why all edits are restricted
   */
  const getForceRestrictEditReason = () => {
    switch (true) {
      case testExecuted:
        return 'Test has been executed, no more changes permitted.';
      case isAddInProgress:
      case isEditInProgress:
      case initialLoad:
      case isDeleteInProgress:
      case isResetTagInProgress:
      case scenarioExecutionChangeInProgress:
      default:
        return '';
    }
  };

  const forceRestrictAllEdits =
    initialLoad ||
    testExecuted ||
    isAddInProgress ||
    isEditInProgress ||
    isDeleteInProgress ||
    scenarioExecutionChangeInProgress;

  const forceRestrictAllEditsReason = forceRestrictAllEdits
    ? getForceRestrictEditReason()
    : '';

  return {
    projectId,
    setIsAddEditModalOpen,
    addEditModalMeta,
    reFetchProject,
    testId,
    editPermission,
    initialLoad,
    scenariosData,
    userPermissionsConfigIdentifier,
    forceRestrictAllEdits,
    forceRestrictAllEditsReason,
    originPointsData,
    samplesData,
    startAddScenarioProcess,
    startEditScenarioProcess,
    startDeleteProcess,
    handleDeleteScenario,
    isAddEditModalOpen,
    cancelScenarioDelete,
    scenarioForDeleteId,
    tagsUsed,
    tagsUsedByScenario,
    currentTestType,
    startResetTagScenarioProcess,
    resetTagMeta,
    cancelResetTagScenarioProcess,
    handleTagReset,
    setScenarioExecuted,
    projectStatus,
    shouldUpdateScenariosData,
  };
};
