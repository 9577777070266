import {
  HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR,
  HEATMAP_VERIFICATION_LIKE_BEHAVIOR,
  LS_BREADCRUMBS,
  TEST_TYPES,
} from 'Constants';
import React, { useState } from 'react';

import HeatmapGradient from './components/HeatmapGradient';
import SingleOP from './components/SingleOP';
import SingleSP from './components/SingleSP';
import './index.css';

import { Box, Typography } from '@mui/material';

import { getSignificanceColor } from 'Utils';

export default function NewHeatmap({
  onImageLoaded,
  scale,
  sliderSp,
  heatMap,
  isDownload,
  interval,
  activeFloorPlanUrl,
  resultsData,
  showSpOpValue,
  calculatedResult,
  comment,
  significance,
  overwriteImage,
  showLegend = true,
  isMergedVersion = false,
}) {
  const {
    testtype,
    testid,
    testordernumber,
    sampletype,
    scenarioid,
    scenarioname,
    scenariodescription,
    scenarios,
    buildingdata,
    testmetadata,
    segmentid,
  } = resultsData;

  const [activeFPWidth, setActiveFPWidth] = useState(null);
  const [activeFPHeight, setActiveFPHeight] = useState(null);

  const scenarioIndex =
    scenarios?.find((item) => item.id === scenarioid)?.scenarioindex ?? 1;

  const getImageSize = (imageId) => {
    try {
      const img = document.querySelector(imageId);
      if (!img || !img.complete || typeof img.naturalHeight === 'undefined') {
        console.error('Image not fully loaded');
        return;
      }

      const { naturalHeight, naturalWidth } = img;

      setActiveFPHeight(naturalHeight);
      setActiveFPWidth(naturalWidth);
      onImageLoaded(naturalWidth, naturalHeight);
    } catch (e) {
      console.log('Failed to get image size', e);
    }
  };

  const SingleFPImage = React.useMemo(() => {
    if (!activeFloorPlanUrl) return;

    // Generate a random ID for the image to prevent overlapping during comparison of multiple images
    const imageId = `id-${Math.random().toString(36).substring(2, 9)}`;
    return (
      <img
        alt="HeatMap"
        src={overwriteImage ? overwriteImage : activeFloorPlanUrl}
        id={imageId}
        onLoad={() => {
          getImageSize(`#${imageId}`);
        }}
        style={{
          minWidth: isDownload && overwriteImage ? '1600px' : 'auto',
        }}
      />
    );
  }, [activeFloorPlanUrl, overwriteImage]);

  const SPrendered = React.useMemo(() => {
    if (!heatMap || !heatMap.sp || overwriteImage) return;

    const { sp } = heatMap;
    const isVerificationLike =
      !!~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(testtype) ||
      !!~HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR.indexOf(testtype);
    const isHealthCare = !!~HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR.indexOf(testtype);
    const isUV = testtype === TEST_TYPES.UV;

    return sp.map((SP, i) => (
      <SingleSP
        key={`SP-${SP.id ?? SP.spnumber}-${i}`}
        x={parseFloat(SP.spcoordx)}
        y={parseFloat(SP.spcoordy)}
        sp={SP}
        showLabel={isDownload || showSpOpValue}
        isVerificationLike={isVerificationLike}
        isHealthCare={isHealthCare}
        isUV={isUV}
        gradientType={
          testtype !== TEST_TYPES.GENERAL &&
          testtype !== TEST_TYPES.RECIRCULATION
            ? 'reductionLog'
            : 'copiesPerMillion'
        }
        scale={scale}
        spCircleValue={sliderSp || 35}
        testNumberLabel={isMergedVersion}
      />
    ));
  }, [isDownload, heatMap, scale, showSpOpValue, sliderSp, overwriteImage]);

  const OPrendered = React.useMemo(() => {
    if (!heatMap || !heatMap.op || overwriteImage) return;

    const { op } = heatMap;
    const fillColor =
      ~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(testtype) ||
      !!~HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR.indexOf(testtype)
        ? 'rgb(0, 0, 255)'
        : 'rgb(255, 0, 0)';

    return op.map((OP, i) => (
      <SingleOP
        key={`OP-${OP.id ?? OP.opnumber}-${i}`}
        color={fillColor}
        x={parseFloat(OP.opcoordx)}
        y={parseFloat(OP.opcoordy)}
        scale={scale}
        opnumber={OP.opnumber.split('::')[0]}
        showLabel={isDownload || showSpOpValue}
        spCircleValue={sliderSp || 35}
      />
    ));
  }, [isDownload, heatMap, showSpOpValue, scale, sliderSp, overwriteImage]);

  const downloadOnlyPart = React.useMemo(() => {
    if (!isDownload) {
      return (
        <Box sx={{ width: '1px', height: '1px', overflow: 'hidden' }}>
          <img src="/SafeTraces_Logo.png" alt="logo" />
        </Box>
      );
    }

    const breadcrumbsText = localStorage.getItem(LS_BREADCRUMBS);
    const commentText = comment && `Comment: ${comment}`;

    let testSampleScenarioText = `Test: ${testid}, Sample type: ${
      sampletype || 'n/a'
    }`;
    let calculatedResultText = calculatedResult;
    let significanceName = 'Significance:';

    if (
      ~HEATMAP_VERIFICATION_LIKE_BEHAVIOR.indexOf(testtype) ||
      ~HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR.indexOf(testtype)
    ) {
      calculatedResultText = '';
      significanceName = 'Result:';
    }
    if (scenarioid) {
      testSampleScenarioText += `, Scenario: ${scenarioid} ${
        scenarioname ? `| ${scenarioname}` : ''
      }`;
    }

    const significanceColor = getSignificanceColor(significance);

    const renderTestMetadata = () => {
      let passFailText = '';
      if (testmetadata && testmetadata[parseInt(segmentid, 10)]) {
        const { scenariosData } = testmetadata[segmentid];
        if (scenariosData && scenariosData[scenarioname]) {
          const { HVAC_ACH, PEC_ACH, achPassFail, leakagePassFail } =
            scenariosData[scenarioname];
          passFailText = `HVAC ACH: ${HVAC_ACH}, PEC ACH: ${PEC_ACH}, ACH Pass/Fail: ${achPassFail}, Leakage Pass/Fail: ${leakagePassFail}`;
        }
      }
      if (passFailText) {
        return <p>{passFailText}</p>;
      }
      return null;
    };

    return (
      <div id="heatmap-download-addition">
        <div id="logo">
          <img src="/SafeTraces_Logo.png" alt="logo" />
        </div>
        <div className="download-details-info">
          <p>{breadcrumbsText}</p>
          <p>{testSampleScenarioText}</p>
          <div className="significance-box">
            <p>{significanceName}</p>
            {significanceColor ? (
              <div
                className="significance-box--square"
                style={{ background: significanceColor }}
              />
            ) : (
              <p>n/a</p>
            )}
          </div>
          {!!buildingdata && (
            <p>
              Area: {buildingdata.sq_ft} sq.ft., height:{' '}
              {buildingdata.celling_height} ft.
            </p>
          )}
          {!!commentText && <p>{commentText}</p>}
          {!!calculatedResultText && <p>{calculatedResultText}</p>}
          {!!scenariodescription && (
            <p>Scenario description: {scenariodescription}</p>
          )}
          {renderTestMetadata()}
        </div>
      </div>
    );
  }, [isDownload]);

  return (
    <div className="heatmap-container-new">
      {downloadOnlyPart}
      {!!~[TEST_TYPES.DILUTION, TEST_TYPES.RECIRCULATION].indexOf(testtype) && (
        <p>Interval: {interval}</p>
      )}
      <Box id="floorplan-gradient-box">
        <Box
          className="img-overlay-wrap"
          style={{
            margin: `0px 0`, // prev. ${350 * scale} 0
          }}
        >
          {!!heatMap && SingleFPImage}
          {activeFPWidth && activeFPHeight && !overwriteImage && (
            <svg
              className="img-overlay-wrap--spOpContainer"
              style={{
                width: activeFPWidth + 'px',
                height: activeFPHeight + 'px',
              }}
            >
              {SPrendered}
              {OPrendered}
            </svg>
          )}
        </Box>
        {!~[...HEATMAP_VERIFICATION_LIKE_BEHAVIOR, TEST_TYPES.UV].indexOf(
          testtype,
        ) &&
          showLegend && (
            <HeatmapGradient
              healtCare={~HEATMAP_HEALTH_CARE_LIKE_BEHAVIOR.indexOf(testtype)}
              scale={scale}
              isNewHeatmap
              type={
                testtype !== TEST_TYPES.GENERAL &&
                testtype !== TEST_TYPES.RECIRCULATION
                  ? 'reductionLog'
                  : 'copiesPerMillion'
              }
            />
          )}
      </Box>
      {!!isDownload && (
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography>
            Test #{testordernumber ?? 1}, Scenario #{scenarioIndex}
          </Typography>
          <Typography textAlign="right">Confidential Information</Typography>
        </Box>
      )}
    </div>
  );
}
