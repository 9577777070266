import {
  HEALTHCARE_TEST_TYPES,
  TEST_BUILDING_DATA,
  TEST_SP_COUNTS,
  TEST_TYPES,
  TEST_TYPES_MAPPING,
} from 'Constants';

import originPointsApi from 'Api/originpoints';
import samplePointsApi from 'Api/samplePoints';
import scenariosApi from 'Api/scenarios';
import segmentsApi from 'Api/segments';
import testsApi from 'Api/tests';

import { renderTestsIcons } from 'Components/Portfolios/utils';

import { isValidResponse, isValidStatusResponse } from 'Utils';

export function renderIcons(
  testObject,
  flexDirection = 'row',
  badgeColor = 'primary',
  iconColor = '#118996',
) {
  const tests = normaliseTestCounts(testObject);
  return renderTestsIcons(
    tests,
    flexDirection,
    'flex-start',
    badgeColor,
    iconColor,
  );
}

export function sortSiteContracts(siteContracts, sortType) {
  switch (sortType) {
    case 'End date (ascending)':
      return siteContracts.sort((a, b) => {
        return new Date(a.enddate) - new Date(b.enddate);
      });
    case 'End date (descending)':
      return siteContracts.sort((a, b) => {
        return new Date(b.enddate) - new Date(a.enddate);
      });
    case 'Start date (ascending)':
      return siteContracts.sort((a, b) => {
        return new Date(a.startdate) - new Date(b.startdate);
      });
    case 'Start date (descending)':
      return siteContracts.sort((a, b) => {
        return new Date(b.startdate) - new Date(a.startdate);
      });
    default:
      return siteContracts;
  }
}

export function filterSiteContractsBySearchTerm(siteContracts, searchTerm) {
  if (searchTerm && typeof searchTerm === 'string') {
    // Escaping regex sensitive characters like ()
    const reg = new RegExp(escapeRegex(searchTerm), 'i');
    return siteContracts.filter((sc) => {
      return reg.test(sc.sitecontractname);
    });
  }
  return siteContracts;
}

export function filterSiteContractsByPartner(siteContracts, partnerId) {
  if (partnerId === -1) {
    return siteContracts;
  }
  if (partnerId) {
    return siteContracts.filter((sc) => {
      return Number.parseInt(sc.partner) === Number.parseInt(partnerId);
    });
  }
  return siteContracts;
}

export function escapeRegex(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function getPartnersFromLS() {
  return JSON.parse(localStorage.getItem('companies'))?.filter(
    (p) => p.type === 'Partner',
  );
}

export function sortAndFilterSiteContracts(
  siteContracts,
  sortType,
  partnerId,
  searchTerm,
) {
  return sortSiteContracts(
    filterSiteContractsBySearchTerm(
      filterSiteContractsByPartner(siteContracts, partnerId),
      searchTerm,
    ),
    sortType,
  );
}

export function getPartnerById(id) {
  return getPartnersFromLS().find(
    (c) => Number.parseInt(c.companyid) === Number.parseInt(id),
  );
}

/**
 * Normalises the names of the tests and returns them as a single object with predefined test names.
 * Also converts values to Numbers
 *
 * @param testsObject
 */
export function normaliseTestCounts(testsObject) {
  const temp = {
    largeSurvey:
      testsObject.largesurveytests ??
      testsObject.largeSurvey ??
      testsObject.largeSurveyTests ??
      0,
    smallSurvey:
      testsObject.smallsurveytests ??
      testsObject.smallSurvey ??
      testsObject.smallSurveyTests ??
      0,
    largeDilution:
      testsObject.largedilutiontests ??
      testsObject.largeDilution ??
      testsObject.largeDilutionTests ??
      0,
    smallDilution:
      testsObject.smalldilutiontests ??
      testsObject.smallDilution ??
      testsObject.smallDilutionTests ??
      0,
    miniSurvey:
      testsObject.minisurveytests ??
      testsObject.miniSurvey ??
      testsObject.miniSurveyTests ??
      0,
    recirculation:
      testsObject.recirculationtests ??
      testsObject.recirculation ??
      testsObject.recirculationTests ??
      0,
    verification:
      testsObject.verificationtests ??
      testsObject.verification ??
      testsObject.verificationTests ??
      0,
    [HEALTHCARE_TEST_TYPES.POSITIVE]:
      testsObject[HEALTHCARE_TEST_TYPES.POSITIVE] ?? 0,
    [HEALTHCARE_TEST_TYPES.NEUTRAL]:
      testsObject[HEALTHCARE_TEST_TYPES.NEUTRAL] ?? 0,
    [HEALTHCARE_TEST_TYPES.NEGATIVE]:
      testsObject[HEALTHCARE_TEST_TYPES.NEGATIVE] ?? 0,
    [TEST_TYPES.UV]: testsObject[TEST_TYPES.UV] ?? 0,
  };
  const final = {
    largeSurvey: Number.parseInt(temp.largeSurvey),
    smallSurvey: Number.parseInt(temp.smallSurvey),
    largeDilution: Number.parseInt(temp.largeDilution),
    smallDilution: Number.parseInt(temp.smallDilution),
    miniSurvey: Number.parseInt(temp.miniSurvey),
    recirculation: Number.parseInt(temp.recirculation),
    verification: Number.parseInt(temp.verification),
    [HEALTHCARE_TEST_TYPES.NEGATIVE]: Number.parseInt(
      temp[HEALTHCARE_TEST_TYPES.NEGATIVE],
      10,
    ),
    [HEALTHCARE_TEST_TYPES.POSITIVE]: Number.parseInt(
      temp[HEALTHCARE_TEST_TYPES.POSITIVE],
      10,
    ),
    [HEALTHCARE_TEST_TYPES.NEUTRAL]: Number.parseInt(
      temp[HEALTHCARE_TEST_TYPES.NEUTRAL],
      10,
    ),
    [TEST_TYPES.UV]: Number.parseInt(temp[TEST_TYPES.UV], 10),
  };
  return {
    ...final,
    total:
      final.recirculation +
      final.verification +
      final.miniSurvey +
      final.smallDilution +
      final.largeDilution +
      final.smallSurvey +
      final.largeSurvey +
      final[HEALTHCARE_TEST_TYPES.POSITIVE] +
      final[HEALTHCARE_TEST_TYPES.NEUTRAL] +
      final[HEALTHCARE_TEST_TYPES.NEGATIVE] +
      final[TEST_TYPES.UV],
  };
}

export function subtractTests(a, b) {
  const testsA = normaliseTestCounts(a);
  const testsB = normaliseTestCounts(b);
  const result = {
    ...testsA,
  };

  for (const testName in result) {
    result[testName] = result[testName] - testsB[testName];
  }

  return result;
}

export function addTests(a, b) {
  const testsA = normaliseTestCounts(a);
  const testsB = normaliseTestCounts(b);
  const result = {
    ...testsA,
  };

  for (const testName in result) {
    result[testName] = result[testName] + testsB[testName];
  }

  return result;
}

export function sumTests(testsArr) {
  let total = {
    total: 0,
    smallSurvey: 0,
    largeSurvey: 0,
    smallDilution: 0,
    largeDilution: 0,
    miniSurvey: 0,
    recirculation: 0,
    verification: 0,
    [HEALTHCARE_TEST_TYPES.POSITIVE]: 0,
    [HEALTHCARE_TEST_TYPES.NEGATIVE]: 0,
    [HEALTHCARE_TEST_TYPES.NEUTRAL]: 0,
    [TEST_TYPES.UV]: 0,
  };
  for (const item of testsArr) {
    total = addTests(total, item?.testsmetadata ?? item);
  }
  return total;
}

export async function createTests(
  testList,
  projectId,
  setMainError,
  postCreateAction,
  incrementCounts,
) {
  if (testList) {
    const spCollectionMethods = 'filter';
    const buildingId = +testList.buildingId;
    for (const [key, testTypes] of Object.entries(testList)) {
      if (Array.isArray(testTypes) && key !== 'buildingId') {
        for (let i = 0; i < testTypes.length; i++) {
          const name = testTypes[i];
          const isHealthcareTestGroup = Object.values(
            HEALTHCARE_TEST_TYPES,
          ).includes(key);

          if (!isHealthcareTestGroup && TEST_TYPES.UV !== key) {
            const responseTest = await testsApi.createTest(
              {
                name: name,
                goal: name,
                overview: name,
                type: TEST_TYPES_MAPPING[key],
                rawType: key,
                buildingId: buildingId,
                projectId: projectId,
                buildingdata: TEST_BUILDING_DATA[key] || {},
              },
              incrementCounts,
            );
            if (!isValidResponse(responseTest) || !responseTest.data.data) {
              setMainError('Error: Test create.');
              await postCreateAction();
              return false;
            }
            let testId = responseTest.data.data[0];
            if (typeof testId === 'object') {
              testId = testId?.testid;
            }
            const responseUpdateSegmentSamples =
              await segmentsApi.updateSamples({
                testId: testId,
                collectionMethod: spCollectionMethods,
              });
            if (!isValidStatusResponse(responseUpdateSegmentSamples)) {
              setMainError('Error: Segment Samples update');
              await postCreateAction();
              return false;
            }

            const sp = TEST_SP_COUNTS[key];
            const responseCreateSegment = await segmentsApi.createSegments({
              sp: sp,
              projectId: projectId,
              testId: testId,
              totalSegments: 1,
            });

            if (!isValidStatusResponse(responseCreateSegment)) {
              setMainError(`Error: Segment Create for test Id: ${testId}`);
              await postCreateAction();
              return false;
            }
            const {
              MIN_SURVEY,
              SMALL_DILUTION,
              LARGE_DILUTION,
              VERIFICATION,
              RECIRCULATION,
            } = TEST_TYPES;
            if (
              ~[
                MIN_SURVEY,
                SMALL_DILUTION,
                LARGE_DILUTION,
                VERIFICATION,
                RECIRCULATION,
              ].indexOf(TEST_TYPES_MAPPING[key])
            ) {
              const responseCreateScenarios =
                await scenariosApi.createScenarios({
                  testId: testId,
                  segmentId: responseCreateSegment.data.data.segmentid,
                  totalScenarios: 1,
                });
              if (!isValidStatusResponse(responseCreateScenarios)) {
                setMainError(`Error: Scenarios for test Id: ${testId}`);
                await postCreateAction();
                return false;
              }
            }

            const responseCreateOriginPoints =
              await originPointsApi.createOriginPoints({
                testId: testId,
              });
            if (!isValidStatusResponse(responseCreateOriginPoints)) {
              setMainError(`Error: Origin Points for test Id: ${testId}`);
              await postCreateAction();
              return false;
            }

            const responseCreateSamplePoints =
              await samplePointsApi.createSamplePoints({
                testId: testId,
                collectionMethod: spCollectionMethods,
              });
            if (!isValidStatusResponse(responseCreateSamplePoints)) {
              setMainError('Error: Create Sample Points');
              await postCreateAction();
              return false;
            }
          } else if (isHealthcareTestGroup) {
            const testResponse = await testsApi.createHealthcareTest({
              projectId,
              buildingId,
              type: key,
              name,
              goal: name,
              overview: name,
            });

            if (!isValidResponse(testResponse)) {
              setMainError('Error: Test create.');
              return false;
            }
          } else {
            const testResponse = await testsApi.createUVTest({
              projectId,
              buildingId,
              type: key,
              name,
              goal: name,
              overview: name,
            });

            if (!isValidResponse(testResponse)) {
              setMainError('Error: Test create.');
              return false;
            }
          }
        }
      }
    }
    return true;
  }
}
