import axios from 'axios';

import config from 'Config/config';

const BASEURL = config.back_url + '/sampleplans';

const samplePlans = {
  removeSamplePlan: async (projectId) => {
    return await axios.post(BASEURL + '/remove/' + projectId, {});
  },
  getSamplePlan: async (projectId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }

    return await axios.get(`${BASEURL}?projectId=${projectId}`, options);
  },
  createSamplePlan: async (projectId) => {
    return await axios.put(`${BASEURL}/build/${projectId}`, {});
  },
  getZipUrl: async (projectId) => {
    return await axios.get(`${BASEURL}/zip/${projectId}`);
  },
  getLabelsForProject: async (projectId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }

    return await axios.get(`${BASEURL}/labels?projectId=${projectId}`, options);
  },
};

export default samplePlans;
