import axios from 'axios';

import config from 'Config/config';

const BASEURL = config.back_url + '/projects';

const projects = {
  getAllProjects: async (config = {}) => {
    return await axios.get(BASEURL + '', config);
  },
  getProjectResoucesInfo: async (projectId) => {
    return await axios.get(`${BASEURL}/${projectId}/resourcesConfig`);
  },
  createProject: async (data) => {
    data.origin = 'customer_portal';
    return await axios.post(BASEURL + '/create', data);
  },
  getOneProject: async (
    projectId,
    includeTestsWithEmptyTags = false,
    source,
  ) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }

    if (includeTestsWithEmptyTags) {
      return await axios.get(
        `${BASEURL}/${projectId}?includeTestsWithEmptyTags=true`,
        options,
      );
    }
    return await axios.get(BASEURL + '/' + projectId, options);
  },
  getProjectOrderFormData: async (projectId) => {
    return await axios.get(`${BASEURL}/${projectId}/orderFormCustomerApp`);
  },
  updateOneProject: async (projectId, data) => {
    return await axios.put(BASEURL + '/' + projectId, data);
  },
  deleteOneProject: async (projectId) => {
    return await axios.delete(BASEURL + '/' + projectId);
  },
  removeProjectUpdateTestPackages: async (projectId) => {
    return await axios.delete(
      BASEURL + '/removeProjectUpdateTestPackages/' + projectId,
    );
  },
  /**
   * ST Admin users only.
   * Removes project with all its underlying instances, such as:
   * tests, results, segments, scenarios, SP & OP points and so on.
   */
  removeProjectDeep: async (projectId) => {
    return await axios.delete(
      `${config.back_url}/contracts/removeProjectDeep/${projectId}`,
    );
  },
  updateStatus: async (projectId, status) => {
    return await axios.put(`${BASEURL}/status/${projectId}`, { status });
  },
  autoUpdateStatus: async (projectId, status) => {
    return await axios.put(`${BASEURL}/autoUpdateStatus/${projectId}`, {
      status,
    });
  },
  getProjectConfigurationData: async (projectId, source) => {
    const options = {};
    if (source) {
      options.cancelToken = source.token;
    }
    return await axios.get(
      `${BASEURL}/${projectId}/getProjectConfigurationData`,
      options,
    );
  },
};

export default projects;
