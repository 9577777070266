import { TEST_TYPES } from 'Constants';
import React, { useRef } from 'react';

import HealthcareSettingsForm from '../Forms/HealthcareSettingsForm';
import UVSettingsForm from '../Forms/UVSettingsForm';
import { useTestScenariosContext } from '../context';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { isSafetracesAdmin } from 'Config/roles';

export default function UVHealthcareScenarioSettingsModal({
  transitionDuration = 220,
}) {
  const headerOptions = {
    create: `Add scenario`,
    edit: `Edit scenario`,
    restricted_edit: `Edit scenario`,
  };

  const okBtnText = {
    create: 'Create',
    edit: 'Save',
    restricted_edit: 'Save',
  };

  const scenarioFormRef = useRef(null);
  const testScenariosContext = useTestScenariosContext();

  if (!testScenariosContext) {
    console.error(`Component must be used inside TestScenariosContext`);
    return null;
  }

  const {
    isAddEditModalOpen,
    addEditModalMeta,
    forceRestrictAllEdits,
    currentTestType,
  } = testScenariosContext;
  if (!addEditModalMeta) {
    return null;
  }

  const {
    handleSubmit,
    type,
    handleClose,
    data: defaultValues,
    focusField,
  } = addEditModalMeta;

  if (!~['create', 'edit', 'restricted_edit'].indexOf(type)) {
    return null;
  }

  const disabledActions =
    !(isSafetracesAdmin() && type === 'restricted_edit') &&
    forceRestrictAllEdits;

  return (
    <Dialog
      open={isAddEditModalOpen}
      transitionDuration={transitionDuration}
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          p: 1,
          pb: 2,
          justifyContent: 'center',
          alignItems: 'center',
          width: '440px',
        },
      }}
    >
      <DialogTitle>{headerOptions[type] || ''}</DialogTitle>
      <DialogContent>
        {currentTestType === TEST_TYPES.UV ? (
          <UVSettingsForm
            ref={scenarioFormRef}
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            focusField={focusField}
            type={type === 'restricted_edit' ? 'restricted' : 'regular'}
          />
        ) : (
          <HealthcareSettingsForm
            ref={scenarioFormRef}
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            focusField={focusField}
            type={type === 'restricted_edit' ? 'restricted' : 'regular'}
          />
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between', width: '60%' }}>
        <Button
          style={{ width: '100%', marginRight: '8px' }}
          variant="outlined"
          onClick={handleClose}
          disabled={disabledActions}
        >
          Cancel
        </Button>
        <Button
          style={{ width: '100%' }}
          variant="contained"
          disabled={disabledActions}
          onClick={() => scenarioFormRef.current?.requestSubmit()}
          endIcon={
            disabledActions ? (
              <CircularProgress color="inherit" size={'1rem'} />
            ) : null
          }
        >
          {okBtnText[type]}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
