import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Save } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import projects from 'Api/projects';
import segments from 'Api/segments';

import { useIsMounted } from 'Context';

import { getErrorMessageFromResponse, isValidResponse } from 'Utils';

const EditSurveyDate = ({
  cancel,
  value,
  testId,
  reFetchProject,
  segment,
  type = 'test',
  projectId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [surveyDate, setSurveyDate] = useState(new Date());

  const mounted = useIsMounted();

  useEffect(() => {
    if (value) {
      setSurveyDate(parseISO(value));
    }
  }, [value]);

  const { handleSubmit } = useForm();

  const onSubmit = () => {
    const newDate = format(surveyDate, 'yyyy-MM-dd').toString();
    setIsLoading(true);
    setError(null);

    const update =
      type === 'test'
        ? () =>
            segments.updateTestPlan({
              testId,
              segmentId: segment.segmentid,
              surveyDate: newDate,
              segmentsTested: segment.segmentstested,
              comments: segment.comments,
            })
        : () =>
            projects.updateOneProject(projectId, {
              surveyDate: newDate,
              updateType: 'bulkSurveyDate',
            });

    update()
      .then((response) => {
        if (isValidResponse(response)) {
          reFetchProject();
          cancel();
        } else throw new Error(getErrorMessageFromResponse(response));
      })
      .catch((err) => {
        if (mounted.current) {
          console.log('Error updating survey date: ', err);
          setError(err.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderForm = () => {
    if (!isLoading) {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth>
              <MobileDatePicker
                label="Survey Date"
                value={surveyDate}
                name="surveyDate"
                inputFormat={'MMM do, yyyy'}
                onChange={(newValue) => setSurveyDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            <Stack direction="row" justifyContent="space-between" mt={2}>
              <Button variant="contained" startIcon={<Save />} type="submit">
                Save
              </Button>
              <ButtonGroup>
                <Button onClick={cancel}>Cancel</Button>
              </ButtonGroup>
            </Stack>
          </form>
        </LocalizationProvider>
      );
    }
    return null;
  };

  return (
    <Stack p={2} spacing={2}>
      {isLoading && <LinearProgress />}
      <Typography variant="h5">Edit Survey Date</Typography>
      <Box py={2}>{renderForm()}</Box>
      {!!error && (
        <Alert severity="error">
          {type === 'test' ? `Error when updating Survey Date` : error}
        </Alert>
      )}
    </Stack>
  );
};

export default EditSurveyDate;
