import { HEALTHCARE_TEST_TYPES, TEST_TYPES } from 'Constants';
import React, { useEffect } from 'react';

import {
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';

import { getACHRange, getTotalACH } from 'Components/Scenarios/utils';

import {
  StyledTableCell,
  StyledTableRow,
  StyledTestTableCell,
} from 'Layout/layoutStyles';

export default function ScenariosTable({
  testType,
  scenariosWithSPs,
  scenarios,
  scenarioDataWithOPs,
  scenariosDataFromTest,
}) {
  const [scenariosData, setScenarioData] = React.useState({});
  const [dynamicColumns, setDynamicColumns] = React.useState({}); // for SPs and OPs columns

  useEffect(() => {
    const formattedScenarioObj = Object.assign({}, scenariosDataFromTest);
    const dynamicColumnsObj = {};

    let _scenarios = scenarios || [];
    _scenarios = _scenarios.sort((a, b) => a.scenarioId - b.scenarioId);
    for (let {
      scenarioId,
      scenarioInternalName,
      scenarioDescription,
    } of _scenarios) {
      let scenarioInObj = formattedScenarioObj[scenarioInternalName];
      if (!scenarioInObj) {
        formattedScenarioObj[scenarioInternalName] = {
          scenarioId,
        };
      }
      scenarioInObj = formattedScenarioObj[scenarioInternalName];

      if (
        !!~Object.values(HEALTHCARE_TEST_TYPES).indexOf(testType) ||
        TEST_TYPES.UV === testType
      ) {
        const totalACH = getTotalACH(
          scenarioInObj.PEC_ACH,
          scenarioInObj.HVAC_ACH,
        );
        const achRange = getACHRange(totalACH);
        scenarioInObj.selectorKnobAchSetting = `${achRange[0]} - ${achRange[1]}`;
      }

      scenarioInObj.description = scenarioDescription;
      scenarioInObj.displayName =
        scenarioInObj.displayName || scenarioInternalName;

      // OP tag section
      if (!!~Object.values(HEALTHCARE_TEST_TYPES).indexOf(testType)) {
        const scenarioOP1 = scenarioDataWithOPs.filter(
          (OP) => OP.scenarioId === scenarioId && OP.opNumber === 'OP-001',
        );

        const scenarioTagValueOP1 = scenarioOP1[0].tag.reduce((acc, tagObj) => {
          if (parseInt(tagObj.split(':')[0]) === scenarioId) {
            return tagObj.split(':')[1];
          }
          return acc;
        }, '');

        dynamicColumnsObj.op001Tag = {
          key: 'op001Tag',
          label:
            testType === HEALTHCARE_TEST_TYPES.POSITIVE
              ? `OP-001 Tag`
              : 'OP Tag',
        };
        scenarioInObj.op001Tag = scenarioTagValueOP1;
        scenarioInObj.opCount = 1;

        if (testType === HEALTHCARE_TEST_TYPES.POSITIVE) {
          const scenarioOP2 = scenarioDataWithOPs.filter(
            (OP) => OP.scenarioId === scenarioId && OP.opNumber === 'OP-002',
          );
          const scenarioTagValueOP2 = scenarioOP2[0].tag.reduce(
            (acc, tagObj) => {
              if (parseInt(tagObj.split(':')[0]) === scenarioId) {
                return tagObj.split(':')[1];
              }
              return acc;
            },
            '',
          );

          dynamicColumnsObj.op002Tag = {
            key: 'op002Tag',
            label: `OP-002 Tag`,
          };
          scenarioInObj.op002Tag = scenarioTagValueOP2;
          scenarioInObj.opCount = 2;
        }
      } else if (TEST_TYPES.UV === testType) {
        const scenarioOP1 = scenarioDataWithOPs.filter(
          (OP) => OP.scenarioId === scenarioId && OP.opNumber === 'OP-001::A',
        );

        const scenarioTagValueOP1 = scenarioOP1[0].tag.reduce((acc, tagObj) => {
          if (parseInt(tagObj.split(':')[0]) === scenarioId) {
            return tagObj.split(':')[1];
          }
          return acc;
        }, '');

        dynamicColumnsObj.opGroupA = {
          key: 'opGroupA',
          label: 'OPs First Tag',
        };
        scenarioInObj.opGroupA = scenarioTagValueOP1;

        const scenarioOP2 = scenarioDataWithOPs.filter(
          (OP) => OP.scenarioId === scenarioId && OP.opNumber === 'OP-001::B',
        );
        const scenarioTagValueOP2 = scenarioOP2[0].tag.reduce((acc, tagObj) => {
          if (parseInt(tagObj.split(':')[0]) === scenarioId) {
            return tagObj.split(':')[1];
          }
          return acc;
        }, '');

        dynamicColumnsObj.opGroupB = {
          key: 'opGroupB',
          label: `OPs Second Tag`,
        };
        scenarioInObj.opGroupB = scenarioTagValueOP2;
        scenarioInObj.opCount = scenarioDataWithOPs
          .map((op) => op.opNumber)
          .reduce((acc, opNumber) => {
            if (!acc.includes(opNumber.slice(0, -3))) {
              acc.push(opNumber.slice(0, -3));
            }

            return acc;
          }, []).length;
      } else {
        const scenarioOPs = scenarioDataWithOPs.filter(
          (OP) => OP.scenarioId === scenarioId,
        );

        const opNames = scenarioOPs.map(({ opNumber }) => opNumber);
        const scenarioTagValue = scenarioOPs[0].tag.reduce((acc, tagObj) => {
          if (parseInt(tagObj.split(':')[0]) === scenarioId) {
            return tagObj.split(':')[1];
          }
          return acc;
        }, '');

        if (!dynamicColumnsObj.opTag) {
          const opColumnName =
            opNames.length > 1
              ? `${opNames[0]} - ${opNames[opNames.length - 1]} Tag`
              : `${opNames[0]} Tag`;

          dynamicColumnsObj.opTag = {
            key: 'opTag',
            label: opColumnName,
          };
        }

        scenarioInObj.opTag = scenarioTagValue;
        scenarioInObj.opCount = opNames.length;
      }

      // SP samples section

      const [samplePoints, sampleNumbers] = scenariosWithSPs.scenarios
        .filter(({ id }) => id === scenarioId)[0]
        .SPs.reduce(
          (acc, { number, samples }) => {
            acc[0].push(number);
            acc[1].push(...samples.map(({ samplenumber }) => samplenumber));

            return acc;
          },

          [[], []],
        );

      const samplePointsSorted = samplePoints.sort((a, b) => a - b);
      const sampleNumbersSorted = sampleNumbers.sort((a, b) => a - b);

      const samplePointsColumnName =
        samplePointsSorted.length > 1
          ? `${samplePointsSorted[0]} - ${
              samplePointsSorted[samplePointsSorted.length - 1]
            } Samples`
          : `${samplePointsSorted[0]} Samples`;
      const samplePointsColumnValue = `${sampleNumbersSorted[0]} - ${
        sampleNumbersSorted[sampleNumbersSorted.length - 1]
      }`;

      scenarioInObj.samples = samplePointsColumnValue;
      dynamicColumnsObj.samples = {
        key: 'samples',
        label: samplePointsColumnName,
      };
    }

    setDynamicColumns(dynamicColumnsObj);
    setScenarioData(formattedScenarioObj);
  }, []);

  const TABLE_CONFIG = {
    beforeDynamicSection: {
      displayName: {
        key: 'displayName',
        label: 'Name',
        align: 'left',
      },
      description: {
        key: 'description',
        label: 'Description',
        align: 'left',
      },
      testDate: {
        key: 'testDate',
        label: 'Test Date',
        allowedTestTypes: Object.values(HEALTHCARE_TEST_TYPES),
      },
      testStartTime: {
        key: 'testStartTime',
        label: 'Test Start Time',
        allowedTestTypes: Object.values(HEALTHCARE_TEST_TYPES),
      },
      opCount: {
        key: 'opCount',
        label: 'OP Count',
      },
    },
    afterDynamicSection: {
      hvac_ach: {
        key: 'HVAC_ACH',
        label: 'HVAC ACH',
        align: 'left',
        allowedTestTypes: [
          ...Object.values(HEALTHCARE_TEST_TYPES),
          TEST_TYPES.UV,
        ],
      },
      pec_ach: {
        key: 'PEC_ACH',
        label: 'PEC ACH',
        align: 'left',
        allowedTestTypes: [
          ...Object.values(HEALTHCARE_TEST_TYPES),
          TEST_TYPES.UV,
        ],
      },
      selectorKnobAchSetting: {
        key: 'selectorKnobAchSetting',
        label: 'Selector Knob ACH Setting',
        align: 'left',
        allowedTestTypes: Object.values(HEALTHCARE_TEST_TYPES),
      },
      testDurationSelectorNob: {
        key: 'testDurationSelectorNob',
        label: 'Test Duration (min)',
        align: 'left',
        allowedTestTypes: Object.values(HEALTHCARE_TEST_TYPES),
      },
      achPassFail: {
        key: 'achPassFail',
        label: 'ACH pass/fail',
        align: 'left',
        allowedTestTypes: [
          ...Object.values(HEALTHCARE_TEST_TYPES),
          TEST_TYPES.UV,
        ],
      },
    },
  };

  const semiHeadersTextStyle = {
    fontVariant: 'all-petite-caps',
    fontWeight: 'bolder',
    fontSize: '1.2em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(0, 0, 0, 0.6)',
  };

  const renderRowsFromConfig = (rowConfig) => {
    if (
      rowConfig.allowedTestTypes &&
      !rowConfig.allowedTestTypes.includes(testType)
    )
      return null;

    return (
      <React.Fragment key={rowConfig.key}>
        <StyledTableRow
          coloring="even"
          sx={{ '& > *': { borderBottom: 'unset' } }}
        >
          <StyledTableCell>
            <Typography variant="subtitle2" sx={semiHeadersTextStyle}>
              {typeof rowConfig.label === 'object'
                ? rowConfig.label[testType] || rowConfig.label
                : rowConfig.label}
            </Typography>
          </StyledTableCell>
          {Object.values(scenariosData).map((scenario) => (
            <StyledTableCell
              key={`${scenario.scenarioId}-${rowConfig.key}`}
              align={rowConfig.align}
            >
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="subtitle2"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {scenario[rowConfig.key]}
                </Typography>
              </Stack>
            </StyledTableCell>
          ))}
        </StyledTableRow>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {!!Object.keys(scenariosData).length && (
        <React.Fragment>
          <TableContainer component={'div'} sx={{ overflowX: 'scroll' }}>
            <Table aria-label="scenarios-table-v2" size="small ">
              <TableHead>
                <StyledTableRow border="initial">
                  <StyledTableCell widthcol={2} headertype="secondary" />
                  {Object.values(scenariosData).map(
                    ({ displayName, scenarioId }) => (
                      <StyledTestTableCell
                        widthcol={4}
                        headertype="secondary"
                        key={`scenario-${scenarioId}`}
                        align="left"
                      >
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography
                            style={{
                              textTransform: 'capitalize',
                              ...semiHeadersTextStyle,
                              fontSize: '1.3em',
                            }}
                            variant="subtitle2"
                          >
                            {displayName}
                          </Typography>
                        </Stack>
                      </StyledTestTableCell>
                    ),
                  )}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {Object.values(TABLE_CONFIG.beforeDynamicSection).map(
                  (rowConfig) => renderRowsFromConfig(rowConfig),
                )}
                {Object.values(dynamicColumns).map((rowConfig) =>
                  renderRowsFromConfig(rowConfig),
                )}
                {Object.values(TABLE_CONFIG.afterDynamicSection).map(
                  (rowConfig) => renderRowsFromConfig(rowConfig),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
