import { REQ_ERROR_SHOULD_BE_HIDDEN_INDICATOR } from 'Constants';
import axios from 'axios';

import config from 'Config/config';
import { isSafetracesUser } from 'Config/roles';

import { formSignificanceParamsFromColorsObj, getLSViewCompanyId } from 'Utils';

const BASEURL = config.back_url + '/buildings';

const buildings = {
  getAllBuildings: async (colors = null, inProgress = false) => {
    const companyId = getLSViewCompanyId() ?? 0;
    if (!companyId) {
      return REQ_ERROR_SHOULD_BE_HIDDEN_INDICATOR;
    }

    let queryString = `?companyId=${companyId}`;
    if (colors) {
      const significanceParams = formSignificanceParamsFromColorsObj(
        colors,
        inProgress,
      );

      queryString += significanceParams;
    }
    if (inProgress) {
      queryString += `&inProgress=${inProgress}`;
    }

    return await axios.get(BASEURL + queryString, {});
  },
  getAllBuildingsForPortfolios: async () => {
    return await axios.get(BASEURL + '/forPortfolios');
  },
  getPortfoliosBuildingsForCompany: async () => {
    const companyId = getLSViewCompanyId() ?? 0;
    if (!companyId) return null;

    return await axios.get(
      `${BASEURL}/forPortfolios?companyId=${companyId}&createMappingStructure=1`,
    );
  },
  getBuilding: async (buildingId) => {
    return await axios.get(BASEURL + '/' + buildingId, {});
  },
  getBuildingTests: async (buildingId, colors = null, inProgress = false) => {
    const companyId = getLSViewCompanyId() ?? 0;
    let queryString = `?companyId=${companyId}`;
    if (colors) {
      const significanceParams = formSignificanceParamsFromColorsObj(
        colors,
        inProgress,
      );

      queryString += significanceParams;
    }
    if (inProgress) {
      queryString += `&inProgress=${inProgress}`;
    }
    return await axios.get(
      BASEURL + '/' + buildingId + '/tests' + queryString,
      {},
    );
  },
  createBuilding: async (data) => {
    return await axios.post(BASEURL, data);
  },
  deleteBuilding: async (buildingId) => {
    return await axios.delete(BASEURL + `/${buildingId}`);
  },
  getSites: async () => {
    return await axios.get(BASEURL + '/sites', {});
  },
  getSiteContractBuildings: async (siteContractId) => {
    let queryString = '';
    if (isSafetracesUser()) {
      queryString = `?companyId=${getLSViewCompanyId()}`;
    }
    return await axios.get(
      BASEURL + '/siteContracts/' + siteContractId + queryString,
    );
  },
  getBuildingsByCompanyId: async (companyId) => {
    return await axios.get(BASEURL + '/byCompanyId/' + companyId, {});
  },
  getSitesByCompanyId: async (companyId) => {
    return await axios.get(BASEURL + '/sites/byCompanyId/' + companyId, {});
  },
  createBuildingSingleSite: async (siteId, data) => {
    return await axios.post(BASEURL + '/sites/createBuilding/' + siteId, data);
  },
  linkBuildingSite: async (siteId, buildingId) => {
    return await axios.post(
      `${BASEURL}/sites/linkBuilding/${siteId}/${buildingId}`,
    );
  },
  uploadHDAFile: async (buildingId, file, overwrite = false) => {
    let queryString = BASEURL + '/upload_hda_file/' + buildingId;
    if (overwrite) queryString += '?overwrite=true';
    return await axios.post(queryString, file);
  },
  deleteHDAFile: async (buildingId, filename) => {
    return await axios.post(BASEURL + '/delete_hda_file/' + buildingId, {
      filename,
    });
  },
  downloadHDAFile: async (buildingId, filename) => {
    return await axios.post(BASEURL + '/download_hda_file/' + buildingId, {
      filename,
    });
  },
  updateOneBuilding: async (buildingId, data) => {
    return await axios.put(BASEURL + '/' + buildingId, data);
  },
  downloadBuildingEACHData: async (buildingId) => {
    return await axios.get(BASEURL + '/getEACHcalculated/' + buildingId);
  },
  getSite: async (siteId) => {
    return await axios.get(BASEURL + `/site/${siteId}`);
  },
  updateSite: async (siteId, data) => {
    return await axios.put(`${BASEURL}/site/${siteId}`, data);
  },
  createSite: async (data) => {
    return await axios.post(`${BASEURL}/site/`, data);
  },
  deleteSite: async (siteId) => {
    return await axios.delete(`${BASEURL}/site/${siteId}`);
  },
  getTerritory: async (territoryId) => {
    return await axios.get(BASEURL + `/territory/${territoryId}`);
  },
  updateTerritory: async (territoryId, data) => {
    return await axios.put(`${BASEURL}/territory/${territoryId}`, data);
  },
  createTerritory: async (data) => {
    return await axios.post(`${BASEURL}/territory/`, data);
  },
  deleteTerritory: async (territoryId) => {
    return await axios.delete(`${BASEURL}/territory/${territoryId}`);
  },
  getRegion: async (regionId) => {
    return await axios.get(BASEURL + `/region/${regionId}`);
  },
  updateRegion: async (regionId, data) => {
    return await axios.put(`${BASEURL}/region/${regionId}`, data);
  },
  createRegion: async (data) => {
    return await axios.post(`${BASEURL}/region/`, data);
  },
  deleteRegion: async (regionId) => {
    return await axios.delete(`${BASEURL}/region/${regionId}`);
  },
  uploadBuildingImage: async (buildingId, file) => {
    return await axios.post(`${BASEURL}/uploadImage/${buildingId}`, file);
  },
  deleteBuildingImage: async (buildingId) => {
    return await axios.post(`${BASEURL}/deleteImage/${buildingId}`);
  },
  getDisplayImagePresignedUrl: async (buildingId) => {
    return await axios.get(`${BASEURL}/imagePresignedUrl/${buildingId}`);
  },
  getHDASummary: async (buildingId) => {
    return await axios.get(`${BASEURL}/hdaSummary/${buildingId}`);
  },
};

export default buildings;
