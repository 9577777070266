import axios from 'axios';

import config from 'Config/config';
import { isPartnerUser, isSafetracesUser } from 'Config/roles';

import { getLSViewCompanyId } from 'Utils';

const BASEURL = config.back_url + '/contracts';

const contracts = {
  getContract: async (contractId) => {
    return await axios.get(BASEURL + `/${contractId}`);
  },
  updateContract: async (contractId, data) => {
    return await axios.put(BASEURL + `/${contractId}`, data);
  },
  getAllContracts: async () => {
    return await axios.get(BASEURL + '/contracts');
  },
  createContract: async (data) => {
    return await axios.post(BASEURL, data);
  },
  removeContract: async (contractId) => {
    return await axios.delete(BASEURL + `/${contractId}`);
  },
  createSiteContract: async (data) => {
    return await axios.post(BASEURL + '/siteContracts', data);
  },
  removeSiteContract: async (siteContractId) => {
    return await axios.delete(BASEURL + `/siteContracts/${siteContractId}`);
  },
  getSiteContracts: async () => {
    let queryString = '';
    if (isSafetracesUser() || isPartnerUser()) {
      queryString = `?companyId=${getLSViewCompanyId()}`;
    }
    return await axios.get(BASEURL + '/siteContracts' + queryString);
  },
  getSiteContract: async (siteContractId) => {
    let queryString = '';
    if (isSafetracesUser() || isPartnerUser()) {
      queryString = `?companyId=${getLSViewCompanyId()}`;
    }
    return await axios.get(
      BASEURL + '/siteContracts/' + siteContractId + queryString,
    );
  },
  updateSiteContract: async (siteContractId, data) => {
    return await axios.put(`${BASEURL}/siteContracts/${siteContractId}`, data);
  },
  getTestPackages: async (siteContractId) => {
    let queryString = '';
    if (isSafetracesUser() || isPartnerUser()) {
      queryString = `?companyId=${getLSViewCompanyId()}`;
    }
    return await axios.get(
      BASEURL + '/testPackages/' + siteContractId + queryString,
    );
  },
  createTestPackage: async (data) => {
    return await axios.post(BASEURL + '/testPackages', data);
  },
  updateTestPackagesProject: async (data) => {
    return await axios.put(BASEURL + '/updateTestPackagesProject', data);
  },
  removeTestPackage: async (testPackageId) => {
    return await axios.delete(BASEURL + '/removeTestPackages/' + testPackageId);
  },
  getSiteContractsTestPackagesCount: async () => {
    let queryString = '';
    if (isSafetracesUser() || isPartnerUser()) {
      queryString = `?companyId=${getLSViewCompanyId()}`;
    }
    return await axios.get(
      BASEURL + '/siteContractsTestPackagesCount/' + queryString,
    );
  },
  getContractExample: async () => {
    return await axios.get(BASEURL + '/getContractExample');
  },
  /**
   * ST Admin users only.
   * Removes the site contract with all its underlying instances, such as:
   * test packages, projects, tests, results, segments, scenarios, SP & OP points and so on.
   */
  removeSiteContractDeep: async (siteContractId) => {
    return await axios.delete(
      `${config.back_url}/contracts/removeSiteContractDeep/${siteContractId}`,
    );
  },
  removeContractDeep: async (contractId) => {
    return await axios.delete(
      `${config.back_url}/contracts/removeContractDeep/${contractId}`,
    );
  },
};

export default contracts;
