import { PROJECT_STATUSES } from 'Constants';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import originPoints from '../../Api/originpoints';
import samplePoints from '../../Api/samplePoints';
import FloorplanComponent from './OPsSPs/FloorplanComponent';
import OPItem from './OPsSPs/OPItem';
import SPItem from './OPsSPs/SPItem';
import { useFloorplanContext } from './context';

import { Check, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  CircularProgress,
  Slider,
  Stack,
  Typography,
} from '@mui/material';

import { isSafetracesAdmin, isViewer } from 'Config/roles';

const OpsSpsPlacement = () => {
  const { testId, setError, testData, projectData } = useFloorplanContext();
  const [OPs, setOPs] = useState([]);
  const [SPs, setSPs] = useState([]);
  const [markerSize, setMarkerSize] = useState(3);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [updateStatus, setUpdateStatus] = useState('idle');

  useEffect(() => {
    fetchOpsAndSps(testId);
  }, [testId]);

  const fetchOpsAndSps = async (testId) => {
    if (testId) {
      try {
        const OPs = await originPoints.getAllOriginPointsForTest(testId);
        const SPs = await samplePoints.getAllSamplePointsForTest(testId);
        if (OPs.data?.success && SPs.data?.success) {
          setOPs(OPs.data?.data);
          setSPs(SPs.data?.data);
        } else {
          setError('Error fetching the OP and SP data');
        }
      } catch (e) {
        console.error(e);
        setError(
          'Error fetching the OP and SP data, check the console for details',
        );
      }
    }
  };

  const renderStatus = () => {
    return (
      <Box width="50px" height="50px">
        {updateStatus === 'loading' && <CircularProgress size="small" />}
        {updateStatus === 'success' && <Check color="green" />}
      </Box>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack flexDirection="row" sx={{ alignItems: 'center' }}>
        {!isViewer() && (
          <Stack
            spacing={3}
            sx={{
              maxHeight: '700px',
              overflowY: 'scroll',
              width: '25%',
              mr: '60px',
              mb: 'auto',
            }}
          >
            {testData && testData.testexecuted && !isSafetracesAdmin() && (
              <Alert severity="info">
                The test has been executed and changes are no longer allowed
              </Alert>
            )}
            {isSafetracesAdmin() &&
            projectData &&
            projectData.status === PROJECT_STATUSES.PUBLISHED ? (
              <Alert severity="info">
                Project is published and changes are no longer allowed
              </Alert>
            ) : null}
            <Stack direction="row">
              <Typography variant="h5">Marker size</Typography>
              {renderStatus()}
            </Stack>
            <Slider
              aria-label="Marker size"
              defaultValue={markerSize}
              min={1}
              max={5}
              value={markerSize}
              onChange={(e, value) => setMarkerSize(value)}
              sx={{ width: '90%', marginX: '0' }}
              marks={[
                {
                  value: 1,
                  label: 'xs',
                },
                {
                  value: 2,
                  label: 'sm',
                },
                {
                  value: 3,
                  label: 'md',
                },
                {
                  value: 4,
                  label: 'lg',
                },
                {
                  value: 5,
                  label: 'xl',
                },
              ]}
            />
            <Box>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h5">Origin points</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={1}>
                    {OPs.filter((_op) => !~_op.opnumber.indexOf('::B'))
                      .map((op) => {
                        op.opnumber = op.opnumber.replace('::A', '');
                        return op;
                      })
                      .map((op) => (
                        <OPItem
                          key={op.opid}
                          op={op}
                          reFetch={() => {
                            fetchOpsAndSps(testId);
                          }}
                        />
                      ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h5">Sample points</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={1}>
                    {SPs.map((sp) => (
                      <SPItem
                        key={sp.spid}
                        sp={sp}
                        reFetch={() => {
                          fetchOpsAndSps(testId);
                        }}
                      />
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Stack>
        )}
        <FloorplanComponent
          testId={testId}
          OPs={OPs}
          SPs={SPs}
          reFetchOpsAndSps={() => {
            fetchOpsAndSps(testId);
          }}
          markerSize={markerSize}
          isImageLoaded={isImageLoaded}
          setIsImageLoaded={setIsImageLoaded}
          updateStatus={updateStatus}
          setUpdateStatus={setUpdateStatus}
        />
      </Stack>
    </DndProvider>
  );
};

export default OpsSpsPlacement;
