import axios from 'axios';

import config from 'Config/config';
import { isSafetracesUser } from 'Config/roles';

import { getLSViewCompanyId } from 'Utils';

const BASEURL = config.back_url + '/portfolios';

const portfolios = {
  getAllPortfolios: async () => {
    return await axios.get(BASEURL);
  },
  getPortfolio: async (portfolioId) => {
    return await axios.get(BASEURL + `/${portfolioId}`);
  },
  createPortfolio: async (data) => {
    return await axios.post(BASEURL, data);
  },
  getPortfoliosContracts: async () => {
    let queryString = '';
    if (isSafetracesUser()) {
      queryString = `?companyId=${getLSViewCompanyId()}`;
    }

    return await axios.get(BASEURL + '/contracts' + queryString);
  },
  removePortfolio: async (portfolioId) => {
    return await axios.delete(BASEURL + `/${portfolioId}`);
  },
  getDocuments: async () => {
    return await axios.get(BASEURL + '/getDocuments');
  },
  uploadSummary: async (portfolioId, file) => {
    console.log(file);
    return await axios.post(BASEURL + '/uploadSummary/' + portfolioId, file);
  },
  deleteSummary: async (portfolioId, summaryId) => {
    return await axios.delete(
      BASEURL + '/deleteSummary/' + portfolioId + '/' + summaryId,
    );
  },
  getPortfoliosWithSummaries: async () => {
    return await axios.get(
      BASEURL +
        '/getPortfoliosWithSummaries' +
        `?companyId=${getLSViewCompanyId()}`,
    );
  },
  downloadSummary: async (portfolioId, summaryId, fileName) => {
    return await axios.get(
      BASEURL +
        '/downloadSummary/' +
        portfolioId +
        '/' +
        summaryId +
        `?fileName=${fileName}`,
    );
  },
  /**
   * Request for portfolio sites data. If no portfolioId is provided, returns data for all sites for all portfolios
   * @param {string=} portfolioId
   * @returns {Promise<AxiosResponse<unknown>>}
   */
  getPortfolioSites: async (portfolioId) => {
    let queryString = '/withSites';
    if (
      portfolioId &&
      (typeof portfolioId === 'string' || typeof portfolioId === 'number')
    ) {
      queryString = `${queryString}/${portfolioId}`;
    }
    return await axios.get(BASEURL + queryString);
  },
  getPortfolioExample: async () => {
    return await axios.get(BASEURL + '/getPortfolioExample');
  },
  updatePortfolio: async (portfolioId, data) => {
    return await axios.put(BASEURL + `/${portfolioId}`, data);
  },
  removePortfolioDeep: async (portfolioId) => {
    return await axios.delete(BASEURL + `/removePortfolioDeep/${portfolioId}`);
  }
};

export default portfolios;
