import {
  HEALTHCARE_TEST_TYPES,
  HEALTHCARE_TEST_TYPES_LABELS,
  TEST_TYPES,
  TEST_TYPES_LABELS,
} from 'Constants';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Add } from '@mui/icons-material';
import { Button, Grid, IconButton, MenuItem, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import contractsApi from 'Api/contracts';

import { usePortfolioContext } from 'Components/Portfolios/context';
import { validateSiteContractData } from 'Components/Portfolios/utils';
import SelectControl from 'Components/Select/SelectControl';

import { getCompanies, isValidResponse, testValue } from 'Utils';

function AddSiteContractButton({ contract }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enhancedContract, setEnhancedContract] = useState({});

  const { setErrorToast, setSuccessToast, sites, fetchPortfoliosAndSites } =
    usePortfolioContext();

  const companiesPartner = getCompanies().filter(
    (company) => company.type && company.type.toLowerCase() === 'partner',
  );

  const portfolioSites = sites[contract.portfolioid]?.sites;

  useEffect(() => {
    if (
      !Object.keys(contract).length ||
      !contract.contractid ||
      !contract.portfolioid
    ) {
      setErrorToast('Can not create site contract');
      return;
    }

    const contractsTestsInfo = contract.testsmetadata || contract;

    let contractWithNewTests = {
      contractId: contract.contractid,
      smallSurveyTests: contractsTestsInfo.smallsurveytests ?? 0,
      largeSurveyTests: contractsTestsInfo.largesurveytests ?? 0,
      smallDilutionTests: contractsTestsInfo.smalldilutiontests ?? 0,
      largeDilutionTests: contractsTestsInfo.largedilutiontests ?? 0,
      miniSurveyTests: contractsTestsInfo.minisurveytests ?? 0,
      recirculationTests: contractsTestsInfo.recirculationtests ?? 0,
      verificationTests: contractsTestsInfo.verificationtests ?? 0,
      [HEALTHCARE_TEST_TYPES.NEGATIVE]:
        contractsTestsInfo[HEALTHCARE_TEST_TYPES.NEGATIVE] ?? 0,
      [HEALTHCARE_TEST_TYPES.POSITIVE]:
        contractsTestsInfo[HEALTHCARE_TEST_TYPES.POSITIVE] ?? 0,
      [HEALTHCARE_TEST_TYPES.NEUTRAL]:
        contractsTestsInfo[HEALTHCARE_TEST_TYPES.NEUTRAL] ?? 0,
      [TEST_TYPES.UV]: contractsTestsInfo[TEST_TYPES.UV] ?? 0,
    };
    for (let siteContract of Object.values(contract.sitecontracts)) {
      const siteContractTestsInfo = siteContract.testsmetadata || siteContract;

      contractWithNewTests.smallSurveyTests -=
        siteContractTestsInfo.smallsurveytests ?? 0;
      contractWithNewTests.largeSurveyTests -=
        siteContractTestsInfo.largesurveytests ?? 0;
      contractWithNewTests.smallDilutionTests -=
        siteContractTestsInfo.smalldilutiontests ?? 0;
      contractWithNewTests.largeDilutionTests -=
        siteContractTestsInfo.largedilutiontests ?? 0;
      contractWithNewTests.miniSurveyTests -=
        siteContractTestsInfo.minisurveytests ?? 0;
      contractWithNewTests.recirculationTests -=
        siteContractTestsInfo.recirculationtests ?? 0;
      contractWithNewTests.verificationTests -=
        siteContractTestsInfo.verificationtests ?? 0;
      contractWithNewTests[HEALTHCARE_TEST_TYPES.NEGATIVE] -=
        siteContractTestsInfo[HEALTHCARE_TEST_TYPES.NEGATIVE] ?? 0;
      contractWithNewTests[HEALTHCARE_TEST_TYPES.POSITIVE] -=
        siteContractTestsInfo[HEALTHCARE_TEST_TYPES.POSITIVE] ?? 0;
      contractWithNewTests[HEALTHCARE_TEST_TYPES.NEUTRAL] -=
        siteContractTestsInfo[HEALTHCARE_TEST_TYPES.NEUTRAL] ?? 0;
      contractWithNewTests[TEST_TYPES.UV] -=
        siteContractTestsInfo[TEST_TYPES.UV] ?? 0;
    }
    setEnhancedContract(contractWithNewTests);
  }, [contract]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    clearErrors,
    control,
  } = useForm({
    defaultValues: {
      site: Array.isArray(portfolioSites)
        ? portfolioSites[0].siteid
        : undefined,
    },
  });

  const handleCloseDialog = () => {
    setIsOpen(false);
    reset();
    clearErrors();
  };

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const onSiteContractSubmit = async (data) => {
    try {
      setIsLoading(true);
      let sendObj = {
        siteContractName: data.siteContractName,
        smallSurveyTests: data.smallSurveyTests,
        largeSurveyTests: data.largeSurveyTests,
        smallDilutionTests: data.smallDilutionTests,
        largeDilutionTests: data.largeDilutionTests,
        miniSurveyTests: data.miniSurveyTests,
        recirculationTests: data.recirculationTests,
        verificationTests: data.verificationTests,
        [HEALTHCARE_TEST_TYPES.NEGATIVE]: data[HEALTHCARE_TEST_TYPES.NEGATIVE],
        [HEALTHCARE_TEST_TYPES.POSITIVE]: data[HEALTHCARE_TEST_TYPES.POSITIVE],
        [HEALTHCARE_TEST_TYPES.NEUTRAL]: data[HEALTHCARE_TEST_TYPES.NEUTRAL],
        [TEST_TYPES.UV]: data[TEST_TYPES.UV],
        partner: data.partner,
        contractId: contract.contractid,
        site: data.site,
      };
      if (!validateSiteContractData(sendObj)) {
        setErrorToast('Add at least one test');
      } else {
        const response = await contractsApi.createSiteContract(sendObj);
        if (isValidResponse(response)) {
          await fetchPortfoliosAndSites();
          setSuccessToast('Site Contract has been successfully changed.');
          handleCloseDialog();
        } else {
          setErrorToast(response.data?.error?.message.toString());
        }
      }
    } catch (e) {
      setErrorToast(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <IconButton
        title="Add new site contract"
        color="success"
        size="small"
        onClick={handleOpenDialog}
      >
        <Add />
      </IconButton>
      <Dialog open={isOpen}>
        <form onSubmit={handleSubmit(onSiteContractSubmit)}>
          <DialogTitle sx={{ textAlign: 'center' }}>
            Add Site Contract to <br /> {contract.contractname}
          </DialogTitle>
          <DialogContent sx={{ width: 430 }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Site Contract Name"
              name="siteContractName"
              id="siteContractName"
              {...register('siteContractName', {
                required: 'Site Contract Name field required',
                minLength: {
                  value: 3,
                  message: 'Site Contract Name must have at least 3 characters',
                },
              })}
              error={!!(errors && errors.siteContractName)}
              helperText={errors?.siteContractName?.message}
              disabled={isLoading}
            />
            <SelectControl
              control={control}
              rules={{ required: 'Partner field required' }}
              errors={errors}
              name={'partner'}
              label={'Partner'}
              options={
                !!companiesPartner &&
                companiesPartner.map((item) => (
                  <MenuItem key={item.companyid} value={item.companyid}>
                    {item.companyname}
                  </MenuItem>
                ))
              }
            />
            <SelectControl
              control={control}
              rules={{ required: 'Site field required' }}
              errors={errors}
              name={'site'}
              label={'Sites'}
              options={
                Array.isArray(portfolioSites) &&
                portfolioSites?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              }
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label="Small Survey Tests"
                  name="smallSurveyTests"
                  id="smallSurveyTests"
                  inputProps={{
                    min: 0,
                    max: testValue(enhancedContract?.smallSurveyTests),
                  }}
                  {...register('smallSurveyTests', {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(enhancedContract?.smallSurveyTests) ||
                        `Max value is ${testValue(
                          enhancedContract?.smallSurveyTests,
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors.smallSurveyTests)}
                  helperText={errors?.smallSurveyTests?.message}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label="Large Survey Tests"
                  name="largeSurveyTests"
                  id="largeSurveyTests"
                  inputProps={{
                    min: 0,
                    max: testValue(enhancedContract?.largeSurveyTests),
                  }}
                  {...register('largeSurveyTests', {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(enhancedContract?.largeSurveyTests) ||
                        `Max value is ${testValue(
                          enhancedContract?.largeSurveyTests,
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors.largeSurveyTests)}
                  helperText={errors?.largeSurveyTests?.message}
                  disabled={isLoading}
                />
              </Grid>
              {!!enhancedContract?.smallDilutionTests ? (
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    fullWidth
                    label="Small Dilution Tests"
                    name="smallDilutionTests"
                    id="smallDilutionTests"
                    inputProps={{
                      min: 0,
                      max: testValue(enhancedContract?.smallDilutionTests),
                    }}
                    {...register('smallDilutionTests', {
                      validate: {
                        lessThanTen: (v) =>
                          !v ||
                          parseInt(v) <=
                            testValue(enhancedContract?.smallDilutionTests) ||
                          `Max value is ${testValue(
                            enhancedContract?.smallDilutionTests,
                          )}`,
                      },
                      min: {
                        value: 0,
                        message: 'Min value is 0',
                      },
                    })}
                    error={!!(errors && errors.smallDilutionTests)}
                    helperText={errors?.smallDilutionTests?.message}
                    disabled={isLoading}
                  />
                </Grid>
              ) : null}
              {!!enhancedContract?.largeDilutionTests ? (
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    fullWidth
                    label="Large Dilution Tests"
                    name="largeDilutionTests"
                    id="largeDilutionTests"
                    inputProps={{
                      min: 0,
                      max: testValue(enhancedContract?.largeDilutionTests),
                    }}
                    {...register('largeDilutionTests', {
                      validate: {
                        lessThanTen: (v) =>
                          !v ||
                          parseInt(v) <=
                            testValue(enhancedContract?.largeDilutionTests) ||
                          `Max value is ${testValue(
                            enhancedContract?.largeDilutionTests,
                          )}`,
                      },
                      min: {
                        value: 0,
                        message: 'Min value is 0',
                      },
                    })}
                    error={!!(errors && errors.largeDilutionTests)}
                    helperText={errors?.largeDilutionTests?.message}
                    disabled={isLoading}
                  />
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label="Flex Tests"
                  name="recirculationTests"
                  id="recirculationTests"
                  inputProps={{
                    min: 0,
                    max: testValue(enhancedContract?.recirculationTests),
                  }}
                  {...register('recirculationTests', {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(enhancedContract?.recirculationTests) ||
                        `Max value is ${testValue(
                          enhancedContract?.recirculationTests,
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors.recirculationTests)}
                  helperText={errors?.recirculationTests?.message}
                  disabled={isLoading}
                />
              </Grid>
              {!!enhancedContract?.miniSurveyTests ? (
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="number"
                    fullWidth
                    label="Mini Survey Tests"
                    name="miniSurveyTests"
                    id="miniSurveyTests"
                    inputProps={{
                      min: 0,
                      max: testValue(enhancedContract?.miniSurveyTests),
                    }}
                    {...register('miniSurveyTests', {
                      validate: {
                        lessThanTen: (v) =>
                          !v ||
                          parseInt(v) <=
                            testValue(enhancedContract?.miniSurveyTests) ||
                          `Max value is ${testValue(
                            enhancedContract?.miniSurveyTests,
                          )}`,
                      },
                      min: {
                        value: 0,
                        message: 'Min value is 0',
                      },
                    })}
                    error={!!(errors && errors.miniSurveyTests)}
                    helperText={errors?.miniSurveyTests?.message}
                    disabled={isLoading}
                  />
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label="Verification Tests"
                  name="verificationTests"
                  id="verificationTests"
                  inputProps={{
                    min: 0,
                    max: testValue(enhancedContract?.verificationTests),
                  }}
                  {...register('verificationTests', {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(enhancedContract?.verificationTests) ||
                        `Max value is ${testValue(
                          enhancedContract?.verificationTests,
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors.verificationTests)}
                  helperText={errors?.verificationTests?.message}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label={
                    HEALTHCARE_TEST_TYPES_LABELS[HEALTHCARE_TEST_TYPES.NEGATIVE]
                  }
                  name={HEALTHCARE_TEST_TYPES.NEGATIVE}
                  id={HEALTHCARE_TEST_TYPES.NEGATIVE}
                  inputProps={{
                    min: 0,
                    max: testValue(
                      enhancedContract?.[HEALTHCARE_TEST_TYPES.NEGATIVE],
                    ),
                  }}
                  {...register(HEALTHCARE_TEST_TYPES.NEGATIVE, {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(
                            enhancedContract?.[HEALTHCARE_TEST_TYPES.NEGATIVE],
                          ) ||
                        `Max value is ${testValue(
                          enhancedContract?.[HEALTHCARE_TEST_TYPES.NEGATIVE],
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors[HEALTHCARE_TEST_TYPES.NEGATIVE])}
                  helperText={errors?.[HEALTHCARE_TEST_TYPES.NEGATIVE]?.message}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label={
                    HEALTHCARE_TEST_TYPES_LABELS[HEALTHCARE_TEST_TYPES.NEUTRAL]
                  }
                  name={HEALTHCARE_TEST_TYPES.NEUTRAL}
                  id={HEALTHCARE_TEST_TYPES.NEUTRAL}
                  inputProps={{
                    min: 0,
                    max: testValue(
                      enhancedContract?.[HEALTHCARE_TEST_TYPES.NEUTRAL],
                    ),
                  }}
                  {...register(HEALTHCARE_TEST_TYPES.NEUTRAL, {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(
                            enhancedContract?.[HEALTHCARE_TEST_TYPES.NEUTRAL],
                          ) ||
                        `Max value is ${testValue(
                          enhancedContract?.[HEALTHCARE_TEST_TYPES.NEUTRAL],
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors[HEALTHCARE_TEST_TYPES.NEUTRAL])}
                  helperText={errors?.[HEALTHCARE_TEST_TYPES.NEUTRAL]?.message}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label={
                    HEALTHCARE_TEST_TYPES_LABELS[HEALTHCARE_TEST_TYPES.POSITIVE]
                  }
                  name={HEALTHCARE_TEST_TYPES.POSITIVE}
                  id={HEALTHCARE_TEST_TYPES.POSITIVE}
                  inputProps={{
                    min: 0,
                    max: testValue(
                      enhancedContract?.[HEALTHCARE_TEST_TYPES.POSITIVE],
                    ),
                  }}
                  {...register(HEALTHCARE_TEST_TYPES.POSITIVE, {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(
                            enhancedContract?.[HEALTHCARE_TEST_TYPES.POSITIVE],
                          ) ||
                        `Max value is ${testValue(
                          enhancedContract?.[HEALTHCARE_TEST_TYPES.POSITIVE],
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors[HEALTHCARE_TEST_TYPES.POSITIVE])}
                  helperText={errors?.[HEALTHCARE_TEST_TYPES.POSITIVE]?.message}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="number"
                  fullWidth
                  label={TEST_TYPES_LABELS[TEST_TYPES.UV]}
                  name={TEST_TYPES.UV}
                  id={TEST_TYPES.UV}
                  inputProps={{
                    min: 0,
                    max: testValue(enhancedContract?.[TEST_TYPES.UV]),
                  }}
                  {...register(TEST_TYPES.UV, {
                    validate: {
                      lessThanTen: (v) =>
                        !v ||
                        parseInt(v) <=
                          testValue(enhancedContract?.[TEST_TYPES.UV]) ||
                        `Max value is ${testValue(
                          enhancedContract?.[TEST_TYPES.UV],
                        )}`,
                    },
                    min: {
                      value: 0,
                      message: 'Min value is 0',
                    },
                  })}
                  error={!!(errors && errors[TEST_TYPES.UV])}
                  helperText={errors?.[TEST_TYPES.UV]?.message}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isLoading}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default AddSiteContractButton;
