import axios from 'axios';

import config from 'Config/config';

const BASEURL = config.back_url + '/auth';

const users = {
  getUsersList: async (companyId) => {
    return await axios.get(BASEURL + '/users/list/' + companyId);
  },
  recoverPassword: async (email) => {
    return await axios.put(BASEURL + '/users/recoverPassword', email);
  },
  signUp: async (data) => {
    return await axios.post(BASEURL + '/users/signUp', data);
  },
  inviteUser: async (body) => {
    return await axios.post(BASEURL + '/users/inviteUser/', body);
  },
  getAllRoles: async () => {
    return await axios.get(BASEURL + '/roles/');
  },
  getAllPermissionLevels: async () => {
    return await axios.get(BASEURL + '/permissions/');
  },
  removeOneUser: async (userId) => {
    return await axios.delete(BASEURL + '/users/' + userId);
  },
  approveUser: async (data) => {
    return await axios.put(BASEURL + '/users/approveUser/', data);
  },
  getUsers: async () => {
    return await axios.get(BASEURL + '/users/');
  },
  updateOneUser: async (userId, data) => {
    return await axios.put(BASEURL + '/users/' + userId, data);
  },
};

export default users;
