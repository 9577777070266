import { TEST_TYPES } from 'Constants';
import {
  PRESENTATION_DEFAULT_LEFT_MARGIN,
  PRESENTATION_DEFAULT_SIZE,
  createPresentation,
  createTableForVerificationHeatmapSummary,
  createTemplateSlideForPresentation,
} from 'Utils/presentationUtils';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Alert,
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import floorplansApi from 'Api/floorPlans';
import resultsApi from 'Api/results';
import testsApi from 'Api/tests';

import BreadcrumbsFloorplanProjectHeatmap from 'Components/Breadcrumbs/BreadcrumbsFloorplanProjectHeatmap';
import { fileNameUtil, getScaledImageSize } from 'Components/Floorplans/utils';
import ElementsControlPanel from 'Components/HeatMap/ElementsControlPanel';
import NewHeatmap from 'Components/HeatMap/NewHeatmap';
import MapInteractionCustom from 'Components/MapInteractionCustom';
import ErrorMessage from 'Components/UI/ErrorMessage';
import Loading from 'Components/UI/Loading';

import {
  buildingProjectResultsSelector,
  fetchBuildingProjects,
} from 'Features/BuildingProjectsResults/buildingProjectResultsSlice';
import { colorsSelector } from 'Features/Colors/colorsSlice';
import { inProgressSelector } from 'Features/Filter/inProgressSlice';
import {
  heatmapSelector,
  setFinishLoading,
  setLoadingFailed,
  setStartLoading,
} from 'Features/Heatmap/heatmapSlice';
import { fetchBuildings } from 'Features/Results/resultsSlice';

import {
  getCalcResultLabelFromVerLikeTest,
  getDataFromResponse,
  getErrorMessageFromResponse,
  getNewMapValueOnScaleChange,
  heatmapDownloadFloorPlan,
  isValidResponse,
} from 'Utils';

export default function SingleProjectFloorplanCompilationHeatmap() {
  const ref = useRef(null);

  const { projects, isLoading: isProjectDataLoading } = useSelector(
    buildingProjectResultsSelector,
  );
  const { isLoading: isHeatmapDataLoading } = useSelector(heatmapSelector);

  const dispatch = useDispatch();
  const [mapValue, setMapValue] = useState({
    scale: 1,
    translation: { x: 0, y: 0 },
  });
  const [alert, setAlert] = useState({
    message: '',
  });
  const [disablePan, setDisablePan] = useState(false);
  const { inProgress } = useSelector(inProgressSelector);
  const { colors } = useSelector(colorsSelector);

  const [mainError, setMainError] = useState(null);

  const [elementsViewSettings, setElementsViewSettings] = useState({
    sliderHeatmap: 0,
    sliderCropBox: 0,
    sliderSp: 35,
    showSpOpValue: true,
    minScaleHeatmap: 0.1,
  });

  const [floorplanLoading, setFloorplanLoading] = useState(false);
  const [floorplanData, setFloorplanData] = useState(null);

  const [projectTestDisplayNumbers, setProjectTestDisplayNumbers] = useState(
    {},
  );
  const [isTestDisplayNumbersLoading, setIsTestDisplayNumbersLoading] =
    useState(false);

  const { buildingId, projectId, floorplanId } = useParams();
  const [heatmapsByScenarios, setHeatmapsByScenarios] = useState({});
  const [scenarioSelected, setScenarioSelected] = useState(1);

  const [mergedHeatmap, setMergedHeatmap] = useState(null);
  const [showLabelsForPoints, setShowLabelsForPoints] = useState(true);

  const mapRef = {};

  const isLoading =
    isProjectDataLoading ||
    isHeatmapDataLoading ||
    floorplanLoading ||
    isTestDisplayNumbersLoading;

  useEffect(() => {
    floorplanDownload();
    getTestDisplayNumbersInProject();

    dispatch(fetchBuildings(colors, buildingId, false, inProgress));
    dispatch(fetchBuildingProjects(buildingId));

    fetchHeatMaps();
  }, []);

  const floorplansInProjectData = useMemo(() => {
    const project = projects.find(
      ({ id }) => parseInt(id) === parseInt(projectId),
    );

    if (!project) return {};
    const floorplansObj = {};
    for (let { filename, metadata } of project.heatmaps) {
      floorplansObj[filename] = metadata;
    }

    return floorplansObj;
  }, [projects]);

  useEffect(() => {
    updateMergedHeatmap(scenarioSelected);
  }, [
    isLoading,
    floorplansInProjectData,
    heatmapsByScenarios,
    scenarioSelected,
  ]);

  const getTestDisplayNumbersInProject = async () => {
    try {
      setIsTestDisplayNumbersLoading(true);
      const response = await testsApi.getDisplayNumbersProject(
        parseInt(projectId, 10),
      );
      if (!isValidResponse(response))
        throw new Error(getErrorMessageFromResponse(response));

      setProjectTestDisplayNumbers(getDataFromResponse(response));
    } catch (err) {
      console.log('getTestDisplayNumbersInProject error', err);
    } finally {
      setIsTestDisplayNumbersLoading(false);
    }
  };

  const updateMergedHeatmap = (scenarioIndex) => {
    if (isLoading) {
      setMergedHeatmap(null);
      return;
    }

    const heatmapsInScenario = heatmapsByScenarios[scenarioIndex];
    if (!heatmapsInScenario || !Object.keys(floorplansInProjectData).length) {
      setMergedHeatmap(null);
      return;
    }

    const mergedHeatmap = {
      buildingData: {},
      op: [],
      sp: [],
    };

    const buildingDataArr = [[], []];
    for (let heatmapData of heatmapsInScenario) {
      const heatmap = heatmapData.heatmaps?.[0];
      if (!heatmap) continue;

      const testResultPercent = getTestResultDisplayDataFromCalculatedResult(
        heatmapData.calculatedresult,
      );

      const { floorplan, sp, buildingdata } = heatmap;
      const { sq_ft, celling_height } = buildingdata;
      if (!buildingDataArr[0].includes(sq_ft)) {
        buildingDataArr[0].push(sq_ft);
      }
      if (!buildingDataArr[1].includes(celling_height)) {
        buildingDataArr[1].push(celling_height);
      }

      const spsForMerged = [];
      const shiftData = floorplansInProjectData[floorplan]?.cropData;

      const shiftX = shiftData?.x || 0;
      const shiftY = shiftData?.y || 0;
      const testNumber = projectTestDisplayNumbers[heatmapData.testid];
      for (let _sp of sp) {
        if (_sp.spnumber !== 'SP-001') continue;

        const newSp = Object.assign({}, _sp, {
          spcoordx: parseFloat(_sp.spcoordx) + shiftX,
          spcoordy: parseFloat(_sp.spcoordy) + shiftY,
          testNumber: testNumber ? `Test Number: ${testNumber}` : '',
          result: {
            displayValue: testResultPercent.displayValue,
            isValid: testResultPercent.isValid,
            pass: testResultPercent.pass,
          },
        });

        spsForMerged.push(newSp);
      }
      mergedHeatmap.sp.push(...spsForMerged);
    }

    if (buildingDataArr[0].length === 1) {
      mergedHeatmap.buildingData.sq_ft = buildingDataArr[0][0];
    }
    if (buildingDataArr[1].length === 1) {
      mergedHeatmap.buildingData.celling_height = buildingDataArr[1][0];
    }

    setMergedHeatmap(mergedHeatmap);
  };

  const floorplanDownload = async () => {
    setFloorplanLoading(true);
    try {
      const response = await floorplansApi.getFloorPlan(
        parseInt(floorplanId, 10),
      );
      if (isValidResponse(response)) {
        const fpData = getDataFromResponse(response)[0];
        const fileName = fpData?.filename;

        const fpUrl = await heatmapDownloadFloorPlan(
          fileName,
          parseInt(projectId, 10),
        );

        setFloorplanData({
          name: fileName,
          url: fpUrl,
          floorNumber: parseInt(fpData?.floornumber, 10) || 0,
        });
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      console.log(`floorplanDownload Error: `, err);
    } finally {
      setFloorplanLoading(false);
    }
  };

  const fetchHeatMaps = async () => {
    try {
      dispatch(setStartLoading());

      const heatmapsForFloorplanInProject =
        await resultsApi.getHeatmapListForFloorplanInProject(
          parseInt(projectId, 10),
          parseInt(floorplanId, 10),
        );

      if (!isValidResponse(heatmapsForFloorplanInProject)) {
        throw new Error(
          getErrorMessageFromResponse(heatmapsForFloorplanInProject),
        );
      }

      const heatmapsResponse = await resultsApi.getHeatMapListDetailed(
        getDataFromResponse(heatmapsForFloorplanInProject).map(
          ({ heatmapid }) => heatmapid,
        ),
      );

      if (!isValidResponse(heatmapsResponse)) {
        throw new Error(getErrorMessageFromResponse(heatmapsResponse));
      }

      const heatmapsDataByScenarios = {};
      const heatmapsDataObj = getDataFromResponse(heatmapsResponse);

      for (let heatmapData of Object.values(heatmapsDataObj)) {
        const scenarioIndex =
          parseInt(heatmapData?.scenario?.scenarioindex) || 1;
        if (!heatmapsDataByScenarios[scenarioIndex]) {
          heatmapsDataByScenarios[scenarioIndex] = [];
        }
        heatmapsDataByScenarios[scenarioIndex].push(heatmapData);
      }

      setHeatmapsByScenarios(heatmapsDataByScenarios);
      dispatch(setFinishLoading());
    } catch (e) {
      dispatch(setLoadingFailed(e.message));
    }
  };

  /**
   * What to do after image loaded
   * @param w
   * @param h
   */
  const handleImageLoaded = (w, h) => {
    if (w === 1 || h === 1) {
      return;
    }
    let newScale;
    let divHeight = ref.current.clientHeight;
    let divWidth = ref.current.clientWidth;

    // Detect ration between image and div
    let ratioH = h / divHeight;
    let ratioW = w / divWidth;
    // Detect by which ratio need to scale an image
    newScale = ratioH > ratioW ? 1 / ratioH - 0.01 : 1 / ratioW - 0.015;

    newScale = newScale / 1 - 0.002;
    setElementsViewSettings(
      Object.assign({}, elementsViewSettings, {
        minScaleHeatmap: newScale,
      }),
    );

    // Overwrite mapValue
    let newMapValue = Object.assign({}, mapValue);
    newMapValue.scale = newScale;
    setMapValue(newMapValue);
  };

  const getTestResultDisplayDataFromCalculatedResult = (calculatedresult) => {
    const testResObj = calculatedresult && JSON.parse(calculatedresult);

    /** Same as in results/getVerificationProjectSummary (Project Downloads > Results > Summary) */
    let minimumPercentSP;
    let _pass;
    let _displayValue;

    if (testResObj.testValid && testResObj.spDetails) {
      const regex = /([><%])/g;
      minimumPercentSP = Object.values(testResObj.spDetails).reduce(
        (min, curr) => {
          const { displayValue, pass } = curr;

          const spPercent =
            typeof displayValue === 'string' && displayValue.replace(regex, '');
          const floatPercent = parseFloat(spPercent);

          if (!floatPercent) return min;

          if (!min || floatPercent < min) {
            min = floatPercent;
            _displayValue = displayValue;
            _pass = pass;
          }

          return min;
        },
        '',
      );
    }

    return {
      displayValue: _displayValue ? _displayValue : 'N/A',
      value: typeof minimumPercentSP === 'number' ? minimumPercentSP : 'N/A',
      isValid: testResObj.testValid,
      pass: _pass,
    };
  };

  const downloadSlide = async () => {
    setShowLabelsForPoints(false);

    const pres = createPresentation();

    const slideTitle = fileNameUtil.removeExtension(floorplanData.name);

    const scenariosNums = Object.keys(heatmapsByScenarios).sort(
      (a, b) => parseInt(a) - parseInt(b),
    );

    for (let scenarioIndex of scenariosNums) {
      updateMergedHeatmap(scenarioIndex);
      await new Promise((resolve) => setTimeout(resolve, 100));

      const heatmapsInScenario = heatmapsByScenarios[scenarioIndex];

      const earliestSurveyDate = heatmapsInScenario.reduce(
        (earliest, current) => {
          const surveyDate = new Date(current.surveydate);
          return surveyDate < earliest ? surveyDate : earliest;
        },
        heatmapsInScenario[0].surveydate,
      );

      let subtitle = `${heatmapsInScenario[0].buildingname}`;
      if (floorplanData.floorNumber) {
        subtitle += ` - Floor ${floorplanData.floorNumber}`;
      }
      let footer = `${moment(earliestSurveyDate).format(
        'DD-MM-YYYY',
      )} PID:${projectId}`;

      const slide = createTemplateSlideForPresentation(
        pres,
        slideTitle,
        subtitle,
        {
          width: PRESENTATION_DEFAULT_SIZE.w,
        },
        footer,
      );

      const testResultsObj = {};
      for (let { testid, calculatedresult, testname } of heatmapsInScenario) {
        const testResult =
          getTestResultDisplayDataFromCalculatedResult(calculatedresult);
        let eACH = '';
        if (typeof testResult.value === 'number') {
          eACH =
            parseInt(
              Math.round(
                -100 * Math.log10(1 - testResult.value / 100) * Math.log(10),
              ),
            ) / 100;
        }

        testResultsObj[projectTestDisplayNumbers[testid]] = {
          result: getCalcResultLabelFromVerLikeTest({ calculatedresult }, true),
          name: testname,
          hourlyAerosolRemoval: testResult.value,
          eACH,
        };
      }

      const testNumbers = Object.keys(testResultsObj).sort(
        (a, b) => parseInt(a) - parseInt(b),
      );
      const data = [];

      for (let testNum of testNumbers) {
        data.push([
          testResultsObj[testNum].name,
          testResultsObj[testNum].hourlyAerosolRemoval,
          testResultsObj[testNum].eACH,
          testResultsObj[testNum].result,
        ]);
      }

      slide.addTable(...createTableForVerificationHeatmapSummary(data));

      slide.addText(
        [
          {
            text: 'Comments',
            options: {
              fontSize: 16,
              color: '000000',
              align: 'left',
              breakLine: true,
              bold: true,
              autoFit: true,
            },
          },
        ],
        {
          x: 0.1,
          y: 4,
          w: PRESENTATION_DEFAULT_LEFT_MARGIN - 0.2,
          h: 2.0,
          valign: 'top',
          autoFit: true,
        },
      );

      /**
       * Add heatmap image to slide with legend if needed
       */
      const margins = PRESENTATION_DEFAULT_SIZE.w * 0.05;

      const heatmapMiddlePercent =
        PRESENTATION_DEFAULT_LEFT_MARGIN / PRESENTATION_DEFAULT_SIZE.w +
        (1 -
          margins * 2 -
          PRESENTATION_DEFAULT_LEFT_MARGIN / PRESENTATION_DEFAULT_SIZE.w) /
          2;

      const heatmap = document.getElementById('mapBorder');
      const img = await htmlToImage.toPng(heatmap, { backgroundColor: '#fff' });

      const slideImgSizeInInch = getScaledImageSize(
        { width: heatmap.clientWidth, height: heatmap.clientHeight },
        {
          width:
            PRESENTATION_DEFAULT_SIZE.w -
            PRESENTATION_DEFAULT_LEFT_MARGIN -
            margins * 2,
          height: PRESENTATION_DEFAULT_SIZE.h * 0.7,
        },
      );

      slide.addImage({
        data: img,
        x: `${
          (heatmapMiddlePercent +
            margins -
            slideImgSizeInInch.width / 2 / PRESENTATION_DEFAULT_SIZE.w) *
          100
        }%`,
        y: '25%',
        w: slideImgSizeInInch.width,
        h: slideImgSizeInInch.height,
      });
    }

    let fileName =
      projects.find(({ id }) => parseInt(id) === parseInt(projectId))?.name ||
      `PID_${projectId}`;
    fileName += ` - Verification Results.pptx`;

    pres.writeFile({ fileName });

    updateMergedHeatmap(scenarioSelected);
    setShowLabelsForPoints(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ message: '', type: alert.type ?? 'success' });
  };

  const handleCloseErrorAlert = () => {
    setMainError(null);
  };

  const handleOutHeatmap = () => {
    setDisablePan(true);
  };

  const handleOverToHeatmap = () => {
    setDisablePan(false);
  };

  const handleOnChangeMapInteraction = (value) => {
    setMapValue(value);

    if (elementsViewSettings.sliderHeatmap !== value.scale) {
      setElementsViewSettings(
        Object.assign({}, elementsViewSettings, {
          sliderHeatmap: value.scale,
        }),
      );
    }
  };

  useEffect(() => {
    if (!elementsViewSettings.sliderHeatmap) return;

    if (ref.current) {
      const { x, y } = mapValue.translation;
      const newMapValue =
        getNewMapValueOnScaleChange(
          { x, y },
          elementsViewSettings.sliderHeatmap,
          mapValue.scale,
          ref,
        ) || mapValue;
      setMapValue(newMapValue);
    }
  }, [elementsViewSettings.sliderHeatmap]);

  let heightMapBorder = '80vh';
  let widthMapBorder = '60vw';
  if (isMobile) {
    heightMapBorder = '30vh';
    widthMapBorder = '80vw';
  }

  const newHeatmap = () => {
    if (!mergedHeatmap) return;

    return (
      <div style={{ position: 'relative' }}>
        <div id={'thisHeatmap'} className="newHeatmap">
          <div style={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'nowrap',
              }}
            >
              <NewHeatmap
                activeFloorPlanUrl={floorplanData?.url}
                onImageLoaded={handleImageLoaded}
                resultsData={{
                  testtype: TEST_TYPES.VERIFICATION,
                }}
                heatMap={mergedHeatmap}
                isMergedVersion={true}
                scale={mapValue.scale}
                sliderSp={elementsViewSettings.sliderSp}
                showSpOpValue={
                  elementsViewSettings.showSpOpValue && showLabelsForPoints
                }
              />
            </Box>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {!!mainError && (
        <ErrorMessage
          message={mainError}
          handleCloseErrorAlert={handleCloseErrorAlert}
        />
      )}
      {!!isLoading && <Loading />}
      <Stack>
        <BreadcrumbsFloorplanProjectHeatmap
          buildingId={buildingId}
          floorplanId={floorplanId}
          projectId={projectId}
          floorplanName={floorplanData?.name}
          floorplanLoading={floorplanLoading}
        />
        <Snackbar
          open={alert.message !== ''}
          autoHideDuration={4500}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            severity={alert.type ?? 'success'}
            onClose={handleClose}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={8}>
            <Box
              ref={ref}
              id={'mapBorder'}
              style={{
                height: heightMapBorder,
                width: widthMapBorder,
                border: '1px solid #444',
                overflow: 'hidden',
                resize: 'both',
              }}
            >
              <Box
                onMouseOver={handleOverToHeatmap}
                onMouseOut={handleOutHeatmap}
              >
                <MapInteractionCustom
                  mapRef={mapRef}
                  value={mapValue}
                  onChange={(value) => handleOnChangeMapInteraction(value)}
                  minScale={elementsViewSettings.minScaleHeatmap}
                  maxScale={2}
                  disablePan={disablePan}
                >
                  {newHeatmap()}
                </MapInteractionCustom>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            {!!heatmapsByScenarios &&
            Object.keys(heatmapsByScenarios).length > 1 ? (
              <Box sx={{ mb: 2 }}>
                <Select sx={{ width: '240px' }} value={scenarioSelected}>
                  {Object.keys(heatmapsByScenarios)
                    .sort((a, b) => parseInt(a) - parseInt(b))
                    .map((scenarioIndex) => (
                      <MenuItem
                        key={scenarioIndex}
                        value={scenarioIndex}
                        onClick={() => setScenarioSelected(scenarioIndex)}
                      >
                        Scenario {scenarioIndex}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            ) : null}
            <ElementsControlPanel
              settings={elementsViewSettings}
              updateSettings={setElementsViewSettings}
            />
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '8px',
                  mt: 1,
                  mb: 4,
                }}
              >
                <Button
                  variant="contained"
                  onClick={downloadSlide}
                  disabled={isLoading}
                >
                  Download
                </Button>
              </Box>
              {!!mergedHeatmap &&
              !!Object.keys(mergedHeatmap.buildingData).length ? (
                <Box>
                  <Paper sx={{ mb: 2, p: 2 }}>
                    <Typography variant="overline" display="block" gutterBottom>
                      Building data
                    </Typography>
                    {mergedHeatmap.buildingData.sq_ft ? (
                      <Typography>
                        Area sq. ft: {mergedHeatmap.buildingData.sq_ft}
                      </Typography>
                    ) : null}
                    {mergedHeatmap.buildingData.celling_height ? (
                      <Typography>
                        Height ft: {mergedHeatmap.buildingData.celling_height}
                      </Typography>
                    ) : null}
                  </Paper>
                </Box>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </React.Fragment>
  );
}
