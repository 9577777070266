import {
  ERROR_AUTOCLOSE_TIMEOUT_MS,
  HEALTHCARE_TEST_TYPES,
  TEST_TYPES,
  TYPES_TEST_MAPPING,
} from 'Constants';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import samplePlans from 'Api/samplePlans';
import testsApi from 'Api/tests';

import AddBorderButton from 'Components/Buttons/AddBorderButton';
import AddTestModal from 'Components/SingleProject/Main/Forms/AddTestModal';
import { useProjectContext } from 'Components/SingleProject/context';
import { createTests } from 'Components/TestPackages/utils';

import { useIsMounted } from 'Context';

import { projectDataSelector } from 'Features/Projects/projectsSlice';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

export default function AddTestBtn() {
  const {
    testAmountChangeHandler,
    projectBuildings,
    projectHasHealthcareTests: hasHealthcare,
    projectHasVerificationTests: hasVerification,
    setMainError,
  } = useProjectContext();
  const { isProjectTestsLoading, projectId } = useSelector(projectDataSelector);
  const [modalOpen, setModalOpen] = useState(false);
  const [addInProgress, setAddInProgress] = useState(false);

  const mounted = useIsMounted();

  const addTest = async (values) => {
    setAddInProgress(true);
    let isSuccess = false;
    try {
      const tests = {};

      const testTypeMapKey = TYPES_TEST_MAPPING[values.type];
      if (!testTypeMapKey) {
        throw new Error(`Test of such type can not be found`);
      }

      tests[testTypeMapKey] = [values.name];
      tests.buildingId = values.building;
      isSuccess = await createTests(
        tests,
        projectId,
        setMainError,
        testAmountChangeHandler,
        true,
      );
      await samplePlans.removeSamplePlan(Number(projectId));
    } catch (err) {
      console.log('Add test error: ', err);
      setMainError(err?.message || `Couldn't add test`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      if (mounted.current) {
        setAddInProgress(false);
      }
      if (isSuccess && typeof testAmountChangeHandler === 'function') {
        if (mounted.current) {
          setModalOpen(false);
        }
        testAmountChangeHandler();
      }
    }
  };

  const addHealthcareTest = async (values) => {
    setAddInProgress(true);
    let isSuccess = false;

    try {
      const { type, building: strBuildingId, name, goal, overview } = values;
      const buildingId = parseInt(strBuildingId, 10);
      const intProjectId = parseInt(projectId, 10);

      if (!buildingId || !intProjectId) {
        throw new Error(`No parental resources data. Please, try again later`);
      }

      const bodyObj = {
        type,
        buildingId,
        projectId: intProjectId,
        name,
        goal,
        overview,
      };

      const response = await testsApi.createHealthcareTest(bodyObj, true);
      await samplePlans.removeSamplePlan(Number(projectId));

      if (isValidResponse(response)) {
        const { testId } = getDataFromResponse(response);

        isSuccess = !!testId;
      } else {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      console.log('Add healthcare test error: ', err);

      setMainError(err?.message || `Couldn't add test`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      if (mounted.current) {
        setAddInProgress(false);
      }
      if (isSuccess && typeof testAmountChangeHandler === 'function') {
        if (mounted.current) {
          setModalOpen(false);
        }
        testAmountChangeHandler();
      }
    }
  };

  const addUVTest = async (values) => {
    setAddInProgress(true);
    let isSuccess = false;

    try {
      const { type, building: strBuildingId, name, goal, overview } = values;
      const buildingId = parseInt(strBuildingId, 10);
      const intProjectId = parseInt(projectId, 10);

      if (!buildingId || !intProjectId) {
        throw new Error(`No parental resources data. Please, try again later`);
      }

      const bodyObj = {
        type,
        buildingId,
        projectId: intProjectId,
        name,
        goal,
        overview,
      };

      const response = await testsApi.createUVTest(bodyObj, true);
      await samplePlans.removeSamplePlan(Number(projectId));

      if (isValidResponse(response)) {
        const { testId } = getDataFromResponse(response);

        isSuccess = !!testId;
      } else {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      console.log('Add UV test error: ', err);

      setMainError(err?.message || `Couldn't add test`);
      setTimeout(() => setMainError(''), ERROR_AUTOCLOSE_TIMEOUT_MS);
    } finally {
      if (mounted.current) {
        setAddInProgress(false);
      }
      if (isSuccess && typeof testAmountChangeHandler === 'function') {
        if (mounted.current) {
          setModalOpen(false);
        }
        testAmountChangeHandler();
      }
    }
  };

  const handleAddTest = async (values) => {
    try {
      if (Object.values(HEALTHCARE_TEST_TYPES).includes(values.type)) {
        return await addHealthcareTest(values);
      } else if (values.type === TEST_TYPES.UV) {
        return await addUVTest(values);
      } else {
        return await addTest(values);
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <React.Fragment>
      <AddBorderButton
        disableStyles={true}
        text="Add test"
        clickAction={() => setModalOpen(true)}
        extraBtnProps={{
          disableFocusRipple: true,
          disabled:
            isProjectTestsLoading || addInProgress || !projectBuildings.length,
        }}
      />
      <AddTestModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleSubmit={handleAddTest}
        actionsDisabled={addInProgress}
        hasVerification={hasVerification}
        hasHealthcare={hasHealthcare}
        buildings={projectBuildings}
      />
    </React.Fragment>
  );
}
