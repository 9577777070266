import React from 'react';

import './Visualizer.css';
import sizeScalingUtil from './sizeScalingUtil';

import Typography from '@mui/material/Typography';

class AnnotatedFloorPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageFile: props.imageFile,
      imageDimensions: null,
      blobUrl: null,
    };

    this.buildSVG = this.buildSVG.bind(this);
    this.floorplanDimensions = this.floorplanDimensions.bind(this);
  }
  floorplanDimensions() {
    const d = {
      width: this.state.imageDimensions?.width,
      height: this.state.imageDimensions?.height,
    };
    return d;
  }

  buildSVG() {
    if (this.state.imageDimensions === null || this.state.imageFile === null) {
      return <div></div>;
    }

    let originPointItems = [];
    let samplePointItems = [];
    let imageProps = {};
    const toRect = {};

    const _width =
      this.floorplanDimensions().width *
      (this.props.size.height / this.floorplanDimensions().height);
    const _height =
      this.floorplanDimensions().height *
      (this.props.size.width / this.floorplanDimensions().width);

    if (_height <= this.props.size.height) {
      toRect.height = _height;
      toRect.width = this.props.size.width;
      imageProps = { width: toRect.width, height: toRect.height };
    }
    if (_width <= this.props.size.width) {
      toRect.height = this.props.size.height;
      toRect.width = _width;
      imageProps = { height: toRect.height, width: toRect.width };
    }

    const iconWidth =
      sizeScalingUtil.iconSize(this.floorplanDimensions(), toRect).width * 1.4;
    const fontSize = iconWidth * 0.6;

    originPointItems = this.props.originPoints
      .map((item, index) => {
        if (item.x !== null && item.y !== null) {
          const centeredCoordinates = sizeScalingUtil.toCoordiates(
            this.floorplanDimensions(),
            item.x !== null ? Number(item.x) : null,
            item.y !== null ? Number(item.y) : null,
            toRect,
          );
          const strokeWidth = iconWidth * 0.1;
          const scaledX = centeredCoordinates.x + strokeWidth / 4;
          const scaledY = centeredCoordinates.y + strokeWidth / 2;

          return (
            <g key={index}>
              <rect
                x={scaledX - iconWidth / 2}
                y={scaledY - (iconWidth * 0.8) / 2}
                width={iconWidth + 'px'}
                height={iconWidth * 0.8 + 'px'}
                rx={iconWidth * 0.3 + 'px'}
                ry={iconWidth * 0.3 + 'px'}
                fill="saddlebrown"
              />
              <rect
                x={scaledX - iconWidth / 2 + iconWidth * 0.1}
                y={scaledY - (iconWidth * 0.8) / 2 + iconWidth * 0.8 * 0.15}
                width={iconWidth * 0.8 + 'px'}
                height={iconWidth * 0.7 * 0.8 + 'px'}
                rx={iconWidth * 0.3 + 'px'}
                ry={iconWidth * 0.3 + 'px'}
                fill="linen"
              />
              <text
                x={scaledX}
                y={scaledY + strokeWidth / 2}
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize={fontSize}
              >
                {item.number.replace(/^[OS]P-0+/i, '').includes('::B') ||
                item.number.replace(/^[OS]P-0+/i, '').includes('::A')
                  ? item.number
                      .replace(/^[OS]P-0+/i, '')
                      .replace('::B', '')
                      .replace('::A', '')
                  : item.number.replace(/^[OS]P-0+/i, '')}
              </text>
            </g>
          );
        }

        return null;
      })
      .filter((item) => !!item);

    samplePointItems = this.props.samplePoints
      .map((item, index) => {
        if (item.x !== null && item.y !== null) {
          const centeredCoordinates = sizeScalingUtil.toCoordiates(
            this.floorplanDimensions(),
            item.x !== null ? Number(item.x) : null,
            item.y !== null ? Number(item.y) : null,
            toRect,
          );

          const strokeWidth = iconWidth * 0.1;
          const scaledX = centeredCoordinates.x + strokeWidth / 4;
          const scaledY = centeredCoordinates.y + strokeWidth / 2;
          return (
            <g key={index}>
              <rect
                x={scaledX - iconWidth / 2}
                y={scaledY - (iconWidth * 0.8) / 2}
                width={iconWidth + 'px'}
                height={iconWidth * 0.8 + 'px'}
                fill="blue"
              />
              <rect
                x={scaledX - iconWidth / 2 + iconWidth * 0.1}
                y={scaledY - (iconWidth * 0.8) / 2 + iconWidth * 0.8 * 0.15}
                width={iconWidth * 0.8 + 'px'}
                height={iconWidth * 0.7 * 0.8 + 'px'}
                fill="linen"
              />
              <text
                x={scaledX}
                y={scaledY + strokeWidth / 2}
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize={fontSize}
              >
                {item.number.replace(/^[OS]P-0+/i, '')}
              </text>
            </g>
          );
        }

        return null;
      })
      .filter((item) => !!item);

    //console.log(samplePointItems);

    if (this.state.imageFile !== null) {
      return (
        <svg
          id="svgImage"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          height={toRect.height}
          width={toRect.width}
          preserveAspectRatio="xMidYMid meet"
          onLoad={() => {
            let svgnode = document.getElementById('svgImage');
            let clonedSvgElement = svgnode.cloneNode(true);

            let image = new Image();
            image.onload = () => {
              let canvas = document.createElement('canvas');
              let ctx = canvas.getContext('2d');

              canvas.width = this.state.imageDimensions.width;
              canvas.height = this.state.imageDimensions.height;

              ctx.drawImage(image, 0, 0);

              this.setState({ blobUrl: canvas.toDataURL(`image/${'png'}`, 1) });
            };

            image.src = clonedSvgElement;
          }}
        >
          <g>
            <g>
              <image
                {...imageProps}
                href={this.state.imageFile}
                preserveAspectRatio="xMidYMid meet"
              />
            </g>

            {originPointItems}
            {samplePointItems}
          </g>
        </svg>
      );
    }
  }

  render() {
    const svgImage = this.buildSVG();

    return (
      <div>
        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Typography variant="h6">File Name: {this.props.fileName}</Typography>
        </div>
        {this.state.imageFile !== undefined &&
          this.state.imageFile !== null && (
            <img
              src={this.state.imageFile}
              alt="Working FP"
              style={{
                visibility: 'hidden',
                opacity: 0,
                width: '1px',
                height: 'auto',
              }}
              onLoad={(e) => {
                this.props.setFPLoaded();
                this.setState({
                  imageDimensions: {
                    width: e.target.naturalWidth,
                    height: e.target.naturalHeight,
                  },
                });
              }}
            />
          )}

        {this.props.heatmapVersionFP ? (
          <div id="annotated-floorplan-container">
            <div id="annotated-floorplan-container--logo" />
            <div id="annotated-floorplan-container--img">{svgImage}</div>
          </div>
        ) : (
          svgImage
        )}
      </div>
    );
  }
}

export default AnnotatedFloorPlan;
