import {
  HEALTHCARE_TEST_TYPES,
  PROJECT_STATUSES,
  PROJECT_STATUS_WORKFLOW,
} from 'Constants';
import React from 'react';

import {
  DeleteScenarioButton,
  EditScenarioCharacteristicButton,
  EditScenarioSettingsButton,
} from './Buttons';
import { SCENARIOS_UV_DATA_CONFIG_TABLE } from './config';
import { useTestScenariosContext } from './context';

import {
  Checkbox,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';

import { isSafetracesAdmin } from 'Config/roles';

import {
  StyledTableCell,
  StyledTableRow,
  StyledTestTableCell,
} from 'Layout/layoutStyles';

export default function ScenariosTableUV({
  insideCollapsibleTable = false,
  editDisabled,
}) {
  const semiHeadersTextStyle = {
    fontVariant: 'all-petite-caps',
    fontWeight: 'bolder',
    fontSize: '1.2em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(0, 0, 0, 0.6)',
  };
  const contextData = useTestScenariosContext();
  const {
    initialLoad,
    scenariosData,
    userPermissionsConfigIdentifier,
    forceRestrictAllEdits,
    currentTestType,
    setScenarioExecuted,
    projectStatus,
  } = contextData;

  const isScenarioCantBeSetToExecuted = (scenario) => {
    if (currentTestType === HEALTHCARE_TEST_TYPES.POSITIVE) {
      if (!scenario.opGroupA || !scenario.opGroupB) {
        return true;
      }
    } else {
      if (!scenario.opGroupA) {
        return true;
      }
    }
    return false;
  };

  const overrideEditPermissionSafetracesAdminIgroneWorkflow =
    isSafetracesAdmin();

  if (initialLoad) {
    return (
      <Stack>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Stack>
    );
  }

  if (!Object.keys(scenariosData).length) {
    return null;
  }
  return (
    <TableContainer
      component={insideCollapsibleTable ? 'div' : Paper}
      sx={{ overflowX: 'scroll' }}
    >
      <Table aria-label="scenarios-table-v2" size="small ">
        <TableHead>
          <StyledTableRow border="initial">
            <StyledTableCell widthcol={2} headertype="secondary" />
            {Object.values(scenariosData).map(
              ({ internalName, displayName, scenarioId }) => (
                <StyledTestTableCell
                  widthcol={4}
                  headertype="secondary"
                  key={`scenario-${scenarioId}`}
                  align="left"
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      style={{
                        textTransform: 'capitalize',
                        ...semiHeadersTextStyle,
                        fontSize: '1.3em',
                      }}
                      variant="subtitle2"
                    >
                      {displayName}
                    </Typography>
                    <EditScenarioSettingsButton scenarioId={scenarioId} />
                    {!['uvOn', 'uvOff'].includes(internalName) && (
                      <DeleteScenarioButton
                        scenarioId={scenarioId}
                        disabled={
                          PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) >=
                          PROJECT_STATUS_WORKFLOW.indexOf(
                            PROJECT_STATUSES.EXECUTION_READY,
                          )
                        }
                      />
                    )}
                  </Stack>
                </StyledTestTableCell>
              ),
            )}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {Object.values(SCENARIOS_UV_DATA_CONFIG_TABLE).map((rowConfig) => {
            if (
              rowConfig.allowedTestTypes &&
              !rowConfig.allowedTestTypes.includes(currentTestType)
            )
              return null;

            return (
              <React.Fragment key={rowConfig.key}>
                {!insideCollapsibleTable ||
                (insideCollapsibleTable &&
                  rowConfig.presentInCollapsibleTable) ? (
                  <StyledTableRow
                    coloring="even"
                    sx={{ '& > *': { borderBottom: 'unset' } }}
                  >
                    <StyledTableCell>
                      <Typography variant="subtitle2" sx={semiHeadersTextStyle}>
                        {typeof rowConfig.label === 'object'
                          ? rowConfig.label[currentTestType] || rowConfig.label
                          : rowConfig.label}
                      </Typography>
                    </StyledTableCell>
                    {Object.values(scenariosData).map((scenario) => (
                      <StyledTableCell
                        key={`${scenario.scenarioId}-${rowConfig.key}`}
                        align={rowConfig.align}
                      >
                        <Stack direction="row" alignItems="center">
                          {rowConfig.key !== 'scenarioExecuted' ? (
                            <Typography
                              variant="subtitle2"
                              sx={{ textTransform: 'capitalize' }}
                            >
                              {scenario[rowConfig.key]}
                            </Typography>
                          ) : null}
                          {(overrideEditPermissionSafetracesAdminIgroneWorkflow &&
                            rowConfig.presentInRestrictedForm) ||
                          (!forceRestrictAllEdits &&
                            !editDisabled &&
                            rowConfig.editable &&
                            rowConfig.editPermission.includes(
                              userPermissionsConfigIdentifier,
                            )) ? (
                            <EditScenarioCharacteristicButton
                              sx={{ ml: 1 }}
                              scenarioId={scenario.scenarioId}
                              field={rowConfig.key}
                              restrictedEdit={
                                !(
                                  !forceRestrictAllEdits &&
                                  !editDisabled &&
                                  rowConfig.editable &&
                                  rowConfig.editPermission.includes(
                                    userPermissionsConfigIdentifier,
                                  )
                                ) &&
                                overrideEditPermissionSafetracesAdminIgroneWorkflow &&
                                rowConfig.presentInRestrictedForm
                              }
                            />
                          ) : null}
                          {rowConfig.key === 'scenarioExecuted' ? (
                            <Checkbox
                              size="small"
                              checked={scenario[rowConfig.key]}
                              onChange={(e) =>
                                setScenarioExecuted(
                                  scenario.scenarioId,
                                  e.target.checked,
                                )
                              }
                              disabled={
                                isScenarioCantBeSetToExecuted(scenario) ||
                                PROJECT_STATUS_WORKFLOW.indexOf(projectStatus) <
                                  PROJECT_STATUS_WORKFLOW.indexOf(
                                    PROJECT_STATUSES.EXECUTION_READY,
                                  ) ||
                                PROJECT_STATUS_WORKFLOW.indexOf(
                                  projectStatus,
                                ) >=
                                  PROJECT_STATUS_WORKFLOW.indexOf(
                                    PROJECT_STATUSES.PUBLISHED,
                                  )
                              }
                            />
                          ) : null}
                        </Stack>
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ) : null}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
