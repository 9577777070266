import { UNMOUNT_IGNORED_ERR_MSG } from 'Constants';
import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import './styles.css';

import { Box, Stack } from '@mui/material';

import originpoints from 'Api/originpoints';

import DownloadButton from 'Components/Buttons/DownloadButton';

import { useIsMounted } from 'Context';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  getLabelsDimensions,
  getLabelsInnerContainerConfig,
  isValidResponse,
} from 'Utils';

const SingleOPLabel = React.memo(
  ({
    logo,
    projectId,
    projectName,
    opNumber,
    surveyDate,
    testNumber,
    scenarioText,
    width = 288,
    height = 192,
    position,
  }) => {
    const warningText = `Do not remove`;

    return (
      <Stack
        className="opLabel noselect"
        style={{
          width: `${width}px`,
          height: `${height}px`,
          position: 'absolute',
          top: position?.top,
          left: position?.left,
        }}
        direction="column"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className="opLabel-logo">{logo}</Box>
          <Box className="opLabel-opNumber">{opNumber}</Box>
        </Stack>
        <div className="opLabel-divider" />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack className="opLabel-secondRow--details" direction="column">
            <Box>Test: {testNumber}</Box>
            <Box>Scenario:</Box>
            <Box>{scenarioText}</Box>
          </Stack>
          <Box className="opLabel-secondRow--warning">{warningText}</Box>
        </Stack>
        <div className="opLabel-divider" />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box className="opLabel-thirdRow--projectInfo">
            {projectId}: {projectName}
          </Box>
          <Box className="opLabel-thirdRow--date">{surveyDate}</Box>
        </Stack>
      </Stack>
    );
  },
);

const OPLabelsForPrint = React.forwardRef(
  (
    { data, projectId, projectName, paperWidth = 816, paperHeight = 1056 },
    ref,
  ) => {
    const paperPadding = {
      top: 24,
      left: 114,
      bottom: 24,
      right: 114,
    };
    const labelsSpacing = {
      vertical: 12,
      horizontal: 12,
    };
    const labelWidth = 288;
    const labelHeight = 192;

    const LogoImg = useMemo(() => {
      return <img src="/SafeTraces_Logo.png" alt="logo"></img>;
    }, []);

    const labelsDimensionsConfig = useMemo(
      () =>
        getLabelsDimensions(
          paperWidth,
          paperHeight,
          paperPadding,
          labelsSpacing,
          data?.length,
          labelWidth,
          labelHeight,
        ),
      [paperWidth, paperHeight],
    );

    const innerContainerConfig = useMemo(
      () =>
        getLabelsInnerContainerConfig(
          paperWidth,
          paperPadding,
          labelWidth,
          labelsSpacing,
        ),
      [paperWidth, paperHeight],
    );

    return (
      <div
        style={{
          width: paperWidth,
          height: paperHeight,
          padding: paperPadding,
        }}
        ref={ref}
      >
        <div
          className="opLabels-print-container"
          style={{
            left: innerContainerConfig?.left,
            top: innerContainerConfig?.top,
            width: innerContainerConfig?.width,
          }}
        >
          {data.map(({ opNumber, surveyDate, testNumber, scenarioText }, i) => (
            <SingleOPLabel
              key={i}
              logo={LogoImg}
              projectName={projectName}
              projectId={projectId}
              opNumber={opNumber}
              surveyDate={surveyDate}
              testNumber={testNumber}
              scenarioText={scenarioText}
              width={labelWidth}
              height={labelHeight}
              position={labelsDimensionsConfig[i]}
            />
          ))}
        </div>
      </div>
    );
  },
);

function OriginPointsLabelsPrint({ projectId, projectName, hasOP, onError }) {
  const mounted = useIsMounted();

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [labelsData, setLabelsData] = useState(null);

  const labelsRef = useRef();

  useEffect(() => {
    const source = axios.CancelToken.source();

    getLabels(source);

    return () => {
      source.cancel(UNMOUNT_IGNORED_ERR_MSG);
    };
  }, []);

  const getLabels = async (source) => {
    if (!projectId) return;

    setDownloadInProgress(true);

    try {
      const response = await originpoints.getLabelsForProject(
        projectId,
        source,
      );

      if (isValidResponse(response)) {
        const labelsData = getDataFromResponse(response);
        const testLabelsData = labelsData.reduce((acc, op) => {
          const { testNumber, segmentid, opnumber } = op;
          if (!acc[testNumber]) {
            acc[testNumber] = {};
          }
          if (!acc[testNumber][segmentid]) {
            acc[testNumber][segmentid] = {};
          }

          let opNum = opnumber;
          if (!!~opnumber.indexOf('::A') || !!~opnumber.indexOf('::B')) {
            opNum = opNum.slice(0, -3);
          }
          if (!acc[testNumber][segmentid][opNum]) {
            acc[testNumber][segmentid][opNum] = op;
          } else {
            const mergedForFormattingTagObj = {};
            for (let scenarioName of Object.keys(
              acc[testNumber][segmentid][opNum].tags,
            )) {
              mergedForFormattingTagObj[scenarioName] = `${
                acc[testNumber][segmentid][opNum].tags[scenarioName]
              },${op.tags[scenarioName].replace(`Tag-`, ``)}`;
            }
            acc[testNumber][segmentid][opNum].tags = mergedForFormattingTagObj;
            acc[testNumber][segmentid][opNum].opnumber = acc[testNumber][
              segmentid
            ][opNum].opnumber.slice(0, -3);
          }

          return acc;
        }, {});

        const labelsDataAfterMerge = [];
        for (let segmentObj of Object.values(testLabelsData)) {
          for (let OPs of Object.values(segmentObj)) {
            labelsDataAfterMerge.push(...Object.values(OPs));
          }
        }

        labelsDataAfterMerge.sort((a, b) => a.testNumber - b.testNumber);
        console.log(labelsDataAfterMerge, 'labelsDataAfterMerge');

        const labels = formatLabelsData(labelsDataAfterMerge);
        if (mounted.current) {
          setLabelsData(labels);
          setDownloadInProgress(false);
        }
      } else {
        throw new Error(getErrorMessageFromResponse(response));
      }
    } catch (err) {
      if (err.message === UNMOUNT_IGNORED_ERR_MSG) return;

      console.error(`getLabels Error: `, err);
      if (mounted.current) {
        setLabelsData(null);
        setDownloadInProgress(false);
      }
      if (typeof onError === 'function') {
        onError(err);
      }
    }
  };

  const formatLabelsData = (labels) => {
    if (!Array.isArray(labels) || !labels.length) return [];

    const formattedLabels = labels.map((label) => {
      const scenarioTextForTags = !!label.tags
        ? Object.values(label.tags)
            .map((tag, i) => {
              const shortTag = tag.replace(`Tag-`, ``);
              return `${i + 1}:${shortTag}`;
            })
            .join(', ')
        : ``;

      const _label = {
        opNumber: label.opnumber,
        surveyDate: label.surveydate,
        testNumber: label.testNumber,
        scenarioText: scenarioTextForTags,
      };

      return _label;
    });

    return formattedLabels;
  };

  const handlePrint = useReactToPrint({
    content: () => labelsRef.current,
    documentTitle: `${projectName}-OP-Labels`,
  });

  const PrintButton = React.useMemo(() => {
    return (
      <DownloadButton
        text="Origin Points"
        disabled={!hasOP || downloadInProgress || !labelsData}
        loading={downloadInProgress}
        tooltipText={
          !hasOP
            ? `The project does not have origin points`
            : downloadInProgress
            ? `Data for the labels is loading`
            : ''
        }
        icon="print"
        clickAction={handlePrint}
        sx={{ width: '180px' }}
      />
    );
  }, [hasOP, downloadInProgress, labelsData]);

  return (
    <Stack direction="column">
      {PrintButton}
      {!!labelsData && (
        <div style={{ display: 'none' }}>
          <OPLabelsForPrint
            ref={labelsRef}
            data={labelsData}
            projectId={projectId}
            projectName={projectName}
            paperWidth={816}
            paperHeight={1056}
          />
        </div>
      )}
    </Stack>
  );
}

export default React.memo(OriginPointsLabelsPrint);
