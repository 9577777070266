import axios from 'axios';
import mergeImages from 'merge-images';
import React from 'react';
import Cropper from 'react-cropper';

import FloorPlanTemplateImage from '../Template-for-Floor-Plan.png';
import { fileNameUtil, getMergePosition, getScaledImageSize } from '../utils';
import RenamePanel from './RenamePanel';
import Resizer from './Resizer';
import './cropper.css';

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';

import floorPlansApi from 'Api/floorPlans';

import {
  getDataFromResponse,
  getErrorMessageFromResponse,
  isValidResponse,
} from 'Utils';

const classes = {
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.8)',
    height: '100%',
    width: '100%',
    // Default modal z-index
    zIndex: 1300,
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#fff',
    width: '80%',
    height: '80%',
    padding: '20px',
  },
  modalTitle: {
    width: '100%',
    height: '60px',
  },
  modalBody: {
    width: '100%',
    height: 'calc(100% - 150px)',
    maxHeight: 'calc(100% - 150px)',
    marginTop: '20px',
    backgroundImage:
      'linear-gradient(45deg, #EEEEEE 25%, transparent 25%), linear-gradient(-45deg, #EEEEEE 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #EEEEEE 75%), linear-gradient(-45deg, transparent 75%, #EEEEEE 75%)',
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
    overflow: 'hidden',
    overflowY: 'auto',
  },
  testAreaModalBody: {
    width: '100%',
    height: 'calc(100% - 150px)',
    maxHeight: 'calc(100% - 150px)',
    marginTop: '20px',
    overflow: 'hidden',
  },
  testAreaCropperBody: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    backgroundImage:
      'linear-gradient(45deg, #EEEEEE 25%, transparent 25%), linear-gradient(-45deg, #EEEEEE 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #EEEEEE 75%), linear-gradient(-45deg, transparent 75%, #EEEEEE 75%)',
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
    overflow: 'hidden',
  },
  modalActions: {
    width: '100%',
    height: '40px',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  isHidden: {
    visibility: 'hidden',
    opacity: 0,
    width: '100%',
  },
  isVisible: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.7s linear',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};
class FloorPlanCropperDialog extends React.Component {
  constructor(props) {
    super(props);

    const isTestArea = props.type === 'testArea';

    const validFile =
      !!props.imageFile &&
      (isTestArea ||
        ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          props.imageFile?.type,
        ));

    let defaultView = 'details';
    if (props.type === 'testArea') {
      defaultView = 'crop'; // todo: change to crop
    }
    if (['preview', 'crop'].includes(defaultView) && !validFile) {
      defaultView = 'error';
    }

    this.state = {
      activeActivity: defaultView,
      isTestArea,
      testAreaCropData: {},
      selectedImageFile: props.imageFile,
      selectedImage: null,
      croppedImage: null,
      croppedImageDimensions: null,
      saveFileName: props.imageFile?.name,
      tempSaveFileName: props.imageFile?.name,
      detailsAddress: '',
      detailsFloorNumber: '',
      detailsBuildingId: props.buildingId,
      modalBodyDimensions: null,
      cropperDimensions: null,
      cropperToolBarDimensions: null,
      cropper: null,
      workingFileVisible: true,
      workingFileDimensions: null,
      templateFileDimensions: null,
      imageViewDimensions: null,
      mergedImageVisible: isTestArea,
      previewImage: null,
      workingImage: null,
      scaledWorkingImage: null,
      mergedImage: FloorPlanTemplateImage,
      isRenameDoneDisabled: false,
      isCropDisabled: true,
      isUploadDisabled: true,
      percentUploadComplete: 0,
      showBuildingRequiredNotice: false,
      hdaList: props.hdaList,
      hdaActiveList: {},
    };

    this.imageContainerDivRef = React.createRef();
    this.cropperContainerDivRef = React.createRef();
    this.cropperToolBarContainerDivRef = React.createRef();
    this.modalBodyDivRef = React.createRef();
  }

  async componentDidMount() {
    // load user selected image here
    const projectId = this.props?.projectId;
    const buildingId = this.props?.buildingId;

    const response = projectId
      ? await floorPlansApi.getFloorPlans(projectId)
      : await floorPlansApi.getFloorPlansForBuilding(buildingId);
    if (isValidResponse(response)) {
      this.setState(
        {
          floorPlanFiles: getDataFromResponse(response),
        },
        () => {
          this.loadFile();
        },
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    switch (this.state.activeActivity) {
      case 'preview':
        this.componentDidUpdatePreview(prevProps, prevState);
        break;
      case 'crop':
        this.componentDidUpdateCrop(prevProps, prevState);
        break;
      case 'rename':
        break;
      case 'details':
        break;
      case 'upload':
        break;
      default:
        break;
    }
  }

  componentDidUpdatePreview() {
    if (this.imageContainerRef() !== null) {
      const rect = this.imageContainerRef().getBoundingClientRect();

      const imageDimensions = this.scaleToFitDimensions(
        rect,
        this.state.templateFileDimensions,
      );
      if (
        imageDimensions !== null &&
        (this.state.imageViewDimensions === null ||
          this.state.imageViewDimensions.width - imageDimensions > 20)
      ) {
        this.setState({ imageViewDimensions: imageDimensions });
      }
    }
  }

  componentDidUpdateCrop() {
    // crop view is now being displayed

    // need to calculate the cropper height
    // modal body - tool bar height

    if (this.modalBodyRef() !== null) {
      const rect = this.modalBodyRef().getBoundingClientRect();
      //console.log(rect);

      if (
        this.state.modalBodyDimensions === null ||
        this.state.modalBodyDimensions.height !== rect.height ||
        this.state.modalBodyDimensions.width !== rect.width
      ) {
        this.setState(
          { modalBodyDimensions: { width: rect.width, height: rect.height } },
          () => {
            if (this.cropperToolBarContainerRef() !== null) {
              const rect =
                this.cropperToolBarContainerRef().getBoundingClientRect();

              if (
                this.state.cropperToolBarDimensions === null ||
                this.state.cropperToolBarDimensions.width !== rect.width ||
                this.state.cropperToolBarDimensions.height !== rect.height
              ) {
                this.setState({ cropperToolBarDimensions: rect }, () => {
                  if (
                    this.state.modalBodyDimensions !== null &&
                    this.state.cropperToolBarDimensions !== null
                  ) {
                    const editPanelDimensions = {
                      width: this.state.modalBodyDimensions.width,
                      height:
                        this.state.modalBodyDimensions.height -
                        this.state.cropperToolBarDimensions.height,
                    };

                    if (
                      this.state.cropperDimensions === null ||
                      this.state.cropperDimensions.width !==
                        editPanelDimensions.width ||
                      this.state.cropperDimensions.height !==
                        editPanelDimensions.height
                    ) {
                      this.setState({ cropperDimensions: editPanelDimensions });
                    }
                  }
                });
              }
            }
          },
        );
      }
    }
  }

  getCropData = () => {
    if (this.state.cropper !== null) {
      const cropData = this.state.cropper.getData();
      const dataURL = this.state.cropper
        .getCroppedCanvas()
        .toDataURL('image/png', 1.0);
      let image = new Image();
      image.onload = () => {
        this.setState(
          {
            croppedImage: image.src,
            testAreaCropData: cropData,
            croppedImageDimensions: {
              width: image.width,
              height: image.height,
            },
          },
          () => {
            this.mergeFloorPlanIntoTemplate();
            this.setState({
              activeActivity: 'preview',
            });
          },
        );
      };
      image.src = dataURL;
    }
  };

  mergeFloorPlanIntoTemplate() {
    const { isTestArea } = this.state;

    this.setState({ uploadDisabled: true });
    try {
      const size = getScaledImageSize(this.state.croppedImageDimensions);

      let image = new Image();

      image.src = this.state.croppedImage;

      let resizedDataUrl = Resizer.resizeAndRotateImage(
        image, //image,
        size.width, //maxWidth,
        size.height, //maxHeight,
        size.width, //minWidth,
        size.height, //minHeight,
        'PNG', //compressFormat,
        100, //quality,
        0, //rotation
      );

      const contentType = `image/PNG`;
      const blob = Resizer.b64toBlob(resizedDataUrl, contentType);
      const offset = getMergePosition(size);

      const imagesToMerge = [
        {
          src: URL.createObjectURL(blob),
          x: isTestArea ? 0 : offset.x,
          y: isTestArea ? 0 : offset.y,
          opacity: 1,
        },
      ];

      if (!isTestArea) {
        imagesToMerge.unshift({ src: FloorPlanTemplateImage, x: 0, y: 0 });
      }

      mergeImages(imagesToMerge, { crossOrigin: 'anonymous' }).then((b64) => {
        this.setState({
          mergedImage: b64,
          previewImage: b64,
          isUploadDisabled: false,
        });
      });

      this.setState({ scaledWorkingImage: URL.createObjectURL(blob) });
    } catch (err) {
      console.log(err);
    }
  }

  getImageForHeatmapSave = async (i = 0) => {
    const result = {
      success: false,
      heatmapFP: null,
    };
    try {
      /**
       * 1. Set size of final image container
       */
      const width = 2798;
      const height = 1942;

      /**
       * 2. Get new size of image so it would fit container. Resize the cropped image.
       */
      let _image = new Image();

      _image.src = this.state.croppedImage;

      // const image = this.removeHalfImageBlanks(_image);
      const image = _image;

      const size = getScaledImageSize(
        { width: image.width, height: image.height },
        { width, height },
      );

      const resizedDataUrl = Resizer.resizeAndRotateImage(
        image, //image,
        size.width, //maxWidth,
        size.height, //maxHeight,
        size.width, //minWidth,
        size.height, //minHeight,
        'PNG', //compressFormat,
        100, //quality,
        0, //rotation
      );
      /**
       * 3. Create canvas and fill it with color. It would be our background.
       */
      const background = document.createElement('canvas');

      background.width = width;
      background.height = height;

      const ctx = background.getContext('2d');
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, width, height);
      /**
       * 4. Calculate offset for the cropped FP so that the FP would be centered in the final picture.
       */
      const offset = {
        x: (width - size.width) / 2,
        y: (height - size.height) / 2,
      };

      const blob = Resizer.b64toBlob(resizedDataUrl, 'image/PNG');
      /**
       * 5. Merge single-color background with the cropped & resized floorplan.
       */
      const finalImage = await mergeImages(
        [
          { src: background.toDataURL('image/png'), x: 0, y: 0 },
          {
            src: URL.createObjectURL(blob),
            x: offset.x,
            y: offset.y,
            opacity: 1,
          },
        ],
        {
          crossOrigin: 'anonymous',
        },
      );

      result.success = true;
      result.heatmapFP = finalImage;

      return result;
    } catch (err) {
      /**
       * Sometimes, if the upload button were clicked too fast, this function
       * would complain on the 0 height of the image.
       * So try 3 times and then if nothing had changed return falsy result.
       *
       * Delete the condition below to see initial error.
       */
      if (i) {
        console.log('getImageForHeatmapSave error: ', i, err);
      }
      if (i < 3) {
        const j = i + 1;
        return new Promise((resolve) => setTimeout(resolve, 200)).then(() =>
          this.getImageForHeatmapSave(j),
        );
      } else {
        return result;
      }
    }
  };

  removeHalfImageBlanks = (imageObject) => {
    const { width, height } = imageObject;

    /** Canvas with original image */
    const canvas = document.createElement('canvas');

    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);

    const context = canvas.getContext('2d');
    context.drawImage(imageObject, 0, 0);

    /** Second canvas with halved image (all data present, just different size) */
    const oc = document.createElement('canvas');
    const octx = oc.getContext('2d');

    const halfWidth = Math.round(width * 0.5);
    const halfHeight = Math.round(height * 0.5);

    oc.setAttribute('width', halfWidth);
    oc.setAttribute('height', halfHeight);

    octx.drawImage(imageObject, 0, 0, halfWidth, halfHeight);

    /**
     * Amount of non-white pixels to alert something is here
     */
    const cropThreshold = 1;
    /**
     * get data for the whole image
     */
    const imageData = octx.getImageData(0, 0, halfWidth, halfHeight);
    const data = imageData.data;

    const getRBG = (x, y) => {
      const offset = halfWidth * y + x;
      return {
        red: data[offset * 4],
        green: data[offset * 4 + 1],
        blue: data[offset * 4 + 2],
        opacity: data[offset * 4 + 3],
      };
    };

    const isWhite = (rgb) => {
      // many images contain noise, as the white is not a pure #fff white
      const thresholdValue = 200;
      return (
        rgb.opacity === 0 ||
        (rgb.red > thresholdValue &&
          rgb.green > thresholdValue &&
          rgb.blue > thresholdValue)
      );
    };

    const scanWhiteSpaces = (direction, fromStart) => {
      const compareValueY = direction === 'height' ? halfHeight : halfWidth;
      const compareValueX = direction === 'height' ? halfWidth : halfHeight;

      let result = null; // All image is white

      if (fromStart) {
        for (let y = 0; y < compareValueY; y += 1) {
          let count = 0;
          for (let x = 0; x < compareValueX; x++) {
            const values = direction === 'height' ? [x, y] : [y, x];
            const rgb = getRBG(...values);
            if (!isWhite(rgb)) {
              count++;
              if (count > cropThreshold) {
                result = y * 2;
                break;
              }
            }
          }
          if (count > cropThreshold) break;
        }
      } else {
        for (let y = compareValueY - 1; y > -1; y -= 1) {
          let count = 0;
          for (let x = 0; x < compareValueX; x++) {
            const values = direction === 'height' ? [x, y] : [y, x];
            const rgb = getRBG(...values);
            if (!isWhite(rgb)) {
              count++;
              if (count > cropThreshold) {
                result = Math.min(
                  (y + 1) * 2,
                  direction === 'height' ? height : width,
                );
                break;
              }
            }
          }
          if (count > cropThreshold) break;
        }
      }
      return result;
    };

    const scanBlanks = (direction) => {
      const blanks = [];

      const lastPoint = direction === 'height' ? halfHeight : halfWidth;

      let current = 0;
      let lastIsWhite = false;
      let lastIsBlack = false;
      let lastBlack = 0;
      for (let y = 0; y < lastPoint; y++) {
        let count = 0;
        for (let x = 0; x < lastPoint; x++) {
          const rgb = getRBG(
            direction !== 'height' ? y : x,
            direction === 'height' ? y : x,
          );
          if (!isWhite(rgb)) {
            count++;
          }
        }
        if (count > 1 && lastIsWhite) {
          blanks.push([current > 0 ? current * 2 - 1 : 0, (y - 1) * 2 - 1]);
          current = y;
        }
        if (y === lastPoint - cropThreshold && lastIsWhite) {
          blanks.push([(lastBlack + 1) * 2 - 1, y * 2 - 1]);
          current = y;
        }
        lastIsWhite = count <= cropThreshold;
        lastIsBlack = !lastIsWhite;
        if (lastIsBlack) {
          lastBlack = y;
        }
      }

      return blanks; // all image is white
    };

    const crop = {};

    crop['Top'] = scanWhiteSpaces('height', true);
    crop['Bottom'] = scanWhiteSpaces('height', false);
    crop['Left'] = scanWhiteSpaces('width', true);
    crop['Right'] = scanWhiteSpaces('width', false);

    crop['Width'] = crop['Right'] - crop['Left'];
    crop['Height'] = crop['Bottom'] - crop['Top'];

    crop['BlanksTop'] = scanBlanks('height');
    crop['BlanksLeft'] = scanBlanks('width');

    const getCropPoints = (crop) => {
      const halfHeight = height / 2;
      const halfWidth = width / 2;

      const points = {
        startX: 0,
        startY: 0,
        finishX: 999999,
        finishY: 999999,
      };

      if (crop['BlanksTop'].length > 0) {
        crop['BlanksTop'].forEach((value) => {
          if (value[1] < halfHeight) {
            points.startY = value[1];
          }
          if (value[0] > halfHeight && points.finishY === 0) {
            points.finishY = value[0];
          }
        });
      } else {
        points.startY = 0;
        points.finishY = height;
      }

      if (crop['BlanksLeft'].length > 0) {
        crop['BlanksLeft'].forEach((value) => {
          if (value[1] < halfWidth) {
            points.startX = value[1];
          }
          if (value[0] > halfWidth && points.finishX === 0) {
            points.finishX = value[0];
          }
        });
      } else {
        points.startX = 0;
        points.finishX = width;
      }

      if (points.startY < crop.Top) {
        points.startY = crop.Top;
      }
      if (points.finishY > crop.Bottom) {
        points.finishY = crop.Bottom;
      }

      if (points.startX < crop.Left) {
        points.startX = crop.Left;
      }
      if (points.finishX > crop.Right) {
        points.finishX = crop.Right;
      }
      points.width = points.finishX - points.startX;
      points.height = points.finishY - points.startY;

      return points;
    };

    const points = getCropPoints(crop);

    // finally crop the guy
    canvas.setAttribute('width', points.width);
    canvas.setAttribute('height', points.height);
    canvas
      .getContext('2d')
      .drawImage(
        imageObject,
        points.startX,
        points.startY,
        points.width,
        points.height,
        0,
        0,
        points.width,
        points.height,
      );

    return canvas;
  };

  modalBodyRef() {
    if (this.modalBodyDivRef === undefined || this.modalBodyDivRef === null) {
      return null;
    }
    return this.modalBodyDivRef.current;
  }

  imageContainerRef() {
    if (
      this.imageContainerDivRef === undefined ||
      this.imageContainerDivRef === null
    ) {
      return null;
    }
    return this.imageContainerDivRef.current;
  }

  cropperContainerRef() {
    if (
      this.cropperContainerDivRef === undefined ||
      this.cropperContainerDivRef === null
    ) {
      return null;
    }
    return this.cropperContainerDivRef.current;
  }

  cropperToolBarContainerRef() {
    if (
      this.cropperToolBarContainerDivRef === undefined ||
      this.cropperToolBarContainerDivRef === null
    ) {
      return null;
    }
    return this.cropperToolBarContainerDivRef.current;
  }

  loadFile() {
    const { isTestArea } = this.state;

    const reader = new FileReader();

    reader.onload = (e) => {
      const name = isTestArea
        ? ``
        : fileNameUtil.appendExtensionToFileName(
            fileNameUtil.appendSuffixToFileName(
              fileNameUtil.removeExtension(this.state.saveFileName),
              this.state.floorPlanFiles,
            ),
          );

      this.setState({
        selectedImage: e.target.result,
        imageForTestAreaWithHda: e.target.result,
        croppedImage: e.target.result,
        previewImage: e.target.result,
        saveFileName: name,
        isCropDisabled: false,
      });
    };

    reader.readAsDataURL(
      this.state.selectedImageFile?.convertedFile ??
        this.state.selectedImageFile,
    );
  }

  scaleToFitDimensions(containerRect, objectDimensions) {
    if (this.state.templateFileDimensions === null) {
      return null;
    }

    return getScaledImageSize(objectDimensions, containerRect);
  }

  blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  /**
   * Change temporary floor details if one of fields in floor modal editor was changed
   * @param field
   * @param value
   */
  handleChange(field, value) {
    switch (field) {
      case 'address':
        this.setState({
          detailsAddress: value.target.value ?? '',
        });
        break;
      case 'floorNumber':
        this.setState({
          detailsFloorNumber: value.target.value ?? '',
        });
        break;
      case 'buildingId':
        this.setState({
          detailsBuildingId: value.target.value ?? '',
        });
        break;
      case 'fileName':
        this.setState({
          saveFileName: value.target.value
            ? value.target.value + '.' + this.state.saveFileName?.split('.')[1]
            : this.state.saveFileName,
        });
        break;
      default:
        break;
    }
  }

  dataURLtoFile(dataurl, filename) {
    //https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  async createTestArea() {
    try {
      this.setState({
        isUploadDisabled: true,
      });

      const {
        saveFileName,
        detailsAddress,
        detailsFloorNumber,
        testAreaCropData,
        hdaActiveList,
      } = this.state;
      const { mainFpId, mainFpName, floorPlanFiles, onUploadCompleted } =
        this.props;

      if (!mainFpId) {
        throw new Error();
      }

      let name = saveFileName ? fileNameUtil.removeExtension(saveFileName) : ``;
      let mainFpNameNoExtemsion = !!mainFpName
        ? fileNameUtil.removeExtension(mainFpName)
        : ``;

      if (!name) {
        if (!mainFpNameNoExtemsion) {
          throw new Error();
        }
        name = `${mainFpNameNoExtemsion} - Test Area`;

        let i = 1;
        while (fileNameUtil.isFileNameAlreadyUsed(name, floorPlanFiles)) {
          name = `${mainFpNameNoExtemsion} - Test Area (${i})`;
          i++;
        }
      }

      const bodyObj = {
        fileName: name,
        cropData: testAreaCropData,
        hdaList: Object.values(hdaActiveList),
        floorNumber: detailsFloorNumber ?? null,
        address: detailsAddress ?? null,
      };

      const response = await floorPlansApi.createTestArea(mainFpId, bodyObj);
      if (isValidResponse(response)) {
        this.setState({
          isUploadDisabled: false,
        });
        if (typeof onUploadCompleted === 'function') {
          onUploadCompleted();
        }
        return true;
      } else throw new Error(getErrorMessageFromResponse(response));
    } catch (err) {
      this.setState({
        isUploadDisabled: false,
      });
      console.log(`Create Test Area Error: `, err);
      return false;
    }
  }

  async uploadFile() {
    const { isTestArea } = this.state;

    //todo: add error handling for projectId == null
    const projectId = this.props?.projectId;
    const buildingId = this.props?.buildingId;

    if (isTestArea) {
      return await this.createTestArea();
    }
    this.setState({
      isUploadDisabled: true,
    });
    // Check if same filename exist in current list of files
    // Step 1: Get pre-signed url by sending axios request to server
    const fileInfo = {
      buildingId: buildingId ?? this.state.detailsBuildingId ?? undefined,
      filename: this.state.saveFileName,
      filetype: 'image/png',
      action: 'putObject',
    };

    const result = await floorPlansApi.getPresignedURL(fileInfo);

    const dataForHeatmaps = {
      ...fileInfo,
      heatmapVersion: 1,
    };

    const resultHeatmaps = await floorPlansApi.getPresignedURL(dataForHeatmaps);

    const { success, heatmapFP } = await this.getImageForHeatmapSave();
    if (!success) {
      this.setState({
        isUploadDisabled: false,
      });
      return false;
    }
    //1. check result/response code
    //todo: clean this up
    const preSignedURL =
      result && result.data ? result.data.preSignedURL : null;
    const heatmapsPreSigned =
      resultHeatmaps && resultHeatmaps.data
        ? resultHeatmaps.data.preSignedURL
        : null;

    if (!preSignedURL || !heatmapsPreSigned) {
      // console.log('houston we got a problem, no presignedURL');
      //todo: handle this error, give the user some feedback
      this.setState({
        isUploadDisabled: false,
      });
      return;
    }

    // todo: Check if user has permission/accessLevel to upload file
    //       actually if user doesn't have permissions they shouldn't
    //       be in this dialog at all

    // Step 2: Upload file to AWS S3 bucket using pre-signed url
    // Making straight axios call in order to access the ProgressEvent
    // interface which allows up to caputre upload progress and pass
    // that data along to spinner component
    const newAxiosInstance = axios.create();

    //todo: make sure mergedImage is formatted correctly as a file
    const { previewImage, saveFileName, detailsAddress, detailsFloorNumber } =
      this.state;
    this.setState({
      isLoading: true,
    });

    return newAxiosInstance
      .put(
        heatmapsPreSigned,
        this.dataURLtoFile(heatmapFP, saveFileName + '_heatmap'),
        {
          'Content-type': 'image/png',
        },
      )
      .then((heatmap_res) => {
        return newAxiosInstance.put(
          preSignedURL,
          this.dataURLtoFile(previewImage, saveFileName),
          {
            'Content-type': 'image/png',
            // Progress bar to show the progress of upload while user is waiting
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percentUploadComplete = Math.round((loaded * 100) / total);
              this.setState(
                {
                  percentUploadComplete,
                },
                async () => {
                  if (percentUploadComplete === 100) {
                    this.setState({
                      isLoading: false,
                    });
                    if (typeof this.props.onUploadCompleted === 'function') {
                      //  Step 3: on success send uploaded file details to server
                      const data = {
                        filename: saveFileName,
                        address: detailsAddress || null,
                        buildingid: buildingId ?? this.state.detailsBuildingId,
                        floornumber: detailsFloorNumber || null,
                        heatmapversion: !!(
                          heatmap_res && heatmap_res.status === 200
                        ),
                      };

                      if (this.state.detailsBuildingId) {
                        const res = await floorPlansApi.addFloorPlanForBuilding(
                          this.state.detailsBuildingId,
                          data,
                        );
                        if (typeof res.data.error !== 'undefined') {
                          this.props.uploadError(res.data.error.message);

                          if (
                            res.data.error.message ===
                            'Floorplan with the same name is already loaded. Choose another file or rename this one.'
                          ) {
                            this.setState(
                              {
                                percentUploadComplete: 0,
                                isLoading: false,
                                activeActivity: 'details',
                                shouldRename: true,
                              },
                              () => {
                                setTimeout(this.props.uploadError(''), 0);
                              },
                            );

                            return;
                          }
                          this.props.onCancel();
                          return;
                        }
                      } else if (projectId) {
                        await floorPlansApi.addFloorPlan(projectId, data);
                      }

                      //this.props.onCancel();
                      //todo: verify onUploadCompleted() is defined and a function
                      this.props.onUploadCompleted();
                    }
                  }
                },
              );
            },
          },
        );
      })
      .catch((err) => {
        console.error('uploadFile error: ', err);
      })
      .finally(() => {
        this.setState({
          isUploadDisabled: false,
        });
      });

    // Step 4: Get updated list of floorplans

    //this needs to be a function from the caller
    //const updatedList = await floorPlans.getFloorPlans(this.state.projectId);
    //this.setState({ floorPlans: updatedList.data.data });
  }

  previewActivity() {
    let imageWidth =
      this.state.imageViewDimensions !== null
        ? this.state.imageViewDimensions.width + 'px'
        : '50%';
    let imageHeight =
      this.state.imageViewDimensions !== null
        ? this.state.imageViewDimensions.height + 'px'
        : '50%';

    if (
      !!this.state.imageViewDimensions &&
      !!this.state.croppedImageDimensions
    ) {
      const { width: viewWidth, height: viewHeight } =
        this.state.imageViewDimensions;
      const { height: croppedHeight, width: croppedWidth } =
        this.state.croppedImageDimensions;

      if (croppedHeight > croppedWidth) {
        imageHeight = viewHeight + 'px';
        imageWidth = 'auto';
      } else {
        imageWidth = viewWidth + 'px';
        imageHeight = 'auto';
      }
    }

    const imgStyle = {
      width: imageWidth,
      height: imageHeight,
      visibility: this.state.mergedImageVisible ? 'visible' : 'hidden',
      opacity: this.state.mergedImageVisible ? 1 : 0,
      transition: 'opacity 0.7s linear',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    };

    const { isTestArea } = this.state;

    return (
      <Box sx={classes.modalContent}>
        <Box sx={classes.modalTitle}>
          <Typography variant="h5">
            {isTestArea ? 'Create Test Area' : `New Floor Plan Preview`}
          </Typography>
          <Typography variant="subtitle1">{this.state.saveFileName}</Typography>
        </Box>
        <Box sx={classes.modalBody} ref={this.imageContainerDivRef}>
          <img
            src={this.state.previewImage}
            alt="Working floor plan"
            style={imgStyle}
            onLoad={(e) => {
              if (this.state.croppedImageDimensions === null) {
                this.setState(
                  {
                    workingFileVisible: false,
                    mergedImageVisible: true,
                    previewImage: this.state.mergeImage,
                    croppedImageDimensions: {
                      width: e.target.naturalWidth,
                      height: e.target.naturalHeight,
                    },
                  },
                  () => {
                    if (this.state.workingFileDimensions === null) {
                      this.setState(
                        {
                          workingFileDimensions: {
                            width: this.state.croppedImageDimensions.width,
                            height: this.state.croppedImageDimensions.height,
                          },
                        },
                        () => {
                          this.mergeFloorPlanIntoTemplate();
                        },
                      );
                    } else {
                      this.mergeFloorPlanIntoTemplate();
                    }
                  },
                );
              } else if (this.state.mergedImageVisible === true) {
                if (this.state.templateFileDimensions === null) {
                  this.setState({
                    templateFileDimensions: {
                      width: e.target.naturalWidth,
                      height: e.target.naturalHeight,
                    },
                  });
                }
                e.target.className = classes.isVisible;
              } else {
                e.target.className = classes.isVisible;
              }
            }}
          />
        </Box>
        <Box sx={classes.modalActions}>
          <Box sx={classes.buttons}>
            <Button onClick={this.props.onCancel}>Cancel</Button>
            <Button
              color="primary"
              disabled={this.state.isCropDisabled}
              onClick={() => {
                this.setState({ activeActivity: 'crop' });
              }}
            >
              Crop
            </Button>
            {this.state.isTestArea && (
              <Button
                color="primary"
                onClick={() => {
                  this.setState({ activeActivity: 'rename' });
                }}
              >
                Rename
              </Button>
            )}

            <Button
              color="primary"
              onClick={() => {
                this.setState({ activeActivity: 'details' });
              }}
            >
              Details
            </Button>
            <Button
              color="primary"
              disabled={this.state.isUploadDisabled}
              onClick={() => {
                this.setState({ activeActivity: 'upload' });
                if (!this.state.detailsBuildingId) {
                  this.setState({
                    activeActivity: 'details',
                    showBuildingRequiredNotice: true,
                  });
                } else {
                  this.uploadFile();
                }
              }}
            >
              {this.state.isTestArea ? 'Create' : `Upload`}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  addHDAToImage() {
    this.setState({ uploadDisabled: true });
    try {
      if (!this.state.selectedImage) return;
      const { hdaActiveList } = this.state;
      const mainImage = new Image();

      mainImage.src = this.state.selectedImage;

      const background = document.createElement('canvas');

      background.width = mainImage.width;
      background.height = mainImage.height;

      const ctx = background.getContext('2d');
      ctx.drawImage(mainImage, 0, 0);
      const minDimension =
        mainImage.width < mainImage.height ? mainImage.width : mainImage.height;
      const radiusOfEllipse = minDimension * 0.05;

      for (let hdaData of Object.values(hdaActiveList)) {
        const { coordX, coordY } = hdaData;
        ctx.fillStyle = '#5F3E3E';
        ctx.beginPath();
        ctx.ellipse(
          coordX,
          coordY,
          radiusOfEllipse,
          radiusOfEllipse,
          Math.PI * 0,
          0,
          Math.PI * 2,
        );
        ctx.fill();

        ctx.fillStyle = 'grey';
        ctx.beginPath();
        ctx.ellipse(
          coordX,
          coordY,
          radiusOfEllipse * 0.8,
          radiusOfEllipse * 0.8,
          Math.PI * 0,
          0,
          Math.PI * 2,
        );
        ctx.fill();
      }

      this.setState({
        imageForTestAreaWithHda: background.toDataURL('image/png'),
        isUploadDisabled: false,
      });
    } catch (err) {
      console.log(err);
    }
  }

  cropActivity() {
    const { isTestArea } = this.state;

    const cropperWidth =
      this.state.cropperDimensions !== null
        ? this.state.cropperDimensions.width + 'px'
        : 0;
    const cropperHeight =
      this.state.cropperDimensions !== null
        ? this.state.cropperDimensions.height + 'px'
        : 0;

    const FPCropperArea = (
      <Box
        sx={isTestArea ? classes.testAreaCropperBody : classes.modalBody}
        ref={this.modalBodyDivRef}
      >
        <div className={classes.editPanel}>
          <Toolbar
            className={classes.toolBar}
            ref={this.cropperToolBarContainerDivRef}
          >
            <IconButton
              className={classes.iconButton}
              color="inherit"
              onClick={() => {
                if (this.state.cropper !== null) {
                  this.state.cropper.zoom(0.2);
                }
              }}
              aria-label="Close"
            >
              <ZoomInIcon />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              color="inherit"
              onClick={() => {
                if (this.state.cropper !== null) {
                  this.state.cropper.zoom(-0.2);
                }
              }}
              aria-label="Close"
            >
              <ZoomOutIcon />
            </IconButton>
            {!isTestArea && (
              <IconButton
                className={classes.iconButton}
                color="inherit"
                onClick={() => {
                  if (this.state.cropper !== null) {
                    this.state.cropper.rotate(-45);
                  }
                }}
                aria-label="Close"
              >
                <RotateLeftIcon />
              </IconButton>
            )}
            {!isTestArea && (
              <IconButton
                className={classes.iconButton}
                color="inherit"
                onClick={() => {
                  if (this.state.cropper !== null) {
                    this.state.cropper.rotate(45);
                  }
                }}
                aria-label="Close"
              >
                <RotateRightIcon />
              </IconButton>
            )}
          </Toolbar>
          <div
            className="cropperContainer"
            ref={this.cropperContainerDivRef}
            style={{ height: cropperHeight, width: cropperWidth }}
          >
            <Cropper
              style={{ height: cropperHeight, width: cropperWidth }}
              zoom={-2}
              guides={true}
              src={
                isTestArea
                  ? this.state.imageForTestAreaWithHda
                  : this.state.selectedImage
              }
              ref={(cropper) => {
                this.cropper = cropper;
              }}
              viewMode={1}
              dragMode="move"
              cropBoxMovable={true}
              background={true}
              responsive={true}
              autoCropArea={0.8}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                this.setState({ cropper: instance });
              }}
            />
          </div>
        </div>
      </Box>
    );

    return (
      <Box sx={classes.modalContent}>
        <Box sx={classes.modalTitle}>
          <Typography variant="h5">
            {isTestArea ? `Test Area Crop` : `New Floor Plan Crop`}
          </Typography>
          <Typography variant="subtitle1">{this.state.saveFileName}</Typography>
        </Box>
        {isTestArea ? (
          <Stack direction="row" sx={classes.testAreaModalBody}>
            {isTestArea ? (
              <Box sx={{ width: '40%', overflow: 'scroll' }}>
                <Typography sx={{ fontWeight: 'bold' }}>HDA list</Typography>
                {this.state.hdaList.map((hda) => (
                  <Paper
                    key={hda.id}
                    sx={{
                      width: '80%',
                      my: 1,
                      p: 1,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography>{hda.name}</Typography>
                    <Switch
                      sx={{ ml: 'auto' }}
                      onChange={(e) => this.handleHDAListChange(e, hda)}
                    />
                  </Paper>
                ))}
              </Box>
            ) : null}
            {FPCropperArea}
          </Stack>
        ) : (
          FPCropperArea
        )}
        <Box sx={classes.modalActions}>
          <Box sx={classes.buttons}>
            <Button
              onClick={() => {
                this.setState({ activeActivity: 'preview' });
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                //todo: this.setState.croppedImage
                this.getCropData();
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  handleHDAListChange(e, hda) {
    const checked = e.target.checked;
    const { hdaActiveList } = this.state;

    const _hdaActiveList = Object.assign({}, hdaActiveList);
    if (!checked && _hdaActiveList[hda.id]) {
      delete _hdaActiveList[hda.id];
    }
    if (checked && !_hdaActiveList[hda.id]) {
      _hdaActiveList[hda.id] = hda;
    }
    this.setState(
      {
        hdaActiveList: _hdaActiveList,
      },
      () => this.addHDAToImage(),
    );
  }

  renameActivity() {
    const { isTestArea } = this.state;
    return (
      <Box sx={classes.modalContent}>
        <Box sx={classes.modalTitle}>
          <Typography variant="h5">
            {isTestArea ? `Name Test Area` : `New Floor Plan Rename`}
          </Typography>
          <Typography variant="subtitle1">{this.state.saveFileName}</Typography>
        </Box>

        <Box sx={classes.modalBody} style={{ background: 'white' }}>
          <RenamePanel
            floorPlanFiles={this.state.floorPlanFiles}
            sourceFileName={this.state.saveFileName}
            isTestArea={isTestArea}
            onChange={(e) => {
              // todo: connect this to state
              this.setState({
                isRenameDoneDisabled: !e.target.canSave,
                tempSaveFileName: e.target.saveFileNameValue,
              });
            }}
          />
        </Box>

        <Box sx={classes.modalActions}>
          <Box sx={classes.buttons}>
            <Button
              onClick={() => {
                this.setState({ activeActivity: 'preview' });
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={this.state.isRenameDoneDisabled}
              onClick={() => {
                //todo set this.state.saveFileName
                this.setState({
                  activeActivity: 'preview',
                  saveFileName: this.state.tempSaveFileName + '.png',
                });
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  detailsActivity() {
    const { isTestArea } = this.state;

    return (
      <Box sx={classes.modalContent}>
        <Box sx={classes.modalTitle}>
          <Typography variant="h5">
            {isTestArea ? `Test Area Details` : `New Floor Plan Details`}
          </Typography>
          <Typography variant="subtitle1">{this.state.saveFileName}</Typography>
        </Box>
        <Box sx={classes.modalBody} style={{ background: 'white' }}>
          <Box sx={classes.content}>
            <div style={{ marginTop: '20px' }}>
              <TextField
                fullWidth={true}
                label="Address"
                name="detailsAddress"
                autoComplete="detailsAddress"
                style={{ fontFamily: 'Gotham' }}
                onChange={(newValue) => this.handleChange('address', newValue)}
                value={this.state.detailsAddress}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                fullWidth={true}
                label="Floor Number"
                name="detailsFloorNumber"
                autoComplete="detailsFloorNumber"
                style={{ fontFamily: 'Gotham' }}
                onChange={(newValue) =>
                  this.handleChange('floorNumber', newValue)
                }
                value={this.state.detailsFloorNumber}
                autoFocus={!this.state.shouldRename}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <FormControl
                fullWidth
                error={
                  this.state.showBuildingRequiredNotice &&
                  !this.state.detailsBuildingId
                }
              >
                <InputLabel id="building-select-label">Building</InputLabel>
                <Select
                  labelId="building-select-label"
                  id="building-id-select"
                  value={this.state.detailsBuildingId}
                  label="Building"
                  onChange={(e) => {
                    this.handleChange('buildingId', e);
                  }}
                  disabled={!!this.props.buildingId}
                >
                  {this.props.allBuildings.map((b) => (
                    <MenuItem key={b.buildingid} value={b.buildingid}>
                      {b.buildingname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {this.state.showBuildingRequiredNotice &&
                !this.state.detailsBuildingId && (
                  <Typography color="red">Please select a building</Typography>
                )}
            </div>
            <div style={{ marginTop: '20px' }}>
              <TextField
                fullWidth={true}
                label="File name"
                name="detailsFileName"
                autoComplete="detailsFileName"
                style={{ fontFamily: 'Gotham' }}
                onChange={(newValue) => this.handleChange('fileName', newValue)}
                value={this.state.saveFileName?.split('.')[0]}
                autoFocus={this.state.shouldRename}
              />
            </div>
          </Box>
        </Box>
        <Box sx={classes.modalActions}>
          <Box sx={classes.buttons}>
            <Button
              color="primary"
              disabled={this.state.isRenameDoneDisabled}
              onClick={() => {
                this.setState({ activeActivity: 'preview' });
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  uploadActivity() {
    return (
      <Box sx={classes.modalContent}>
        <Box sx={classes.modalTitle}>
          <Typography variant="h5">New Floor Plan File Upload</Typography>
          <Typography variant="subtitle1">{this.state.saveFileName}</Typography>
        </Box>
        <Box sx={classes.modalBody} style={{ background: 'white' }}>
          <Typography>Loading: {this.state.percentUploadComplete}%</Typography>
        </Box>
        <Box sx={classes.modalActions}>
          <Box sx={classes.buttons}>
            <Button
              onClick={() => {
                // todo: stop the upload in progress
                this.setState({ activeActivity: 'preview' });
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  errorActivity() {
    return (
      <Box sx={classes.modalContent}>
        <Box sx={classes.modalTitle}>
          <Typography variant="h5">New Floor Plan Error</Typography>
          <Typography variant="subtitle1">
            {this.props.imageFile?.name}
          </Typography>
        </Box>
        <Box sx={classes.modalBody} style={{ background: 'white' }}>
          <div>
            <Typography variant="body1">
              The chosen file type is not supported. Please select a jpg or png
              image file.
            </Typography>
          </div>
        </Box>
        <Box sx={classes.modalActions}>
          <Box sx={classes.buttons}>
            <Button onClick={this.props.onCancel}>Close</Button>
          </Box>
        </Box>
      </Box>
    );
  }

  render() {
    return (
      <Box sx={classes.modal}>
        {this.state.activeActivity === 'crop'
          ? this.cropActivity()
          : this.state.activeActivity === 'rename'
          ? this.renameActivity()
          : this.state.activeActivity === 'upload'
          ? this.uploadActivity()
          : this.state.activeActivity === 'error'
          ? this.errorActivity()
          : this.state.activeActivity === 'details'
          ? this.detailsActivity()
          : this.previewActivity()}
      </Box>
    );
  }
}
export default FloorPlanCropperDialog;
